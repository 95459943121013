import { createAction } from 'redux-actions';
export const TRACKING_REGISTRATION_PAGE_FIRST_VIEWED = 'TRACKING_REGISTRATION_PAGE_FIRST_VIEWED';
export const trackingRegistrationPageFirstViewed = (meta) => createAction(
	TRACKING_ADDITIONAL_INFO_PAGE_FIRST_VIEWED,
	updates => updates,
  	() => meta,
);

export const TRACKING_REGISTRATION_PIN_TRIGGERED = 'TRACKING_REGISTRATION_PIN_TRIGGERED';
export const trackingRegistrationPinTriggered = (meta) => createAction(
	TRACKING_REGISTRATION_PIN_TRIGGERED,
	updates => updates,
	() => meta,
);

export const TRACKING_IDENTIFY_DRIVER_ID = 'TRACKING_IDENTIFY_DRIVER_ID';

export const TRACKING_TRAINING_SKIP_CLICK = 'TRACKING_TRAINING_SKIP_CLICK';
export const trackTrainingSkipClick = createAction(TRACKING_TRAINING_SKIP_CLICK);

export const TRACKING_TRAINING_SKIPPED = 'TRACKING_TRAINING_SKIPPED';

export const TRACKING_REGISTRATION_COMPLETE_PAGE_LOADED = 'TRACKING_REGISTRATION_COMPLETE_PAGE_LOADED';
export const trackRegistrationCompletePageLoad = createAction(TRACKING_REGISTRATION_COMPLETE_PAGE_LOADED);

export const TRACKING_REGISTRATION_COMPLETE_PAGE_VIEWED = 'TRACKING_REGISTRATION_COMPLETE_PAGE_VIEWED';

export const TRACKING_REGISTRATION_APP_LOGIN_CLICK = 'TRACKING_REGISTRATION_APP_LOGIN_CLICK';
export const trackRegistrationAppLoginClick = createAction(TRACKING_REGISTRATION_APP_LOGIN_CLICK);

export const TRACKING_REGISTRATION_APP_LOGIN = 'TRACKING_REGISTRATION_APP_LOGIN';

export const TRACKING_REGISTRATION_APP_DOWNLOAD_CLICK = 'TRACKING_REGISTRATION_APP_DOWNLOAD_CLICK';
export const trackRegistrationAppDownloadClick = createAction(TRACKING_REGISTRATION_APP_DOWNLOAD_CLICK);

export const TRACKING_REGISTRATION_APP_DOWNLOADED = 'TRACKING_REGISTRATION_APP_DOWNLOADED';

export const TRACKING_ADDITIONAL_INFO_PAGE_FIRST_VIEWED = 'TRACKING_ADDITIONAL_INFO_PAGE_FIRST_VIEWED';
export const trackingAdditionalInfoPageFirstViewed = (meta) => createAction(
	TRACKING_ADDITIONAL_INFO_PAGE_FIRST_VIEWED,
	updates => updates,
  	() => meta,
);

export const TRACKING_ADDITIONAL_INFO_PAGE_DATA_SUBMITTED = 'TRACKING_ADDITIONAL_INFO_PAGE_DATA_SUBMITTED';
export const trackingAdditionalInfoPageDataSubmitted = (meta) => createAction(
	TRACKING_ADDITIONAL_INFO_PAGE_DATA_SUBMITTED,
	updates => updates,
  	() => meta,
);