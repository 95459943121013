import styled from 'styled-components';

export const SuccessImg = styled.img`
  width: 240px;
  margin-top: 32px;
`;

export const SuccessTitle = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 24px;
  line-height: 1.17em;
  color: #303030;
`;

export const SuccessSubtitle = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5em;
  color: #303030;
`;
