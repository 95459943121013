import { combineReducers } from 'redux';
import {
  CONFIG_COUNTRIES_SUCCESS,
  CONFIG_CHANGE_COUNTRY_OR_LANG,
  CONFIG_CHANGE_CITY,
  CONFIG_DRIVER_DEVICE,
  ERROR_CHANGED,
  CONFIG_FORM_FINISH,
  CONFIG_REGION_COUNTRIES,
  SET_URL_UTM_PARAMS,
  SET_NEAREST_CITY,
  UTM_SAVE_SUCCESS,
  SET_IFRAME_COUNTRIES_URL
} from '../actions/config';

const initialCountryState = {
  country: 'HK',
  configCountries:'',
  lang: '',
  city: 'HK_HKG',
  fromDriverApp: false,
  device: null,
  isPhonePrecedingZero: false,
  csDriverPhone: '',
  nearestCity: 'HK_HKG',
};

const initialErrorState = {
  from: 'general',
};

const initialCompletedState = {
  isFormFinish: true,
};

const initialUTMState = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
  utm_campaigntype: '',
  utm_adname: '',
  isUTMSaved: false,
};

const initialIframeUrlsState = {
  landingPageConfig : {},
  thankYouPageConfig : {}
};

// current country
export function countryReducer(state = initialCountryState, action) {
  const { type, payload } = action;
  switch (type) {
    case CONFIG_CHANGE_COUNTRY_OR_LANG:
      if (!payload.country && !payload.lang) return state;
      return {
        ...state,
        ...payload,
      };
    case CONFIG_CHANGE_CITY:
      if (!payload.city) return state;
      return {
        ...state,
        ...payload,
      };
    case CONFIG_DRIVER_DEVICE:
      if (!payload.fromDriverApp && !payload.device) return state;
      return {
        ...state,
        ...payload,
      };
    case SET_NEAREST_CITY:
      if (!payload.nearestCity) return state;
      return {
        ...state,
        ...payload,
      };  
    default:
      return state;
  }
}

// all countries configs
export function countriesConfigReducer(state = {}, action) {
  switch (action.type) {
    case CONFIG_COUNTRIES_SUCCESS:
      if (!action.payload.configCountries) return state;
      return {
        ...state,
        ...action.payload.configCountries,
      };
    case CONFIG_REGION_COUNTRIES:
      if (!action.payload.configs) return state;
      return {
        ...state,
        ...action.payload.configs,
      };
    default:
      return state;
  }
}

export function errorReducer(state = initialErrorState, action) {
  const { type, payload } = action;
  switch (type) {
    case ERROR_CHANGED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export function completeReducer(state = initialCompletedState, action) {
  const { type, payload } = action;
  switch (type) {
    case CONFIG_FORM_FINISH:
      if (!payload.hasOwnProperty("isFormFinish")) return state;
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export function utmReducer(state = initialUTMState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_URL_UTM_PARAMS:
      return {
        ...state,
        ...payload,
      };
    case UTM_SAVE_SUCCESS:
      return{
        ...state,
        isUTMSaved: true,
      }  
    default:
      return state;
  }
};

export function iframeUrlsReducer(state = initialIframeUrlsState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_IFRAME_COUNTRIES_URL:
      return {
        ...state,
        ...payload,
      };    
    default:
      return state;
  }
};

export default combineReducers({
  country: countryReducer,
  countriesConfig: countriesConfigReducer,
  error: errorReducer,
  completed: completeReducer,
  utm: utmReducer,
  iframeUrls: iframeUrlsReducer,
});
