import React, { Component } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import 'utils/momentLanguages';
import { func, } from 'prop-types';
import { noop } from 'utils/helpers';

import './overwriteStyle.css';

class Calendar extends Component {
  static defaultProps = {
    date: null,
    onDateChange: noop,
    isDayBlocked: func.isRequired,
  };

  static propTypes = {
    onDateChange: func.isRequired,
  };

  state = {
    date: null,
  };

  componentDidUpdate(prevProps) {
    const { date: prevDate } = prevProps
    const { date } = this.props
    if (date !== prevDate) {
      this.setState({ date })
    }
  }

  onDateChange = (date) => {
    this.setState({ date });
    this.props.onDateChange(date);
  }

  resizeCalendar = () => {
    if (window.matchMedia('(max-width: 330px)').matches) {
      return 38;
    }
    if (window.matchMedia('(max-width: 380px)').matches) {
      return 43;
    }
    return 48;
  }

  render() {
    const daySize = this.resizeCalendar();
	  const { locale } = this.props;
	  moment.locale(locale);

	  return (
      <DayPickerSingleDateController
        numberOfMonths={1}
        onDateChange={this.onDateChange}
        date={this.state.date}
        enableOutsideDays
        noBorder
        hideKeyboardShortcutsPanel
        daySize={daySize}
        isDayBlocked={day => this.props.isDayBlocked(day)}
      />
    );
  }
}

export default Calendar;
