import React from 'react';
import moment from 'moment';
import { string, func } from 'prop-types';
import '../styles.css';

const CampaignInfo = ({
  campaignName,
  startDate,
  endDate,
  campaignDescription,
  t,
}) => {
  moment.locale('en'); // Set english locale by default

  const startDateConverted = startDate ? moment(startDate).format('DD-MM-YYYY') : null;
  const endDateConverted = endDate ? moment(endDate).format('DD-MM-YYYY') : null;

  return (
    <div className="container section detailsSection">
      { campaignName && (
      <h3>
        { campaignName }
      </h3>
      )}
      { (startDateConverted && endDateConverted) && (
        <div>
          { `${startDateConverted || ''} ${t('Campaign.driverCampaign.info.to')} ${endDateConverted || ''}` }
        </div>
      )}
      { campaignDescription && (
        <p className="section-description">
          { campaignDescription }
        </p>
      )}
    </div>
  );
};

export default CampaignInfo;

CampaignInfo.propTypes = {
  campaignName: string.isRequired,
  startDate: string.isRequired,
  endDate: string.isRequired,
  campaignDescription: string.isRequired,
  t: func.isRequired,
};

CampaignInfo.defaultProps = {
};
