import {
  TEL_RESUBMIT_FAILURE,
  TEL_SUBMIT_FAILURE,
  TEL_SUBMIT_SUCCESS,
  TEL_CHANGE,
  CHANGED_SUBMITTED_TEL,
  TEL_FORM_FIELD_CHANGED,
  TEL_SUBMIT_REQUEST,
} from 'store/actions/auth';
import {
  CONFIG_CHANGE_COUNTRY,
} from 'store/actions/config';

import { isNewSignUpVersion } from 'utils/helpers';

const initialState = {
  tel: null,
  telWithoutCountryCode: null,
  agreeToS: isNewSignUpVersion(),
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TEL_SUBMIT_FAILURE: // for these two cases the agree TOS need to continue enable
      return {
        ...state,
        ...payload,
        loading: false,
        agreeToS: true,
      }
    case TEL_RESUBMIT_FAILURE:
      return {
        ...state,
        ...payload,
        loading: false,
        agreeToS: true,
      };
    case TEL_CHANGE: // clear the error when the tel field is changing
      return {
        ...state,
        error: null,
      };
    case TEL_SUBMIT_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };
    case CHANGED_SUBMITTED_TEL:
      return {
        ...state,
        ...initialState,
        telWithoutCountryCode: state.telWithoutCountryCode
      };
    case TEL_FORM_FIELD_CHANGED:
    case CONFIG_CHANGE_COUNTRY:
      return {
        ...state,
        ...payload,
      };
    case TEL_SUBMIT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    default:
      return state;
  }
};
