import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  func,
  string,
  shape,
  arrayOf,
  bool,
} from 'prop-types';

import PhoneInput from 'components/PhoneInput';
import { Button } from '@lalamove/karang';
import ModalLink from 'components/ModalLink';

import {
  convertCountriesConfigToCountryList,
  extractLanguagesFromCountriesConfig,
} from 'utils/helpers';

import 'components/AnimatedInput/style.css';
import { FlexGrowDiv } from 'style';
import { changeCountry, formFinish } from 'store/actions/config';
import { telSubmit, telChange, telFormFieldChanged } from 'store/actions/auth';

import {
  CheckBox,
  FormItem,
  Box,
  FormWrapper,
  Notice,
  ToSLable,
} from './style';
import TopBanner from './components/TopBanner';
//TODO remove this later
import LanguageSelector from './components/LanguageSelector';

function normailize(array) {
  return array.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
}

export class Home extends Component {
  static propTypes = {
    t: func.isRequired,
    history: shape().isRequired,
    countriesConfig: shape().isRequired,
    changeCountry: func.isRequired,
    telSubmit: func.isRequired,
    telChange: func.isRequired,
    countries: arrayOf(shape({
      id: string,
      flag: string,
      areaCode: string,
      translations: arrayOf(shape({
        value: string,
      })),
      samplePhone: string,
      defaultLanguage: string,
    })).isRequired,
    country: shape({
      country: string,
      lang: string,
    }).isRequired,
    languages: arrayOf(shape({
      id: string,
      value: string,
    })).isRequired,
    telFormFieldChanged: func.isRequired,
    form: shape({
      tel: string,
      telWithoutCountryCode: string,
      agreeToS: bool,
      error: string,
    }),
    formFinish: func,
  };

  static defaultProps = {
    form: {
      tel: null,
      telWithoutCountryCode: null,
      agreeToS: false,
      error: null,
    },
  };

  state = {
    isSubmitting: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      languages,
    } = props;

    return {
      ...state,
      languageMapper: normailize(languages),
      isSubmitting: props.form.error // if submit has error, allow user to submit again
        ? false
        : state.isSubmitting,
    };
  }

  handleLanguageSelection = (item) => {
    this.props.changeCountry({
      country: this.props.country.country,
      lang: item.id,
      history: this.props.history,
    });
  };

  componentDidMount() {
    this.props.formFinish({
      isFormFinish: true,
    })
  }

  handleToSAgreement = (e) => {
    this.props.telFormFieldChanged({
      agreeToS: e.target.checked,
    });
  }

  handleTelChange = (e) => {
    this.props.telFormFieldChanged({
      telWithoutCountryCode: e.target.value,
    });
    // clear submit error
    this.props.form.error && this.props.telChange();
  }

  checkTelFormat = () => {
    this.setState({ focusOnTel: false });
    const telFormat = Object.values(this.props.countriesConfig)
      .filter(config => config.country === this.props.country.country)[0]
      .phoneFormat;

    let telToCheck = this.props.form.telWithoutCountryCode;

    if (this.props.country.isPhonePrecedingZero) {
      telToCheck = '0' + telToCheck;
    }

    if (!RegExp(telFormat).test(telToCheck)) {
      this.props.telFormFieldChanged({
        error: 'phoneFormat',
      });
    }
  }

  handleCountryChange = (e) => {
    if (e.id === this.props.country.country) {
      return;
    }

    // notify the country has changed
    this.props.changeCountry({
      country: e.id,
      lang: e.defaultLanguage,
      history: this.props.history,
      tel: null,
      telWithoutCountryCode: null,
      agreeToS: false,
      error: null,
    });
  }

  focusTelField = () => {
    this.setState({ focusOnTel: true });
  }

  isFormReady = () => {
    return !this.props.form.error
      && !this.state.focusOnTel
      && this.props.form.agreeToS
      && this.props.form.telWithoutCountryCode
      && !this.state.isSubmitting;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.formFinish({
      isFormFinish: false,
    })
    this.setState({ isSubmitting: true });
    const countryCode = Object.values(this.props.countriesConfig)
      .filter(config => config.country === this.props.country.country)[0]
      .areaCode;
    this.props.telSubmit({
      tel: `+${countryCode}${this.props.form.telWithoutCountryCode}`,
      method: 'SMS',
    });
  }

  handleLinkClick = () => {
    this.props.telFormFieldChanged({
      agreeToS: !this.props.form.agreeToS,
    });
  }

  render() {
    const {
      t,
      countries,
      languages,
      country,
    } = this.props;

    const {
      languageMapper,
    } = this.state;

    return (
      <div className="mainContainer">
        <TopBanner
          background={t('Home.headerBackground')}
          title={t('Home.headerTitle')}
          subTitle={t('Home.headerSubtitle')}
        />
        <FlexGrowDiv>
          <form>
            <FormWrapper>
              <FormItem>
                <PhoneInput
                  name="tel"
                  value={this.props.form.telWithoutCountryCode}
                  error={this.props.form.error && t(`Home.error.${this.props.form.error}`)}
                  placeholder={t('Home.placeholder')}
                  onChange={this.handleTelChange}
                  onBlur={this.checkTelFormat}
                  country={country.country}
                  countryList={countries}
                  onCountryChange={this.handleCountryChange}
                  isPhoneNumber
                  onFocus={this.focusTelField}
                />
              </FormItem>
              <Notice>
                {t('Home.sendPinNotice')}
              </Notice>
              <FormItem>
                <LanguageSelector
                  name="language"
                  languages={languages}
                  selectedLanguage={languageMapper[country.lang]}
                  onChange={this.handleLanguageSelection}
                  style={{ marginTop: '1.2em' }}
                />
              </FormItem>
              <Box>
                <CheckBox
                  name="agreeToS"
                  checked={this.props.form.agreeToS}
                  onChange={this.handleToSAgreement}
                  label={(
                    <ToSLable>
                      <Trans i18nKey="Home.labelToS">
                        By signing up, I agree to
                        <ModalLink
                          closeText={t('Home.modal.closeBtn')}
                          title={t('Home.modal.toSTitle')}
                          href={t('Home.termsUrl')}
                          onClick={this.handleLinkClick}
                        >
                          Lalamove's Terms and Conditions
                        </ModalLink> and
                        <ModalLink
                          closeText={t('Home.modal.closeBtn')}
                          title={t('Home.modal.privacyTitle')}
                          href={t('Home.privacyUrl')}
                          onClick={this.handleLinkClick}
                        >
                          Privacy Policy
                        </ModalLink>
                      </Trans>
                    </ToSLable>
                  )}
                />
              </Box>
            </FormWrapper>
          </form>
        </FlexGrowDiv>
        <Button
          type="submit"
          variant="primary"
          block
          disabled={!this.isFormReady()}
          onClick={this.handleSubmit}
          size="xlarge"
          solid
        >
          {t('Home.nextButton')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countriesConfig: state.config.countriesConfig,
  countries: convertCountriesConfigToCountryList(state.config.countriesConfig),
  country: state.config.country,
  languages: extractLanguagesFromCountriesConfig(
    state.config.countriesConfig,
    state.config.country.country,
  ),
  form: state.auth.telVerify,
  completed: state.config.completed,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    changeCountry,
    telSubmit,
    telChange,
    telFormFieldChanged,
    formFinish,
  }),
)(Home);
