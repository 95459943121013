export const getTel = state => state.auth.telVerify.tel;
export const getCurrentCountry = state => state.config.country;
export const getIsRequestResend = state => state.auth.isRequestResend;
export const getToken = state => state.auth.pinVerify.token;
export const getAllCountryConfigs = state => state.config.countriesConfig;
export const getOptionTagSubmitted = state => {
  const vehicleTypes = Object.values(state.auth.signup.fields).filter(field => {
    return field.driverRegistrationInfoKey === 'FORM_VEHICLE_TYPE';
  })
  let vehicle;
  if (vehicleTypes.length === 1) {
    vehicle = Object.values(vehicleTypes[0].lookup).filter(vehicle => {
      return vehicle.id === state.auth.signup.submittedFields.FORM_VEHICLE_TYPE;
    });
  }
  if (vehicle.length === 1) {
    return vehicle[0].id;
  }
  return null;
};
export const getCity = state => state.config.country.city;
export const getVerificationQuota = state => ({
  remainingSMSLimit: state.auth.pinVerify.remainingSMSLimit,
  remainingCallLimit: state.auth.pinVerify.remainingCallLimit,
});
export const getTelWithoutCountryCode = state => state.auth.telVerify.telWithoutCountryCode;
export const getIsPhonePrecedingZero = state => state.config.country.isPhonePrecedingZero;
export const getQueryParams = state => state.router.location.search || '';
export const getOperatingSystem = state => state.config.country.device || '';
export const getDriverIdWithoutCountry = state => state.auth.pinVerify.driverIdWithoutCountry;
export const getLocale = state => state.config.country.lang;
export const getAvailableDates = state => state.trainingSession.availableDates;

//this function is used to add properties in property object in registration tracking 
export const getPinTriggeredEventProperties = state => {
  const { submittedFields}  = state.auth.signup
  const { FORM_TEL, FORM_PASSWORD, ...propObject } = submittedFields
  const trackingObj = {}
 
  //only extract fields which have values
  Object.keys(propObject).forEach(function(key) {
    if(propObject[key]) {
        trackingObj[key] = propObject[key]   
      }
  });
  return trackingObj;
}

export const getUTMParams = state => {
  let utmObj = {};
  const utmObject = state.config.utm
  //only extract fields which have values
  Object.keys(utmObject).forEach(function(key) {
    if(utmObject[key]) {
       utmObj[key] = utmObject[key]   
      }
  });
  return utmObj;
};

export const getAdditionalInfo = state => state.additionalInfo;

export const getThankYouPageUrls = state => state.config.iframeUrls.thankYouPageConfig;