import styled from 'styled-components';
import { Button } from '@lalamove/karang';

export const Notice = styled.span`
  display: block;
  padding-top: 32px;
`;

export const Image = styled.img`
  padding: 16px 0;
`;

export const Title = styled.span`
  display: block;
  font-size: 1.7em;
  margin-bottom: 8px;
  line-height: 28px;
`;

export const SubTitle = styled.span`
  display: block;
  font-size: 1.14em;
  line-height: 24px;
`;

export const NextButton = styled(Button)`
  width: 100%;
`;

