import { createAction } from 'redux-actions';

export const GET_CAMPAIGN_DETAILS_REQUEST = 'GET_CAMPAIGN_DETAILS_REQUEST';
export const getCampaignDetailsRequest = createAction(GET_CAMPAIGN_DETAILS_REQUEST);

export const GET_CAMPAIGN_DETAILS_SUCCESS = 'GET_CAMPAIGN_DETAILS_SUCCESS';
export const getCampaignDetailsSuccess = createAction(GET_CAMPAIGN_DETAILS_SUCCESS);

export const GET_CAMPAIGN_DETAILS_FAILURE = 'GET_CAMPAIGN_DETAILS_FAILURE';
export const getCampaignDetailsFailure = createAction(GET_CAMPAIGN_DETAILS_FAILURE);
