import moment from 'moment';
import {
  GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
  GET_TRAINING_SESSION_VENUES_FAILURE,
  GET_TRAINING_SESSION_VENUES_SUCCESS,
  GET_TRAINING_SESSION_SLOT_REQUEST,
  GET_TRAINING_SESSION_SLOT_SUCCESS,
  GET_TRAINING_SESSION_SLOT_FAILURE,
  ENROLL_TRAINING_SESSION_REQUEST,
  ENROLL_TRAINING_SESSION_SUCCESS,
  ENROLL_TRAINING_SESSION_FAILURE,
} from 'store/actions/trainingSession';
import {
	TRACKING_TRAINING_SKIP_CLICK,
  TRACKING_TRAINING_SKIPPED
} from 'store/actions/tracking';
import _ from 'lodash';
import {
  takeEvery,
  takeLatest,
  select,
  call,
  put,
  cancelled,
} from 'redux-saga/effects';
import Api from 'utils/api';
import log from 'utils/log'
import { replace } from 'react-router-redux';
import {
  getOptionTagSubmitted,
  getCity,
  getQueryParams,
  getOperatingSystem,
	getDriverIdWithoutCountry,
	getCurrentCountry,
	getLocale,
  getAvailableDates
} from 'store/selectors';
import {
  EventTypes
} from 'redux-segment'
import { resetLoading, hideLoading } from 'react-redux-loading-bar';
import {
  ERROR_CHANGED,
} from '../actions/config';

function* getTrainingSessionsVenues() {
  const city = yield select(getCity);
  const venues = yield call(
    Api.makeRequestWithPagination,
    Api.getTrainingSessionVenues,
    [{
      filter: `(cityCode[${city}])`,
      fields: 'name,address',
    }],
  );

  if (!venues) {
    log.info('no venues available', {
      filter: `(cityCode[${city}])`,
      fields: 'name,address',
    });
    throw Error('no venues available');
  }

  return venues;
}

function* getTrainingSessionDays(optionTag) {
  const city = yield select(getCity);
  let date= moment().locale('en');
  const filters = [
    `sessionDate[>:${date.format('YYYY-MM-DD')}]`,
    `sessionDate[<:${date.add(30, 'days').format('YYYY-MM-DD')}]`,
    'availability[true]',
    `cityCode[${city}]`,
  ];
  const filterVehicle = [
    `optionTag[${optionTag}]`
  ];
  const queryString = {
    filter: `(${filters.join(',')}),(${filterVehicle.join('|')})`,
    fields: 'availability,optionTag,cityCode,sessionDate,venue,startTime',
  };

  const days = yield call(
    Api.makeRequestWithPagination,
    Api.getTrainingSessions,
    [queryString],
  );

  if (!days) {
    log.info('no venues available', queryString);
    throw Error('no venues available');
  }

  return days;
}

function* getTrainingSessionsSlot(dateString, venue, optionTag) {
  const city = yield select(getCity);
  const filters = [
      `sessionDate[${dateString}]`,
      `venue[${venue}]`,
      'availability[true]',
      `cityCode[${city}]`,
  ];

  const filterVehicle = [
      `optionTag[${optionTag}]`,
  ];
  const queryString = {
    filter: `(${filters.join(',')}),(${filterVehicle.join('|')})`,
    fields: 'availability,optionTag,cityCode,startTime,endTime',
  };

  const slots = yield call(
    Api.makeRequestWithPagination,
    Api.getTrainingSessions,
    [queryString],
  );

  if (!slots) {
    log.info('no slots available', {
     ...queryString
    });
    throw Error('no slots available');
  }

  return slots;
}

export function* handleGetVenues(action) {
  let queryParams = '';
  const { payload : {locale} } = action;
  try {
    queryParams = yield select(getQueryParams);
	  queryParams = queryParams || '';
    const optionTag = yield select(getOptionTagSubmitted);
    if (!optionTag) {
      throw Error('no vehicle type found');
    }
    const city = yield select(getCity);

    const venuesData = yield getTrainingSessionsVenues();
    let venues = venuesData.map(venue => ({
      id: venue.attributes.id,
      value: venue.attributes.name,
      address: venue.attributes.address,
      disabled: true,
    }));

    // check for availability for each venue
    const days = yield getTrainingSessionDays(optionTag);
    const availableDates = {};
    days.map((day) => {
      const { venue } = day.attributes;
      const sessionTime = moment(day.attributes.sessionDate + ' ' + day.attributes.startTime);
      const nowTimePlusTwoHours = moment().add(2, 'hours');
      if (
        !day.attributes.availability
        || day.attributes.cityCode !== city 
      ) {
        return true;
      }
      //remove sessions of today  than start before now+2 hours
      if (nowTimePlusTwoHours > sessionTime) {
        return false;
      }
      // already checked availability
      if (availableDates[venue] === undefined) {
        availableDates[venue] = [];
        venues = venues.map((venueObj) => {
          if (venueObj.id === venue) {
            return {
              ...venueObj,
              disabled: false,
            };
          }
          return venueObj;
        });
      }
      if (availableDates[venue].indexOf(day.attributes.sessionDate) === -1) {
        let sessionDate = moment(day.attributes.sessionDate).locale(locale).format('YYYY-MM-DD')
        availableDates[venue].push(sessionDate);
      }
      return true;
    });

    if (Object.keys(availableDates).length === 0) {
      yield put({
        type: ERROR_CHANGED,
        payload: {
          from: 'trainingSession',
        },
      });
      yield handleTrainingSkipClick();
      log.info('no training venue available in upcoming 30 days');
      throw Error('no training venue available in upcoming 30 days');
    }

    // filter venues with trainning sessions
    venues = venues.filter((venue) =>{
        return venue.disabled === false;
    });

    //show only th next seven session days
    for (var key in availableDates) {
      var date = availableDates[key].sort().slice(0, 7);
      availableDates[key] = date;
    }

    yield put({
      type: GET_TRAINING_SESSION_VENUES_SUCCESS,
      payload: {
        venues,
        availableDates,
      },
    });
    yield put(replace(`/register/training-session${queryParams}`));
  } catch (e) {
    yield put({
      type: GET_TRAINING_SESSION_VENUES_FAILURE,
    });
    yield put(replace(`/register/error${queryParams}`));
  }
}

export function* handleGetTimeSlot(action) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
	  queryParams = queryParams || '';
    const { payload: { date, venue } } = action;
    const optionTag = yield select(getOptionTagSubmitted);
    if (!optionTag) {
      throw Error('no vehicle type found');
    }
    const city = yield select(getCity);
    let querydate = moment(date).locale('en').format('YYYY-MM-DD')
    const slotsData = yield getTrainingSessionsSlot(querydate, venue, optionTag, city);
    const availableTime = [];
    slotsData.map((slot) => {
      if (
        !slot.attributes.availability
        || slot.attributes.cityCode !== city
      ) {
        return false;
      }
      const momentStartTime = moment(slot.attributes.startTime, 'HH:mm:ss');
      const startTime = momentStartTime.format('HH:mm');
      const momentEndTime = moment(slot.attributes.endTime, 'HH:mm:ss');
      const endTime = momentEndTime.format('HH:mm');

      if (date === moment().format('YYYY-MM-DD')) {
        const nowTimePlusTwoHours = moment(moment().add(2, 'hours').format('HH:mm:ss'), 'HH:mm:ss');
        // cannot register ongoing timeslot
        if (nowTimePlusTwoHours > moment(slot.attributes.startTime, 'HH:mm:ss')) {
          return false;
        }
      }
      availableTime.push({key: slot.id, value: `${startTime} - ${endTime}`, });
        return true;
    });
    
    availableTime.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

    yield put({
      type: GET_TRAINING_SESSION_SLOT_SUCCESS,
      payload: {
        availableTime,
      },
    });
  } catch (e) {
    yield put({
      type: GET_TRAINING_SESSION_SLOT_FAILURE,
    });
    yield put(replace(`/register/error${queryParams}`));
  } finally {
    if (yield cancelled()) {
      yield put(hideLoading());
      yield put(resetLoading());
    }
  }
}

export function* handleEnrollment(action) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
    const { payload: { trainingSessionId } } = action;

    const payload = {
      data: {
        type: 'training-sessions-bookings',
        attributes: {
          trainingSessionId,
        },
      },
    };
    const { statusCode } = yield call(
      Api.postTrainingSessionsBookings,
      payload,
    );

    if (statusCode !== 201) {
      throw Error('cannot enroll training session');
    }

    yield put({
      type: ENROLL_TRAINING_SESSION_SUCCESS,
    });

    yield put(replace(`/register/welcome${queryParams}`));
  } catch (e) {
    yield put({
      type: ENROLL_TRAINING_SESSION_FAILURE,
    });
    yield put(replace(`/register/error${queryParams}`));
  }
}

function getEarliestDate(availableDatesInVenues) {
  if (!availableDatesInVenues) {
    return '';
  }
  let result = _.flatten(Object.values(availableDatesInVenues));
  if (result.length === 0) {
    return '';
  }
  return result.reduce((a, b) => {
    return a <= b ? a : b;
  })
}

export function* handleTrainingSkipClick() {
  const os = yield select(getOperatingSystem);
  const country = yield select(getCurrentCountry);
  const lang = yield select(getLocale);
  const driverId = yield select(getDriverIdWithoutCountry);
  const availableDatesInVenues = yield select(getAvailableDates);
  let earliestDate = getEarliestDate(availableDatesInVenues);
	let data = {
		analytics: {
			eventType: EventTypes.track,
			eventPayload: {
				event: 'Training Skipped',
				properties: {
					llm_source: os || '',
					country: country ? country.country : '',
					language: lang || '',
					training_earliest_date: earliestDate,
          id: driverId || ''
				}
			}
		}
	};
	yield put({
	  type: TRACKING_TRAINING_SKIPPED,
    meta: data
  })
}

export default function* trainingSessionSaga() {
  yield takeEvery(GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING, handleGetVenues);
  yield takeLatest(GET_TRAINING_SESSION_SLOT_REQUEST, handleGetTimeSlot);
  yield takeLatest(ENROLL_TRAINING_SESSION_REQUEST, handleEnrollment);
  yield takeLatest(TRACKING_TRAINING_SKIP_CLICK, handleTrainingSkipClick);
}
