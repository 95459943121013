import {
  GET_CAMPAIGN_DETAILS_REQUEST,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  GET_CAMPAIGN_DETAILS_FAILURE,
} from 'store/actions/campaign';
import {
  takeLatest,
  call,
  put,
  cancelled,
} from 'redux-saga/effects';
import Api from 'utils/api';
import { resetLoading, hideLoading } from 'react-redux-loading-bar';

const mockResponse = {
  "links": {
    "self": "{{apiUrl}}/v3/campaign-missions/73475348578435834"
  },
  "data": {
    "type": "campaign",
    "id": "73475348578435834",
    "attributes": {
      "id": "73475348578435834",
      "campaignName": "New Year Campaign",
      "campaignDescription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      "participantType": "DRIVER",
      "status": "1",
      "startDate": "2019-11-10T06:56:24Z",
      "endDate": "2020-01-10T06:56:24Z",
      "cityCode": "HK",
      "banner": "/assets/banner.png",
      "createdBy": "123",
      "updatedBy": "123",
      "createdAt": "2019-04-24T06:56:24Z",
      "updatedAt": "2019-04-24T06:56:24Z"
    }
  }
};

export function* handleGetCampaignDetails(action) {
  try {
    const { payload: { id } } = action;
    const response = yield call(
      Api.getCampaignDetails,
      {
        id,
      },
    );
    if (response.body.error) {
      throw response.body;
    } else {
      const { body: { data: attributes } } = response;
      yield put({
        type: GET_CAMPAIGN_DETAILS_SUCCESS,
        payload: {
          data: attributes,
        },
      });
    }
  } catch (e) {
    if (action.payload.mock) {
      yield put({
        type: GET_CAMPAIGN_DETAILS_SUCCESS,
        payload: {
          data: mockResponse.data.attributes,
        },
      });
    } else {
      const { error: { message } } = e;
      yield put({
        type: GET_CAMPAIGN_DETAILS_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  } finally {
    if (yield cancelled()) {
      yield put(hideLoading());
      yield put(resetLoading());
    }
  }
}

export default function* campaignSaga() {
  yield takeLatest(GET_CAMPAIGN_DETAILS_REQUEST, handleGetCampaignDetails);
}
