import React, { Component } from 'react';
import '../styles.css';
import { func } from 'prop-types';

class CampaignMilestonesPerformance extends Component {
  state = {
    currentTab: 0,
  }

  tabConfig = t => ({
    headers: [
      {
        heading: t('Campaign.driverCampaign.info.milestone'),
        tabIndex: 0,
      },
      {
        heading: t('Campaign.driverCampaign.info.performance'),
        tabIndex: 1,
      },
    ],
    data: {
      0: props => (
        <div {...props}>
          This is the Milestones tab
        </div>
      ),
      1: props => (
        <div {...props}>
          This is the Performance tab
        </div>
      ),
    },
  })

  handleTabClick = (currentTab = 0) => {
    if (this.state.currentTab !== currentTab) {
      this.setState({
        currentTab,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { headers: tabHeaders, data: tabData } = this.tabConfig(t);
    const { currentTab } = this.state;
    const TabElement = tabData[currentTab]; // Component for the Tab

    return (
      <div className="container section">
        <ul className="tabs">
          { tabHeaders.map(item => (
            <li
              className={item.tabIndex === currentTab ? 'active' : ''}
              key={item.tabIndex}
              role="presentation"
              onClick={() => this.handleTabClick(item.tabIndex)}
            >
              {item.heading}
            </li>
          )) }
        </ul>
        <div className="tab-data detailsSection min-height-100">
          <TabElement />
        </div>
      </div>
    );
  }
}

export default CampaignMilestonesPerformance;

CampaignMilestonesPerformance.propTypes = {
  t: func.isRequired,
};

CampaignMilestonesPerformance.defaultProps = {
};
