import {
  GET_ADDITIONAL_INFO,
  SET_ADDITIONAL_INFO,
  GET_ADDITIONAL_INFO_COMPLETED,
  SET_ADDITIONAL_INFO_COMPLETED,
  GET_ADDITIONAL_INFO_POST,
  SET_ADDITIONAL_INFO_ERROR,
  CLEAR_ADDITIONAL_INFO_ERROR,
  SET_ADDITIONAL_DISPLAY_FLAG
} from "store/actions/additionalInfo";
import { ADDITIONAL_INFO_SIGNUP_POPUP } from "containers/AdditionalInfo/constants";

const initialState = {
  showAdditionalInfo: false,
  fields: [],
  error: [],
  loading: false,
  loadingPost: false,
  submittedFields: {},
  status: false,
  flow: ADDITIONAL_INFO_SIGNUP_POPUP
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ADDITIONAL_INFO:
      return {
        ...state,
        loading: true
      };
    case GET_ADDITIONAL_INFO_COMPLETED:
      return {
        ...state,
        fields: payload.fields,
        loading: false,
        showAdditionalInfo: payload.additionalDisplay,
        flow: payload.flow
      };
    case SET_ADDITIONAL_INFO:
      return {
        ...state,
        submittedFields: payload.submittedFields,
        error: [],
        loadingPost: true
      };
    case SET_ADDITIONAL_INFO_COMPLETED:
      return {
        ...state,
        status: payload.status,
        loadingPost: false
      };
    case SET_ADDITIONAL_INFO_ERROR:
      return {
        ...state,
        status: false,
        error: [...state.error, payload.error],
        loadingPost: false
      };
    case CLEAR_ADDITIONAL_INFO_ERROR:
      return {
        ...state,
        error: state.error.filter(
          item =>
            item.driverRegistrationInfoKey !==
            action.payload.driverRegistrationInfoKey
        )
      };
    case GET_ADDITIONAL_INFO_POST:
      return {
        ...state,
        loading: true
      };
    case SET_ADDITIONAL_DISPLAY_FLAG: {
      return {
        ...state,
        showAdditionalInfo: payload.additionalDisplay,
        flow: payload.flow
      };
    }
    default:
      return state;
  }
};
