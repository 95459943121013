import { combineReducers } from 'redux';
import telVerify from './telVerify';
import pinVerify from './pinVerify';
import signup from './signup';

export default combineReducers({
  telVerify,
  pinVerify,
  signup,
});
