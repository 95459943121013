import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import styled from 'styled-components';

const withLoadingBar = (WrappedComponent) => {
  class HOC extends React.Component {
    render () {
      const Wrapper = styled.div`
        height: 100%;
        text-align: center;
      `;
      return (
        <Wrapper>
          <LoadingBar style={{ background: '#F16622' }}/>
          <WrappedComponent />
        </Wrapper>
      );
    }
  }

  return HOC
};

export default withLoadingBar;
