import { fork } from 'redux-saga/effects';

import config from './config';
import auth from './auth';
import signup from './signup';
import trainingSession from './trainingSession';
import campaign from './campaign';
import welcome from './welcome';
import trainingSessionBooking from './trainingSessionBooking';
import additionalInfo from './additionalInfo'

export default function* rootSaga() {
  yield fork(config);
  yield fork(auth);
  yield fork(signup);
  yield fork(trainingSession);
  yield fork(campaign);
  yield fork(trainingSessionBooking);
  yield fork(welcome);
  yield fork(additionalInfo)
}
