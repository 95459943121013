import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PromptWrapper from '../../components/PromptWrapper/PromptWrapper';
import { Trans, translate } from 'react-i18next';
import 'react-dates/initialize';
import { Button, Select } from '@lalamove/karang';
import 'react-dates/lib/css/_datepicker.css';
import { FlexGrowDiv } from 'style';
import Calendar from 'components/Calendar';
import {
  func,
  string,
  shape,
  number,
  arrayOf,
  bool,
  array,
} from 'prop-types';
import {
  getTrainingSessionSlotRequest,
  enrollTrainingSessionRequest,
} from 'store/actions/trainingSession';
import {
	trackTrainingSkipClick,
} from 'store/actions/tracking';
import Timeslot from './components/Timeslot';

import {
  VenuePickerContainer,
  BottomContainer,
  DecideLaterBlock,
  CalendarContainer,
  ChosenDate,
} from './style';
import { cancelBack } from '../../utils/helpers';
import './style.css';

class TrainingSession extends Component {
  static propTypes = {
    t: func.isRequired,
    getTrainingSessionSlotRequest: func.isRequired,
    enrollTrainingSessionRequest: func.isRequired,
    venues: arrayOf(
      shape({
        id: number,
        value: string,
      }),
    ),
    availableDates: shape(),
    availableTime: array,
    gettingSlot: bool.isRequired,
    isSubmitting: bool.isRequired,
    locale: string,
    canSkipTrainingSession: bool,
  };

  static defaultProps = {
    venues: [],
    availableDates: {},
    availableTime: [],
    canSkipTrainingSession: true,
  };

  state = {
    venue: this.props.venues.length === 1 ? this.props.venues[0] : null,
    session: null,
    date: null,
    canSkipTrainingSession: true,
  };

  componentDidMount() {
    const {t, device} = this.props;
    document.title = t('TrainingSession.pageTitle');

    if (device === 'ios') {
      cancelBack();
    }
  }

  handleVenueSelection = selectedItem => {
    //Show only location name in selected state
    selectedItem.value = selectedItem.originalValue;
    delete selectedItem.originalValue;

    this.setState({
      venue: selectedItem,
      date: null,
      session: null,
    })
  }

  handleSessionSelection = (session) => {
    this.setState({
      session,
    });
  };

  handleDateSelection = (date) => {
    if(this.state.date !== date) {
      this.setState({
        date,
        session: null,
      });
      this.props.getTrainingSessionSlotRequest({
        date: date.format('YYYY-MM-DD'),
        venue: this.state.venue.id,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.enrollTrainingSessionRequest({
      trainingSessionId: this.state.session,
    });
  }

  trackSkipTrainingSession = (e) => {
    this.props.trackTrainingSkipClick();
  }

  render() {
    const {
      t,
      venues,
      availableTime,
      gettingSlot,
      isSubmitting,
      locale,
      completed,
      history,
      canSkipTrainingSession
    } = this.props;

    if (completed.isFormFinish) {
      history.go('/register' + this.props.location ? this.props.location.search : '');
    }

    const {
      venue,
      date,
    } = this.state;

    const isDayBlocked = (day) => {
      const availableDates = this.props.availableDates[this.state.venue.id]
        ? this.props.availableDates[this.state.venue.id]
        : [];
      return availableDates
        && availableDates.indexOf(day.format('YYYY-MM-DD')) === -1;
    };

    let venuesSelect = <div />;

    let venuesFormatted = venues.map((item, index) => {
      return {
        address: item.address,
        disabled: item.disabled,
        id: item.id,
        value: (
          <div>
            <div className="title">{item.value}</div>
            <div className="subtitle">{item.address}</div>
          </div>
        ),
        originalValue: item.value,
      };
    })

    if (venues.length !== 1) {
      venuesSelect = <Select
          id="venue"
          items={venuesFormatted}
          style={{ display: 'block' }}
          label={t('TrainingSession.venuePlaceholder')}
          onChange={this.handleVenueSelection}
          selectedItem={venue}
          value={venue && venue.id}
        />
    }

    let skipTrainingSessionBlock = <div />;

    if (canSkipTrainingSession) {
      skipTrainingSessionBlock =
        <DecideLaterBlock>
          <Trans i18nKey="TrainingSession.decideLater">
            I want to decide later.
            <Link to={{
              pathname: "/register/welcome",
              search: this.props.location ? this.props.location.search : ''
            }} onClick={this.trackSkipTrainingSession}>
              Skip
            </Link>
          </Trans>
        </DecideLaterBlock>;
    }

    return (
      <div className="mainContainer">
        <PromptWrapper
          message={(location) => {
            return (
              location.pathname === '/register/welcome'
              || location.pathname === '/register/error')
              ? true
              : t('TrainingSession.promptErrorMsg');
          }}
        />
        <FlexGrowDiv>
          <div className="paddingWrapper">
            <span className="topMessage">
              {t('TrainingSession.title')}
            </span>
            <VenuePickerContainer>
              <div className="venue-picker-container">
                {venuesSelect}
              </div>
            </VenuePickerContainer>
            { venue && (
              <CalendarContainer>
                <Calendar
                  date={this.state.date}
                  onDateChange={this.handleDateSelection}
                  isDayBlocked={isDayBlocked}
                  locale={locale}
                />
              </CalendarContainer>
            )}
            { date && (
              <div>
                {t('TrainingSession.chooseTime')}
                <ChosenDate>
                  {date.format('DD MMMM')}
                </ChosenDate>
                { !gettingSlot && (
                  <Timeslot
                    onChange={this.handleSessionSelection}
                    availableTime={availableTime}
                  />
                )}
              </div>
            )}
          </div>
        </FlexGrowDiv>
        <BottomContainer>
          {skipTrainingSessionBlock}
          <Button
            id="sessionSubmitBtn"
            variant="primary"
            size="xlarge"
            solid
            onClick={this.handleSubmit}
            disabled={!this.state.session || isSubmitting}
            block
          >
            {t('TrainingSession.nextButton')}
          </Button>
        </BottomContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.trainingSession.venues,
  availableDates: state.trainingSession.availableDates,
  availableTime: state.trainingSession.availableTime,
  isSubmitting: state.trainingSession.isSubmitting,
  gettingSlot: state.trainingSession.gettingSlot,
  locale: state.config.country.lang,
  completed: state.config.completed,
  device: state.config.country.device,
  canSkipTrainingSession: state.trainingSession.canSkipTrainingSession
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    getTrainingSessionSlotRequest,
    enrollTrainingSessionRequest,
	  trackTrainingSkipClick,
  }),
)(TrainingSession);

export { TrainingSession }
