import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './style.css';

import errorIcon from 'assets/svg/error.svg';

export default class InputError extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    classNameText: PropTypes.string,
    classNameIcon: PropTypes.string,
    error: PropTypes.string,
  };

  static defaultProps = {
    className: 'InputError',
    classNameText: 'InputError__text',
    classNameIcon: 'InputError__icon',
    error: null,
  };

  render() {
    const { className, classNameText, classNameIcon, error } = this.props;

    if (!error) {
      return null;
    }
    return (
      <div className={className}>
        <span className={classNameText}>{error}</span>
        <img className={classNameIcon} src={errorIcon} alt="error" />
      </div>
    );
  }
}
