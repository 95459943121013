import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

import {
  SelectorButton,
  Item,
  DropDown,
  DropDownItemList,
} from './style';

const {
  func,
  string,
  shape,
  arrayOf,
} = PropTypes;

const LanguageSelector = ({ languages, selectedLanguage, onChange, name }) => (
  <Downshift
    selectedItem={selectedLanguage.id}
    onChange={onChange}
  >
    {({
      isOpen,
      getToggleButtonProps,
      getItemProps,
      hightlightedIndex,
      selectedItem,
    }) => (
      <div>
        <SelectorButton
          {...getToggleButtonProps()}
          name={name}
        >
          {selectedLanguage && (
            selectedLanguage.value
          )}
        </SelectorButton>
        <DropDown>
          {isOpen && (
            <DropDownItemList>
                {languages.map((item, index) => (
                  <Item
                    {...getItemProps({
                      item,
                      isActive: hightlightedIndex === index,
                      isSelected: selectedItem.id === item.id,
                    })}
                    key={item.id}
                  >
                    {item.value}
                  </Item>
                ))}
            </DropDownItemList>
          )}
        </DropDown>
      </div>
    )}
  </Downshift>
);

LanguageSelector.propTypes = {
  languages: arrayOf(shape({
    id: string,
    value: string,
  })).isRequired,
  selectedLanguage: shape({
    id: string,
    value: string,
  }),
  onChange: func.isRequired,
};

LanguageSelector.defaultProps = {
  selectedLanguage: {},
};

export default LanguageSelector;
