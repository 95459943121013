import {
  createStore,
  combineReducers,
  compose,
  applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { loadingBarReducer, loadingBarMiddleware } from 'react-redux-loading-bar'
import { createTracker } from 'redux-segment';

import reducers from './reducers';
import rootSaga from './sagas';

const enhancers = [];

const tracker = createTracker()

if (process.env.REACT_APP_DEBUG) {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(history) {
  const createStoreWithEnchancers = compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
      }),
      tracker
    ),
    ...enhancers,
  )(createStore);

  const rootReducer = combineReducers({
    ...reducers,
    router: routerReducer,
    loadingBar: loadingBarReducer,
  });
  const store = createStoreWithEnchancers(rootReducer);

  sagaMiddleware.run(rootSaga);

  return store;
}
