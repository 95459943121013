import {
  GET_CAMPAIGN_DETAILS_FAILURE,
  GET_CAMPAIGN_DETAILS_REQUEST,
  GET_CAMPAIGN_DETAILS_SUCCESS,
} from 'store/actions/campaign';

const initialState = {
	list: [],
	details: {},
	loadingDetails: false,
	detailError: '',
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_CAMPAIGN_DETAILS_REQUEST:
			return {
				...state,
				loadingDetails: true,
			};
		case GET_CAMPAIGN_DETAILS_FAILURE:
			return {
        ...state,
        loadingDetails: false,
				detailError: payload.error,
			}
		case GET_CAMPAIGN_DETAILS_SUCCESS:
			return {
				...state,
				loadingDetails: false,
				details: payload.data,
			};
		default:
			return state;
	}
};
