import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';


export default class PromptWrapper extends Component {
    state = {
        when: false
    }

    componentDidMount() {
        this.setState({
            when: true
        })
    }

    render() {
        return (
            <Prompt 
                when={this.state.when}
                message={this.props.message}
            />
        );
    }
}