import {
    WEB_CHAT_DATA_URL,
    WEB_CHAT_DEPLOYMENT_KEY,
    WEB_CHAT_ORG_GUID,
    WEB_CHAT_AVAILABLE_LANGUAGES
} from '../startupScript';

function getLanguage() {
    const urlPage = window.location.href;
    const url = new URL(urlPage)
    const params = new URLSearchParams(url.search);
    let language = 'EN'
    if(params.get('queueName')) {
        let string = params.get('queueName');
        //some hard code queue process
        if(string){
            string = string.replace('_IN_', '_ID_');
            string = string.replace('PH_MNL_ZH_USER_CHAT', 'PH_MNL_EN_USER_CHAT');
            string = string.replace('MY_KUL_ZH_USER_CHAT', 'MY_KUL_EN_USER_CHAT');
        }
        let languageQueue = string.substring(string.split('_', 2).join('_').length+1, string.split('_', 3).join('_').length);
        if (WEB_CHAT_AVAILABLE_LANGUAGES.includes(languageQueue)) {
            language = languageQueue;
        }
    }
    return language;
}

function getTranslationFile() {
    const language = getLanguage();
    return `../chatTranslations/${language}.json`;
}

export const genesysConfig = {
    widgets: {
        main: {
            debug: true,
            theme: "light",
            i18n: getTranslationFile(),
            lang: getLanguage(),
            preload: [
                "sidebar"
            ]
        },
        webchat: {
            ajaxTimeout: 9000,
            transport: {
                dataURL: WEB_CHAT_DATA_URL,
                type: "purecloud-v2-sockets",
                deploymentKey: WEB_CHAT_DEPLOYMENT_KEY,
                orgGuid: WEB_CHAT_ORG_GUID,
                interactionData: {
                    routing: {
                        targetType: 'QUEUE',
                    }
                }
            },
            emojis: true,
            markDown: true,
            autoInvite: {
                enabled: true,
                timeToInviteSeconds: 10,
                inviteTimeoutSeconds: 30
            },
            userData: {}
        }
    }
};

export const formField = {
    form: {
        autoSubmit: false
    },
    formJSON: {
        wrapper: '<table></table>'
    }
};