import {
  takeEvery,
  select,
  call,
  put,
} from 'redux-saga/effects';

import {
  CONFIG_CHANGE_COUNTRY,
  CONFIG_CHANGE_COUNTRY_OR_LANG,
  CONFIG_CHANGE_CITY,
} from 'store/actions/config';
import {
  getCurrentCountry,
  getAllCountryConfigs,
} from 'store/selectors';
import i18n from 'utils/i18n'
import { clone, isNewSignUpVersion, isIframeView } from 'utils/helpers';
import Api from 'utils/api';
import { onSignedUp } from 'utils/callback';
import { CORS_ANYWHERE_URL } from 'startupScript';

export function* onCountryChange(action) {
  const { payload } = action;
  const currentCountry = yield select(getCurrentCountry);

  if (currentCountry.country === payload.country
    && currentCountry.lang === payload.lang){
     return;
  }

  if (currentCountry.country === payload.country
    && currentCountry.lang === payload.lang){
      //checking city for current location 
      if (isNewSignUpVersion() 
      && currentCountry.city === currentCountry.nearestCity) { 
          return;
      } else {
        return;
      }
  }
  const countriesConfig = yield select(getAllCountryConfigs);

  const clonePlayload = clone(payload);

  let city = '';
  let isPhonePrecedingZero = false;
  let csDriverPhone = '';
  if (payload.country) {
    let country = Object.values(countriesConfig)
      .filter(c => c.country === payload.country)[0];
    if (isNewSignUpVersion() && isIframeView()
      &&  currentCountry.city !== currentCountry.nearestCity) { 
      city = currentCountry.nearestCity;
    } else {
      city = country.defaultCity;
    }

    isPhonePrecedingZero = country.isPhonePrecedingZero;
    csDriverPhone = country.csDriverPhone;
    if (country.domainApp) {
      // change API domain according to config
      Api.changeDomain(country.domainApp)
    }
    Api.changeLocation(city);
    yield put({
      type: CONFIG_CHANGE_CITY,
      payload: {
        city,
      },
    });

    if (payload.lang && country) {
      let url = '';

      country.translations.forEach(function (translation, index) {
        if (payload.lang === translation.id
          && translation.driverRegistrationWebCrowdinTranslation
        ) {
          url = CORS_ANYWHERE_URL+translation.driverRegistrationWebCrowdinTranslation;
          return;
        }
      });

      if (url) {
        try {
          const response = yield call(Api.fetchLanguageJsonFromUrl, url)

          if (response) {
            let jsonData = response;
            i18n.addResourceBundle(payload.lang, 'translation', jsonData.translation, true, true);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  if (payload.history) {
    payload.history.push(`/register/${payload.country}/${payload.lang}${payload.history.location.search}`);
    delete clonePlayload.history;
  }
  clonePlayload.isPhonePrecedingZero = isPhonePrecedingZero || false;
  clonePlayload.csDriverPhone = csDriverPhone || '';

  yield put({
    type: CONFIG_CHANGE_COUNTRY_OR_LANG,
    payload: clonePlayload,
  });

  if (payload.lang) {
    Api.changeLocale(payload.lang);
    let title = yield call([i18n, 'getResource'], payload.lang, 'translation', 'Title');
    if (!title && isNewSignUpVersion()) {
      title = yield call([i18n, 'getResource'], 'en_HK', 'translation', 'Title');
    }
    document.title = title
    yield call([i18n, 'changeLanguage'], payload.lang);
  }

  const callBackParams = {
    country: currentCountry.country,
    city: currentCountry.city,
    lang: currentCountry.lang,
  };

  if (currentCountry.lang && payload.lang) {
    callBackParams.lang = payload.lang;
    if (payload.country) {
      callBackParams.country = payload.country;
    }
    if (payload.country && city) {
      callBackParams.city = city;
    }
    onSignedUp(
      '',
      '',
      callBackParams.country,
      callBackParams.city,
      callBackParams.lang,
    );
  }
}

export default function* configSaga() {
  yield takeEvery(CONFIG_CHANGE_COUNTRY, onCountryChange);
}
