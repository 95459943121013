import { createAction } from 'redux-actions';

export const GET_ADDITIONAL_INFO = 'GET_ADDITIONAL_INFO';
export const getAdditionalInfo = createAction(GET_ADDITIONAL_INFO);

export const GET_ADDITIONAL_INFO_COMPLETED = 'GET_ADDITIONAL_INFO_COMPLETED';
export const getAdditionalInfoCompleted = createAction(
  GET_ADDITIONAL_INFO_COMPLETED
);

export const SET_ADDITIONAL_INFO = 'SET_ADDITIONAL_INFO';
export const setAdditionalInfo = createAction(SET_ADDITIONAL_INFO);

export const SET_ADDITIONAL_INFO_COMPLETED = 'SET_ADDITIONAL_INFO_COMPLETED';
export const setAdditionalInfoCompleted = createAction(
  SET_ADDITIONAL_INFO_COMPLETED
);

export const SET_ADDITIONAL_INFO_ERROR = 'SET_ADDITIONAL_INFO_ERROR';
export const setAdditionalInfoError = createAction(SET_ADDITIONAL_INFO_ERROR);

export const CLEAR_ADDITIONAL_INFO_ERROR = 'CLEAR_ADDITIONAL_INFO_ERROR';
export const clearAdditionalInfoError = createAction(
  CLEAR_ADDITIONAL_INFO_ERROR
);

export const GET_ADDITIONAL_INFO_POST = 'GET_ADDITIONAL_INFO_POST';
export const getAdditionalInfoPost = createAction(GET_ADDITIONAL_INFO_POST);

export const SET_ADDITIONAL_DISPLAY_FLAG = 'SET_ADDITIONAL_DISPLAY_FLAG';
export const setAdditionalDisplayFlag = createAction(
  SET_ADDITIONAL_DISPLAY_FLAG
);
