import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { Button } from '@lalamove/karang';
import './style.css';
import { noop } from 'utils/helpers';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  content: {
    left: '20px',
    right: '20px',
    top: '36px',
    bottom: '36px',
    padding: '0px',
    borderRadius: '4px',
    border: '0',
  },
  container: {
    margin: '0',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

export default class ModalLink extends Component {
  state = {
    modalIsOpen: false,
  };

  onClick = (e) => {
    this.openModal();
    this.props.onClick(e);
  }

  openModal = () => this.setState({ modalIsOpen: true });

  closeModal = () => this.setState({ modalIsOpen: false });

  render() {
    return (
      <span className="link">
        {/*eslint-disable-next-line*/}
        <a onClick={this.onClick} href={null}>
          {this.props.children}
        </a>
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel="Site"
          style={customStyles}
        >
          <div className="container">
            <div className="contentWrapper">
              <div className="Header">
                <span>
                  {this.props.title}
                </span>
              </div>
              <div className="frameWrapper">
                <iframe src={this.props.href} className="frame" title={this.props.children} />
              </div>
            </div>
            <Button
              type="submit"
              variant="primary"
              size="xlarge"
              block
              onClick={this.closeModal}
            >
              {this.props.closeText}
            </Button>
          </div>
        </Modal>
      </span>
    );
  }
}

ModalLink.defaultProps = {
  closeText: 'Close',
  title: null,
  onClick: noop,
};

ModalLink.propTypes = {
  closeText: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};
