import { createAction } from 'redux-actions';

export const CONFIG_COUNTRIES_SUCCESS = 'CONFIG_COUNTRIES_SUCCESS';
export const requestCountriesConfigSuccess = createAction(CONFIG_COUNTRIES_SUCCESS);

export const CONFIG_REGION_COUNTRIES = 'CONFIG_REGION_COUNTRIES';
export const setCountriesPerRegion = createAction(CONFIG_REGION_COUNTRIES);

export const CONFIG_CHANGE_COUNTRY_OR_LANG = 'CONFIG_CHANGE_COUNTRY_OR_LANG';
export const changeCountryOrLang = createAction(CONFIG_CHANGE_COUNTRY_OR_LANG);

export const CONFIG_CHANGE_COUNTRY = 'CONFIG_CHANGE_COUNTRY';
export const changeCountry = createAction(CONFIG_CHANGE_COUNTRY);

export const CONFIG_CHANGE_CITY = 'CONFIG_CHANGE_CITY';
export const changeCity = createAction(CONFIG_CHANGE_CITY);

export const CONFIG_DRIVER_DEVICE = 'CONFIG_DRIVER_DEVICE';
export const driverDevice = createAction(CONFIG_DRIVER_DEVICE);

export const ERROR_CHANGED = 'ERROR_CHANGED';
export const errorChanged = createAction(ERROR_CHANGED);

export const CONFIG_FORM_FINISH = 'CONFIG_FORM_FINISH';
export const formFinish = createAction(CONFIG_FORM_FINISH);

export const SET_URL_UTM_PARAMS = 'SET_URL_UTM_PARAMS';
export const setUTMParams = createAction(SET_URL_UTM_PARAMS);

export const SET_NEAREST_CITY = 'SET_NEAREST_CITY';
export const setNearestCity = createAction(SET_NEAREST_CITY);

export const UTM_SAVE_SUCCESS = 'UTM_SAVE_SUCCESS';
export const utmSaveSuccess = createAction(UTM_SAVE_SUCCESS);

export const SET_IFRAME_COUNTRIES_URL = 'SET_IFRAME_COUNTRIES_URL';
export const setIframeCountriesUrl = createAction(SET_IFRAME_COUNTRIES_URL);