import {
  GET_BASIC_INFO
} from 'store/actions/signup';
import {
	getLocale
} from 'store/selectors';
import {
  GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
  GET_TRAINING_SESSION_VENUES_FAILURE,
  INITIALIZE_TRAINING_SESSION_FIELDS,
  CHANGE_CAN_SKIP_TRAINING_SESSION
} from 'store/actions/trainingSession';
import {
  take,
  takeEvery,
  put,
  select,
} from 'redux-saga/effects';
import { replace } from 'react-router-redux';

export function* handleInitializeActions() {
  try {
    const lang = yield select(getLocale);

    //Remove skip training link
    yield put({
      type: CHANGE_CAN_SKIP_TRAINING_SESSION,
      payload: {
        canSkipTrainingSession: false,
      }
    });

    yield put({
      type: GET_BASIC_INFO,
      payload: {
        skipSignUpForm: true
      }
    });

    yield take('GET_BASIC_INFO_SUCCESS');

    yield put({
      type: GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
      payload: {
        locale: lang,
      }
    });
  } catch (e) {
    yield put({
      type: GET_TRAINING_SESSION_VENUES_FAILURE,
    });
    yield put(replace(`/register/error`));
  }
}

export default function* trainingSessionBookingSaga() {
  yield takeEvery(INITIALIZE_TRAINING_SESSION_FIELDS, handleInitializeActions);
}
