import React from 'react';
import { string, func } from 'prop-types';
import '../styles.css';

const CampaignMilestonesDescription = ({ description, t }) => (
  <div className="container section detailsSection min-height-100">
    <h4 className="section-heading">
      { t('Campaign.driverCampaign.info.milestoneDescription') }
    </h4>
    <p className="section-description">
      { description }
    </p>
  </div>
);

export default CampaignMilestonesDescription;

CampaignMilestonesDescription.propTypes = {
  description: string,
  t: func.isRequired,
};

CampaignMilestonesDescription.defaultProps = {
  description: '',
};
