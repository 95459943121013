import React from 'react';
import {
  func,
  string,
  shape,
  int,
  bool,
  objectOf,
  arrayOf,
  oneOfType,
  number
} from 'prop-types';

import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Input, Select } from '@lalamove/karang';

import { OTHERS_OPTION } from '../../constants';
import { noop } from 'utils/helpers';

import './style.css';

const compareBySortPriority = (a, b) => {
  if (a.sortingPriority < b.sortingPriority) return -1;
  if (a.sortingPriority > b.sortingPriority) return 1;
  return 0;
};

const getSelectedItemFromKey = (lookup, selectedField) => {
  return lookup.find(item => item.id === selectedField);
};

const setOthersAtLast = (lookup, t) => {
  let validOptions = []
  const othersOption = lookup.find(
    item => String(item.id).toLowerCase() === OTHERS_OPTION
  );
  let lookupWoOthers = [];
  let othersWithTranslation = {};
  if (othersOption) {
    othersWithTranslation = {
      id: othersOption.id,
      value: t('AdditionalInfo.otherFieldValue')
    };
    lookupWoOthers = lookup.filter(
      item => String(item.id).toLowerCase() !== OTHERS_OPTION
    );
  }
  validOptions = othersOption ? [...lookupWoOthers, othersWithTranslation] : lookup;
  return validOptions.filter(item => item.id) //filter out empty or invalid values
};

const FormFields = props => {
  const {
    loading,
    fields,
    selectedFields,
    handleInputChange,
    handleOnBlur,
    handleSelection,
    handleOtherField,
    otherFields,
    errorFields,
    t
  } = props;

  if (fields && fields.length) {
    return Object.values(fields)
      .sort(compareBySortPriority)
      .map(field => {
        const key = field.driverRegistrationInfoKey;
        const othersKey = `${key}-${OTHERS_OPTION}`;
        // normal text field
        if (field.lookup && field.lookup.length === 0) {
          const fieldProps = {
            maxLength: field.length,
            type: field.type === 'INTEGER' ? 'number' : 'text'
          };
          return (
            <div className="infoInput" key={`${key}-div`}>
              <span>{field.label}</span>
              <Input
                name={key}
                value={selectedFields[key] || ''}
                onChange={handleInputChange}
                autoComplete="false"
                onCopy={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                error={errorFields[key]}
                required={field.required}
                onBlur={handleOnBlur}
                disabled={loading}
                {...fieldProps}
              />
            </div>
          );
        }
        return (
          <React.Fragment>
            <div className="infoInput" key={`${key}-div`}>
              <span>{field.label}</span>
              <Select
                id={key}
                label={t('AdditionalInfo.dropdownLabel')}
                name={key}
                itemList={setOthersAtLast(field.lookup, t)}
                selectedItem={getSelectedItemFromKey(
                  setOthersAtLast(field.lookup, t),
                  selectedFields[key]
                )}
                onChange={handleSelection}
                error={errorFields[key]}
                onBlur={handleOnBlur}
                required={field.required}
                disabled={loading}
              />
            </div>

            {selectedFields[key] &&
              String(selectedFields[key]).toLowerCase() === OTHERS_OPTION && (
                <div className="infoInput" key={`${othersKey}-div`}>
                  <span> {t('AdditionalInfo.otherFieldLabel')} </span>
                  <Input
                    name={othersKey}
                    value={otherFields[othersKey]}
                    onChange={e => handleOtherField(othersKey, e.target.value)}
                    autoComplete="false"
                    onCopy={e => e.preventDefault()}
                    onPaste={e => e.preventDefault()}
                    error={errorFields[othersKey]}
                    required={true} //others will always be required
                    onBlur={handleOnBlur}
                    disabled={loading}
                  />
                </div>
              )}
          </React.Fragment>
        );
      });
  }
  return '';
};

FormFields.propTypes = {
  loading: bool,
  handleInputChange: func,
  handleOnBlur: func,
  handleSelection: func,
  t: func.isRequired,
  handleOtherField: noop,
  selectedFields: objectOf(oneOfType([string, number])),
  otherFields: objectOf(oneOfType([string, number])),
  errorFields: objectOf(string),
  fields: arrayOf(
    shape({
      driverRegistrationInfoKey: string,
      sortingPriority: int,
      example: string,
      length: int,
      required: bool,
      label: string,
      type: string,
      lookup: arrayOf(
        shape({
          id: oneOfType([string, number]),
          value: oneOfType([string, number])
        })
      )
    })
  )
};
FormFields.defaultProps = {
  loading: false,
  handleInputChange: noop,
  handleOnBlur: noop,
  handleSelection: noop,
  handleOtherField: noop,
  selectedFields: {},
  otherFields: {},
  errorFields: {},
  fields: []
};
export default compose(translate())(FormFields);
