import {
  RESEND_LOCK,
  RESEND_RELEASE,
  SIGNUP_LOCK,
  SIGNUP_RELEASE,
  PIN_VERIFY,
  PIN_SUBMIT_SUCCESS_NO_LOADING,
  PIN_SUBMIT_FAILURE,
  PIN_REQUEST_RESEND,
  TEL_SUBMIT_SUCCESS,
  TEL_RESUBMIT_FAILURE,
  CHANGED_SUBMITTED_TEL,
  SET_DRIVER_ID
} from 'store/actions/auth';

const initialState = {
  isWaiting: false, // time for retry
  isLockedForSignup: false, // time for signup again after entering too many wrong pins
  error: null,
  waitBeforeSignupAgain: 0,
  remainingSMSLimit: 0,
  remainingCallLimit: 0,
  waitBeforeResending: 0,
  isSubmitting: false,
  isRequestResend: false,
  token: null,
  driverTrackingId: null,
  driverIdWithoutCountry: null,
  disabledPinInput: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESEND_LOCK:
      return {
        ...state,
        isWaiting: true,
      };
    case RESEND_RELEASE:
      return {
        ...state,
        isWaiting: false,
        waitBeforeResending: 0,
      };
    case SIGNUP_LOCK:
      return {
        ...state,
        ...payload,
        isLockedForSignup: true,
      };
    case SIGNUP_RELEASE:
      return {
        ...state,
        error: null,
        isLockedForSignup: false,
        waitBeforeSignupAgain: 0,
        disabledPinInput: false,
      };
    case PIN_VERIFY:
    case PIN_SUBMIT_SUCCESS_NO_LOADING:
      return {
        ...state,
        ...payload,
        error: null,
        driverIdWithoutCountry: payload.driverTrackingId ? payload.driverTrackingId.split('_')[1] : null,
      };
    case PIN_SUBMIT_FAILURE:
      return {
        ...state,
        ...payload,
        isSubmitting: false,
      };
    case PIN_REQUEST_RESEND:
      return {
        ...state,
        isRequestResend: true,
      };
    case TEL_SUBMIT_SUCCESS:
    case TEL_RESUBMIT_FAILURE:
      return {
        ...state,
        ...payload,
        isRequestResend: false,
      };
    case CHANGED_SUBMITTED_TEL:
      return {
        ...state,
        ...initialState,
      };
    case SET_DRIVER_ID:
      return {
        ...state,
        driverIdWithoutCountry: action.payload.driverIdWithoutCountry,
        driverTrackingId: action.payload.driverTrackingId,
      }
    default:
      return state;
  }
};
