import { takeLatest, select, put } from 'redux-saga/effects';
import { getCurrentCountry, getOperatingSystem, getLocale, getDriverIdWithoutCountry } from 'store/selectors';
import { TRACKING_REGISTRATION_COMPLETE_PAGE_LOADED, TRACKING_REGISTRATION_COMPLETE_PAGE_VIEWED, TRACKING_REGISTRATION_APP_LOGIN_CLICK, TRACKING_REGISTRATION_APP_LOGIN, TRACKING_REGISTRATION_APP_DOWNLOAD_CLICK, TRACKING_REGISTRATION_APP_DOWNLOADED } from 'store/actions/tracking'
import { EventTypes } from 'redux-segment'

export function* callTrackingAction(eventName, actionName) {
	const os = yield select(getOperatingSystem);
	const country = yield select(getCurrentCountry);
	const lang = yield select(getLocale);
	const driverId = yield select(getDriverIdWithoutCountry);
	let data = {
		analytics: {
			eventType: EventTypes.track,
			eventPayload: {
				event: eventName,
				properties: {
					llm_source: os || '',
					country: country ? country.country : '',
					language: lang || '',
					id: driverId || ''
				}
			}
		}
	};
	yield put({
		type: actionName,
		meta: data
	});
}

export function* handleRegistrationCompletePageLoad() {
	yield callTrackingAction('Registration Completed Page Viewed', TRACKING_REGISTRATION_COMPLETE_PAGE_VIEWED);
}

export function* handleAppLoginClick() {
	yield callTrackingAction('Registration App Login', TRACKING_REGISTRATION_APP_LOGIN);
}

export function* handleAppDownloadClick() {
	yield callTrackingAction('Registration App Downloaded', TRACKING_REGISTRATION_APP_DOWNLOADED);
}

export default function* welcomeSaga() {
  yield takeLatest(TRACKING_REGISTRATION_COMPLETE_PAGE_LOADED, handleRegistrationCompletePageLoad);
  yield takeLatest(TRACKING_REGISTRATION_APP_LOGIN_CLICK, handleAppLoginClick);
  yield takeLatest(TRACKING_REGISTRATION_APP_DOWNLOAD_CLICK, handleAppDownloadClick);
}
