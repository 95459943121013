
import {getParams} from './index';


const formField = {
    form: {
        autoSubmit: true,
        firstname: '',
        lastname: '',
        email: '',
        subject: 'Populated from data'
    },
    formJSON: {
        wrapper: '<table></table>'
    }
};

export function getAdvancedConfig() {
    const paramsData = getParams();
    const { firstName, lastName, email, driverInfoLink, orderInfoLink, phoneNumber, queueName } = paramsData;

    const { form } = formField;
    const formInputField = [
        {
            id: 'cx_webchat_form_firstname',
            name: 'firstname',
            maxlength: '100',
            placeholder: 'First Name',
            label: 'First Name',
            value: firstName,
            readonly: firstName ? true : false,
        },
        {
            id: 'cx_webchat_form_lastname',
            name: 'lastname',
            maxlength: '100',
            placeholder: 'Last Name',
            label: 'Last Name',
            value: lastName,
            readonly: lastName ? true : false,
        },
        {
            id: 'cx_webchat_form_email',
            name: 'email',
            maxlength: '100',
            placeholder: 'Optional',
            label: 'Email',
            value: email,
            readonly: email ? true : false,
        },
        {
            id: 'cx_webchat_form_subject',
            name: 'subject',
            maxlength: '100',
            placeholder: 'Optional',
            label: 'Subject',
            value: 'Web Chat'
        },
        {
            id: 'custom_field_1',
            name: 'customField1',
            maxlength: '100',
            placeholder: 'Custom Data',
            label: 'custom_field_1',
            value: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
            type: "hidden"
        },
        {
            id: 'custom_field_2',
            name: 'customField2',
            maxlength: '100',
            placeholder: 'Custom Data',
            label: 'customField2',
            value: `Order Link / ID: ${orderInfoLink}`,
            type: "hidden"
        }
    ];
    const formData = {
        ...form,
        firstname: firstName,
        lastname: lastName,
        email: email,
        customField1: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
        customField2: `Order Link / ID: ${orderInfoLink}`,
        phoneNumber: phoneNumber,
    };
    return {
        form: formData,
        formJSON: {
            wrapper: '<table></table>',
            inputs: formInputField
        }
    };
};

export function listenCancelEvt(customPlugin){
    //old feat dont listen it
}

export function createWebchatObj(webchat, paramsData, isBotAvailable){
    const webchatObj = { ...webchat, userData: paramsData, form: getAdvancedConfig() };
    return webchatObj;
}