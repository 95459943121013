import styled from 'styled-components';
import { Button } from '@lalamove/karang';

export const SelectorButton = styled(Button)`
  color: #303030;
  margin-top: 16px;
`;

export const Item = styled.div`
  border-left: 2px solid #fff;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#f2f2f2' : '#FFF')};
  border-left-color: ${({ isActive }) => (isActive ? '#f16622' : '#FFF')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  &:hover,
  &:focus {
    background-color: #f2f2f2;
    border-left-color: #f16622;
  }
`;

export const DropDown = styled.div`
  position: relative;
`;

export const DropDownItemList = styled.div`
  background-color: var(--white-color);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  color: var(--gray-color);
  font-size: 14px;
  // left: -1px;
  line-height: 32px;
  max-height: 256px;
  position: absolute;
  top: 1px;
  z-index: 1;
  width:100%;
`;
