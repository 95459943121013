import { createAction } from 'redux-actions';

export const GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING = 'GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING';
export const getTrainingSessionVenuesRequest = createAction(GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING);

export const GET_TRAINING_SESSION_VENUES_SUCCESS = 'GET_TRAINING_SESSION_VENUES_SUCCESS';
export const getTrainingSessionVenuesSuccess = createAction(GET_TRAINING_SESSION_VENUES_SUCCESS);

export const GET_TRAINING_SESSION_VENUES_FAILURE = 'GET_TRAINING_SESSION_VENUES_FAILURE';
export const getTrainingSessionVenuesFailure = createAction(GET_TRAINING_SESSION_VENUES_FAILURE);

export const GET_TRAINING_SESSION_SLOT_REQUEST = 'GET_TRAINING_SESSION_SLOT_REQUEST';
export const getTrainingSessionSlotRequest = createAction(GET_TRAINING_SESSION_SLOT_REQUEST);

export const GET_TRAINING_SESSION_SLOT_SUCCESS = 'GET_TRAINING_SESSION_SLOT_SUCCESS';
export const getTrainingSessionSlotSuccess = createAction(GET_TRAINING_SESSION_SLOT_SUCCESS);

export const GET_TRAINING_SESSION_SLOT_FAILURE = 'GET_TRAINING_SESSION_SLOT_FAILURE';
export const getTrainingSessionSlotFailure = createAction(GET_TRAINING_SESSION_SLOT_FAILURE);

export const ENROLL_TRAINING_SESSION_REQUEST = 'ENROLL_TRAINING_SESSION_REQUEST';
export const enrollTrainingSessionRequest = createAction(ENROLL_TRAINING_SESSION_REQUEST);

export const ENROLL_TRAINING_SESSION_SUCCESS = 'ENROLL_TRAINING_SESSION_SUCCESS';
export const enrollTrainingSessionSuccess = createAction(ENROLL_TRAINING_SESSION_SUCCESS);

export const ENROLL_TRAINING_SESSION_FAILURE = 'ENROLL_TRAINING_SESSION_FAILURE';
export const enrollTrainingSessionFailure = createAction(ENROLL_TRAINING_SESSION_FAILURE);

export const INITIALIZE_TRAINING_SESSION_FIELDS = 'INITIALIZE_TRAINING_SESSION_FIELDS';
export const initializeTrainingSessionFields = createAction(INITIALIZE_TRAINING_SESSION_FIELDS);

export const CHANGE_CAN_SKIP_TRAINING_SESSION = 'CHANGE_CAN_SKIP_TRAINING_SESSION';
export const changeCanSkipTrainingSession = createAction(CHANGE_CAN_SKIP_TRAINING_SESSION);

