import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import {
  func,
  string,
  shape,
  int,
  bool,
} from 'prop-types';

import { convertMsToHumanFormat, cancelBack, isNewSignUpVersion } from 'utils/helpers';
import {
  pinSubmit,
  pinVerify as pinVerifyReducer,
  pinRequsetResend,
  telSubmit,
} from 'store/actions/auth';
import {
  PinInput,
  Button,
  CountdownBar,
} from '@lalamove/karang';

import './style.css';
import { FlexGrowDiv } from 'style';

export class Verification extends Component {
  static propTypes = {
    t: func.isRequired,
    telVerify: shape({
      tel: string,
    }).isRequired,
    pinVerify: shape({
      isWaiting: bool,
      isLockedForSignup: bool,
      waitBeforeSignupAgain: int,
      error: string,
      remainingSMSLimit: int,
      remainingCallLimit: int,
      waitBeforeResending: int,
      isSubmitting: bool,
      isRequestResend: bool,
      disabledPinInput: bool,
    }).isRequired,
    pinSubmit: func.isRequired,
    pinVerifyReducer: func.isRequired,
    pinRequsetResend: func.isRequired,
    telSubmit: func.isRequired,
  };

  state = {};

  componentDidMount() {
    // fix iOS 9 auto focus scroll to bottom issue
    document.body.scrollTop = 0;
    if (!isNewSignUpVersion()) {
      cancelBack();
    }
  }

  static getDerivedStateFromProps(props) {
    const {
      t,
      pinVerify: {
        error,
        waitBeforeSignupAgain,
        waitBeforeResending,
        isLockedForSignup,
      },
    } = props;

    const waitSignupInHumanFormat = convertMsToHumanFormat(waitBeforeSignupAgain, t);
    const waitResendInHumanFormat = convertMsToHumanFormat(waitBeforeResending, t);

    let errorDisplay = error;

    if (error) {
      if (errorDisplay[errorDisplay.length - 1] === '.') {
        errorDisplay = errorDisplay.slice(0, -1);
      }
      errorDisplay = t(`Verification.error.${errorDisplay}`);
    }

    if (error && isLockedForSignup) {
      errorDisplay = errorDisplay.replace('[DURATION]', waitSignupInHumanFormat);
    }

    return {
      error: error && errorDisplay,
      waitSignupInHumanFormat,
      waitResendInHumanFormat,
    };
  }

  handlePinChange = (pin) => {
    this.state.error && this.props.pinVerifyReducer({ error: null });
    if (pin.length === 4) {
      this.props.pinVerifyReducer({
        isSubmitting: true,
      });
      setTimeout(() => this.props.pinSubmit({ pin }), 1);
    }
  }

  resendPin = (method) => {
    this.props.pinRequsetResend();
    this.props.telSubmit({
      tel: this.props.telVerify.tel,
      method,
    });
  }

  render() {
    const {
      t,
      telVerify: {
        tel,
      },
      pinVerify: {
        isWaiting,
        isLockedForSignup,
        remainingSMSLimit,
        remainingCallLimit,
        isSubmitting,
        isRequestResend,
        waitBeforeSignupAgain,
        waitBeforeResending,
        disabledPinInput,
      },
    } = this.props;

    const {
      error,
      waitSignupInHumanFormat,
      waitResendInHumanFormat,
    } = this.state;

    const renderButton = () => {
      let label = '';
      if (isLockedForSignup) {
        label = `${t('Verification.waitToSignup').replace('[DURATION]', waitSignupInHumanFormat)}`;
      } else if (remainingSMSLimit <= 0) {
        return '';
      } else {
        label = isWaiting
          ? `${t('Verification.waitToResendSMS').replace('[DURATION]', waitResendInHumanFormat)}`
          : `${t('Verification.resendSMS')}`;
      }

      if (isLockedForSignup || isWaiting) {
        return (
          <CountdownBar
            label={label}
            duration={`${waitBeforeSignupAgain || waitBeforeResending}ms`}
          />
        );
      }
      return (
        <Button
          variant="primary"
          size="xlarge"
          block
          disabled={isSubmitting || isRequestResend}
          onClick={() => this.resendPin('SMS')}
        >
          {
            label
          }
        </Button>
      );
    };

    const voiceButtonText = () => {
      if (isLockedForSignup) {
        return `${t('Verification.waitToTryVoice').replace('[DURATION]', waitSignupInHumanFormat)}`;
      }
      if (isWaiting) {
        return `${t('Verification.waitToTryVoice').replace('[DURATION]', waitResendInHumanFormat)}`;
      }
      return `${t('Verification.tryVoice')}`;
    };

    return (
      <div className="mainContainer">
        <FlexGrowDiv>
          <div className="upperWrapper">
            <span className="topMessage">
              {`${t('Verification.topLabel').replace('[PHONE]', tel)}`}
            </span>
            <div>
              <PinInput
                disabled={isSubmitting || disabledPinInput}
                onChange={this.handlePinChange}
                error={t(error)}
                size="small"
                type="tel"
              />
            </div>
            {remainingCallLimit > 0
              && (
                <div id="voiceWrapper">
                  <span className="orLabel">
                    {t('Verification.orLabel')}
                  </span>
                  <Button
                    className="voiceLabel"
                    type="submit"
                    variant="link"
                    disabled={isSubmitting || isWaiting || isRequestResend}
                    onClick={e => this.resendPin('CALL')}
                  >
                    {voiceButtonText()}
                  </Button>
                </div>
              )}
          </div>
        </FlexGrowDiv>
        <div id="bottomButton">
          {renderButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  telVerify: state.auth.telVerify,
  pinVerify: state.auth.pinVerify,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    pinSubmit,
    pinVerifyReducer,
    pinRequsetResend,
    telSubmit,
  }),
)(Verification);
