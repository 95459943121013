import 'airbnb-js-shims/target/es2015';
import 'airbnb-browser-shims/browser-only';
import 'babel-polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import createHistory from 'history/createBrowserHistory';
import { ConnectedRouter } from 'react-router-redux';

import i18n from './utils/i18n';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import startupScript from './startupScript';
import configureStore from './store/configureStore';

const history = createHistory();
const store = configureStore(history);

startupScript(store, history, i18n).then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
  );
});
unregister();
