import { DEFAULT_LANGUAGE } from 'startupScript';
import { THANK_YOU_PAGE_DEFAULT_KEY } from 'containers/AdditionalInfo/constants'

export const convertLang = (lang) => {
  if (!lang) return DEFAULT_LANGUAGE;
  let splitted = lang.split('_');
  if (splitted.length < 2) {
    return DEFAULT_LANGUAGE;
  }
  splitted[1] = splitted[1].toUpperCase();
  return splitted.join('_');
};

// reconstruct the format that the Phone input can recognize
export const convertCountriesConfigToCountryList = (countriesConfig) => {
  const countryList = [];

  Object.values(countriesConfig).map(countryConfig => countryList.push({
    id: countryConfig.country,
    flag: countryConfig.flag,
    areaCode: countryConfig.areaCode,
    translations: countryConfig.translations.map(tran => ({ value: tran.country })),
    samplePhone: countryConfig.samplePhone,
    defaultLanguage: countryConfig.defaultLanguage,
  }));

  return countryList;
};

export const extractLanguagesFromCountriesConfig = (countriesConfig, country) => {
  let languages = [];

  const config = Object.values(countriesConfig).filter(config => config.country === country)[0];
  if (typeof config === 'undefined') return languages;
  config.translations.map(translation => languages.push({
    id: translation.id,
    value: translation.value,
  }));
  return languages;
};

export const convertMsToHumanFormat = (timeInMs, t) => {
  if (timeInMs === null || timeInMs === 0 || isNaN(timeInMs)) {
    return null;
  }
  let timeUnit = 'second';
  let timeInSecond = timeInMs / 1000;

  let time = timeInSecond;
  let plural = true;

  const yearInSecond = 31536000,
    monthInSecond = 2592000,
    weekInSecond = 604800,
    dayInSecond = 86400,
    hourInSecond = 3600,
    minuteInSecond = 60;

  switch (true) {
    case timeInSecond >= yearInSecond:
      time = timeInSecond / yearInSecond;
      timeUnit = 'year';
      break;
    case timeInSecond >= monthInSecond:
      time = timeInSecond / monthInSecond;
      timeUnit = 'month';
      break;
    case timeInSecond >= weekInSecond:
      time = timeInSecond / weekInSecond;
      timeUnit = 'week';
      break;
    case timeInSecond >= dayInSecond:
      time = timeInSecond / dayInSecond;
      timeUnit = 'day';
      break;
    case timeInSecond >= hourInSecond:
      time = timeInSecond / hourInSecond;
      timeUnit = 'hour';
      break;
    case timeInSecond >= minuteInSecond:
      time = timeInSecond / minuteInSecond;
      timeUnit = 'minute';
      break;
    default:
      plural = false;
      break;
  }
  time = Math.floor(time);
  timeUnit = time === 1 && plural ? timeUnit : timeUnit + 's';
  let timeType = t('Verification.unit.' + timeUnit);

  return timeType === 's' ? `${time}${timeType}` : `${time} ${timeType}`;
}

export const getRequiredBasicInfoFields = fields => {
  return Object.values(fields)
    .filter(field => field.required)
    .reduce((arr, field) => {
      return [...arr, field.driverRegistrationInfoKey];
    }, []);
}

export const clone = (obj) => JSON.parse(JSON.stringify(obj));

export const ERROR_TYPE_LENGTH_TOO_LONG = 'ERROR_TYPE_LENGTH_TOO_LONG';
export const ERROR_TYPE_PASSWORD_TOO_SHORT = 'ERROR_TYPE_PASSWORD_TOO_SHORT';
export const ERROR_TYPE_REQUIRED = 'ERROR_TYPE_REQUIRED';
export const ERROR_TYPE_INVALID_EMAIL_FORMAT = 'ERROR_TYPE_INVALID_EMAIL_FORMAT';
export const PHONE_FORMAT = "PHONE_FORMAT";
export const handleFieldErrorOnBasicInfo = (t, field, type, length) => {
  let errorString = '';
  switch (type) {
    case ERROR_TYPE_LENGTH_TOO_LONG:
      errorString = t('BasicInfo.error.field_too_long').replace('[LENGTH]', length);
      break;
    case ERROR_TYPE_PASSWORD_TOO_SHORT:
      errorString = t('BasicInfo.error.password_too_short');
      break;
    case ERROR_TYPE_INVALID_EMAIL_FORMAT:
      errorString = t('BasicInfo.error.invalid_email_format');
      break;
    case PHONE_FORMAT:
      errorString = t('Home.error.phoneFormat');
      break;
    case ERROR_TYPE_REQUIRED:
    default:
      errorString = t('BasicInfo.error.field_required');
  }

  return {
    [field]: errorString,
  };
};

export const noop = () => { };

export function cancelBack() {
  window.onpopstate = e => {
    var { pathname } = document.location
    if (!(pathname === '/register' || pathname === '/register/error')) {
      e.preventDefault();
      window.location.replace('/register' + window.location.search);
    }
  };
}

export const OTHER_FORM_FIELDS = ['FORM_REFERRAL_NUMBER', 'FORM_VEHICLE_TYPE']

export const validateEmail = (email) => {
  var mailformat = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
  return email.match(mailformat) ? true : false;
}

export const isNewSignUpVersion = () => {
  return process.env.REACT_APP_NEW_SIGNUP === 'true' ? true : false;
}

export const DEVICE_TYPE = {
  WEB: 'web',
  ANDROID: 'android',
  IOS: 'ios',
  IFRAME: 'iframe'
}

export const isIframeView = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const src = queryParams.get('src');
  return src === DEVICE_TYPE.IFRAME ? true : false
}

export const getThankYouPageUrl = (city, lang, thankYouPageConfig) => {
  let key = '';
  const langArray = lang.split("_")
  if (langArray.length === 2) {
    key = `${city}_${langArray[0].toUpperCase()}`
  }
  return thankYouPageConfig[key] || thankYouPageConfig[THANK_YOU_PAGE_DEFAULT_KEY];
}

export const getSpecificQueryParam = (queryParam) => {
  const search = window.location.search;
  let result = '';
  if (search) {
      const query = new URLSearchParams(search)
      return query.get(queryParam) || result
  }
  return result;
}

export const isConfigBasedUrl = () => {
  return process.env.REACT_APP_CONFIG_BASED_URL === 'true' ? true : false;
}