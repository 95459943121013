import { createAction } from 'redux-actions';

export const CHANGED_SUBMITTED_TEL = 'CHANGED_SUBMITTED_TEL';
export const changedSubmittedTel = createAction(CHANGED_SUBMITTED_TEL);

export const TEL_CHANGE = 'TEL_CHANGE';
export const telChange = createAction(TEL_CHANGE);

export const TEL_SUBMIT_REQUEST = 'TEL_SUBMIT_REQUEST';
export const telSubmit = createAction(TEL_SUBMIT_REQUEST);

export const TEL_SUBMIT_FAILURE = 'TEL_SUBMIT_FAILURE';
export const telSubmitFailure = createAction(TEL_SUBMIT_FAILURE);

export const TEL_RESUBMIT_FAILURE = 'TEL_RESUBMIT_FAILURE';
export const telResubmitFailure = createAction(TEL_RESUBMIT_FAILURE);

export const TEL_SUBMIT_SUCCESS = 'TEL_SUBMIT_SUCCESS';
export const telSubmitSuccess = createAction(TEL_SUBMIT_SUCCESS);

export const TEL_FORM_FIELD_CHANGED = 'TEL_FORM_FIELD_CHANGED';
export const telFormFieldChanged = createAction(TEL_FORM_FIELD_CHANGED);

export const RESEND_LOCK = 'RESEND_LOCK';
export const resendLock = createAction(RESEND_LOCK);

export const RESEND_RELEASE = 'RESEND_RELEASE';
export const resendRelease = createAction(RESEND_RELEASE);

export const SIGNUP_LOCK = 'SIGNUP_LOCK';
export const signupLock = createAction(SIGNUP_LOCK);

export const SIGNUP_RELEASE = 'SIGNUP_RELEASE';
export const signupRelease = createAction(SIGNUP_RELEASE);

export const PIN_VERIFY = 'PIN_VERIFY';
export const pinVerify = createAction(PIN_VERIFY);

export const PIN_SUBMIT_REQUEST = 'PIN_SUBMIT_REQUEST';
export const pinSubmit = createAction(PIN_SUBMIT_REQUEST);

export const PIN_SUBMIT_FAILURE = 'PIN_SUBMIT_FAILURE';
export const pinSubmitFailure = createAction(PIN_SUBMIT_FAILURE);

export const PIN_SUBMIT_SUCCESS_NO_LOADING = 'PIN_SUBMIT_SUCCESS_NO_LOADING';
export const pinSubmitSuccess = createAction(PIN_SUBMIT_SUCCESS_NO_LOADING);

export const PIN_REQUEST_RESEND = 'PIN_REQUEST_RESEND';
export const pinRequsetResend = createAction(PIN_REQUEST_RESEND);

export const SET_IDENTITY_TRACKER = 'SET_IDENTITY_TRACKER'
export const setIdentityTracker = createAction(SET_IDENTITY_TRACKER)

export const SET_DRIVER_ID = 'SET_DRIVER_ID'
export const setDriverId = createAction(SET_DRIVER_ID)
