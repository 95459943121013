import {
  GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
  GET_TRAINING_SESSION_VENUES_SUCCESS,
  GET_TRAINING_SESSION_SLOT_SUCCESS,
  GET_TRAINING_SESSION_SLOT_REQUEST,
  ENROLL_TRAINING_SESSION_FAILURE,
  ENROLL_TRAINING_SESSION_REQUEST,
  ENROLL_TRAINING_SESSION_SUCCESS,
  CHANGE_CAN_SKIP_TRAINING_SESSION,
} from 'store/actions/trainingSession';
import {
  CHANGED_SUBMITTED_TEL,
} from 'store/actions/auth';

const initialState = {
  venues: [],
  availableDates: {},
  availableTime: [],
  gettingSlot: false,
  isSubmitting: false,
  canSkipTrainingSession: true
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRAINING_SESSION_SLOT_REQUEST:
      return {
        ...state,
        gettingSlot: true,
      };
    case GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING:
    case GET_TRAINING_SESSION_VENUES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_TRAINING_SESSION_SLOT_SUCCESS:
      return {
        ...state,
        ...payload,
        gettingSlot: false,
      };
    case ENROLL_TRAINING_SESSION_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case ENROLL_TRAINING_SESSION_FAILURE:
    case ENROLL_TRAINING_SESSION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case CHANGED_SUBMITTED_TEL:
      return {
        ...state,
        ...initialState,
      };
    case CHANGE_CAN_SKIP_TRAINING_SESSION:
      if (!payload.hasOwnProperty("canSkipTrainingSession")) {
        return state;
      }
      
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
