import React from 'react';
import { func } from 'prop-types';
import '../styles.css';

const CampaignMissions = ({ t }) => (
  <div className="container section detailsSection">
    <h4 className="section-heading">
      { t('Campaign.driverCampaign.info.yourMission') }
    </h4>
    <p className="section-description min-height-100" />
  </div>
);

export default CampaignMissions;

CampaignMissions.propTypes = {
  t: func.isRequired,
};

CampaignMissions.defaultProps = {
};
