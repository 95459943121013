import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import {
  func,
  shape,
  bool,
  string,
} from 'prop-types';

import { getCampaignDetailsRequest } from 'store/actions/campaign';

import './style.css';
import { FlexGrowDiv } from 'style';
import { cancelBack } from '../../utils/helpers';
import CampaignInfo from './components/CampaignInfo';
import CampaignMissions from './components/CampaignMissions';
import CampaignMilestonesPerformance from './components/CampaignMilestonesPerformance';
import CampaignMilestonesDescription from './components/CampaignMilestonesDescription';
import BackIcon from '../../assets/BackArrow.png';

export class CampaignDetails extends Component {
  static propTypes = {
    getCampaignDetailsRequest: func.isRequired,
    match: shape({
      isExact: bool,
      path: string,
      url: string,
      params: shape(),
    }).isRequired,
    campaign: shape(),
    t: func.isRequired,
  };

  static defaultProps = {
    campaign: null,
  }

  componentDidMount() {
    // fix iOS 9 auto focus scroll to bottom issue
    document.body.scrollTop = 0;
    cancelBack();
    this.props.getCampaignDetailsRequest({
      id: this.props.match.params.id,
      mock: true,
    });
  }

  handleBackButton = () => {}

  render() {
    const { campaign, t } = this.props;
    const {
      banner = '',
      campaignDescription = '',
      campaignName = '',
      startDate = '',
      endDate = '',
    } = campaign || {};
    const campInfoProps = {
      campaignDescription,
      campaignName,
      startDate,
      endDate,
      t,
    };

    return (
      <div className="campaignContainer campaign-box">
        {
          campaign
          && (
            <FlexGrowDiv>
              <div className="top-bar">
                <figure className="back-button" onClick={this.handleBackButton} role="presentation">
                  <img
                    src={BackIcon}
                    width="20px"
                    height="20px"
                    title={t('Campaign.driverCampaign.info.backButton')}
                    alt="Back button"
                  />
                </figure>
                <p className="header">
                  {t('Campaign.driverCampaign.info.heading')}
                </p>
                <p />
              </div>
              {banner && <img data-type="banner-img" src={banner} width="100%" height="auto" alt={banner} />}
              <CampaignInfo
                {...campInfoProps}
              />
              <CampaignMilestonesPerformance t={t} />
              <CampaignMissions t={t} />
              <CampaignMilestonesDescription t={t} />
            </FlexGrowDiv>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.campaign.details,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    getCampaignDetailsRequest,
  }),
)(CampaignDetails);
