import _ from 'lodash';

import Api from './utils/api';
import log from './utils/log';
import {
  CONFIG_COUNTRIES_SUCCESS,
  CONFIG_REGION_COUNTRIES,
  CONFIG_DRIVER_DEVICE,
  changeCountry,
  driverDevice,
  errorChanged,
  setUTMParams,
  setNearestCity,
  changeCity,
  setIframeCountriesUrl
} from './store/actions/config';

import {
  SET_DRIVER_FIELDS,
  GET_BASIC_INFO_SUCCESS
} from './store/actions/signup';

import { TRACKING_REGISTRATION_PAGE_FIRST_VIEWED } from './store/actions/tracking';
import { setIdentityTracker, setDriverId } from './store/actions/auth'

import { EventTypes } from 'redux-segment';

import { convertLang, isNewSignUpVersion, DEVICE_TYPE, isConfigBasedUrl } from './utils/helpers';
import { COUNTRY_URL_MAP } from './utils/urlMapping';
import { setAdditionalDisplayFlag } from './store/actions/additionalInfo';
import { ADDITIONAL_INFO_VIA_DRIVER_APP } from './containers/AdditionalInfo/constants';

export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY;
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const CAMPAIGN_FEATURE = process.env.REACT_APP_CAMPAIGN;
export const CORS_ANYWHERE_URL = process.env.REACT_APP_CORS_ANYWHERE_URL || '';

const DEFAULT_CITY = process.env.REACT_APP_DEFAULT_CITY;

//Genesys Chat Widget Config
export const WEB_CHAT_DATA_URL = process.env.REACT_APP_WEB_CHAT_DATA_URL;
export const WEB_CHAT_DATA_APP_URL = process.env.REACT_APP_WEB_CHAT_DATA_APP_URL;
export const WEB_CHAT_DEPLOYMENT_KEY = process.env.REACT_APP_WEB_CHAT_DEPLOYMENT_KEY;
export const WEB_CHAT_ORG_GUID = process.env.REACT_APP_WEB_CHAT_ORG_GUID;
export const WEB_CHAT_AVAILABLE_LANGUAGES = process.env.REACT_APP_WEB_CHAT_AVAILABLE_LANGUAGES;
export const WEB_CHAT_BOT_QUEUES_AVAILABLE = process.env.REACT_APP_WEB_CHAT_BOT_QUEUES_AVAILABLE || '';
export const WEB_CHAT_BOT_DATA_APP_URL = process.env.REACT_APP_WEB_CHAT_BOT_DATA_APP_URL;
export const WEB_CHAT_REGION_USER_QUEUE_ALIAS = JSON.parse(process.env.REACT_APP_WEB_CHAT_REGION_USER_QUEUE_ALIAS);
export const WEB_CHAT_REGION_DRIVER_QUEUE_ALIAS = JSON.parse(process.env.REACT_APP_WEB_CHAT_REGION_DRIVER_QUEUE_ALIAS);
export const WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS = JSON.parse(process.env.REACT_APP_WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS);
export const WEB_CHAT_VCONSOLE_ENABLED = process.env.REACT_APP_WEB_CHAT_VCONSOLE_ENABLED === 'true' ? true : false;
export const WEB_CHAT_NEW_FEAT_ENABLED = JSON.parse(process.env.REACT_APP_WEB_NEW_FEAT_ENABLED);
export const WEB_CHAT_NEW_FEAT_QUEUES = JSON.parse(process.env.REACT_APP_WEB_NEW_FEAT_QUEUE);
export const WEB_CHAT_REGION_CONFIG_API = process.env.REACT_APP_REGION_CONFIG_API || '';
const getCoordinates = () => {
  const Promise = require('promise-polyfill').default;

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

// extract lat and long of every country
const extractLatLngForCountries = countriesConfig => {
  const latLng = [];
  countriesConfig.map(config => {
    latLng.push({
      country: config.country,
      lat: config.lat,
      lng: config.lng
    });
    return true;
  });
  return latLng;
};

// extract all languages available for a country
const extractLanguageForCountry = (countriesConfig, country) =>
  _.map(_.find(countriesConfig, { country }).translations, 'id');

// extract all countries available
const extractCountries = countriesConfig => {
  const countries = [];
  countriesConfig.map(config => countries.push(config.country));
  return countries;
};

// extract country and lang user pass in from the url
const extractUserInputtedCountryAndLanguage = () => {
  const paths = window.location.pathname.split('/');
  if (paths.length < 4) {
    return {
      country: DEFAULT_COUNTRY,
      lang: DEFAULT_LANGUAGE
    };
  }

  return {
    country: paths[2],
    lang: paths[3]
  };
};

export const coordPlanarDistance = (
  latitude1,
  longitude1,
  latitude2,
  longitude2
) => {
  const latDiff = latitude2 - latitude1;
  const lngDiff = longitude2 - longitude1;
  const d = Math.sqrt(latDiff ** 2 + lngDiff ** 2);
  return d;
};

const getNearestCountry = (data, lat, lng) => {
  let nearestDistance = Number.MAX_SAFE_INTEGER;
  let nearestCountry = DEFAULT_COUNTRY;
  for (let i = 0; i < data.length; i += 1) {
    const currentDistance = coordPlanarDistance(
      lat,
      lng,
      data[i].lat,
      data[i].lng
    );
    if (currentDistance < nearestDistance) {
      nearestDistance = currentDistance;
      nearestCountry = data[i].country;
    }
  }
  return nearestCountry;
};

async function getCountry(latLngFroCountries) {
  let coords = {};
  try {
    ({ coords } = await getCoordinates());
  } catch (e) {
    return DEFAULT_COUNTRY;
  }

  const country = getNearestCountry(
    latLngFroCountries,
    coords.latitude,
    coords.longitude
  );
  return country;
}

const addCountryTranslationsToi18n = (countriesConfig, i18n) => {
  const translations = countriesConfig.reduce((arr, country) => {
    return [...arr, ...country.translations];
  }, []);

  translations.map(translation =>
    i18n.addResourceBundle(
      translation.id,
      'translation',
      { Home: translation },
      true
    )
  );
};

const detectUserAgent = () => {
  if (navigator.userAgent.match(/iP/i)) {
    return 'ios';
  } else if (navigator.userAgent.match(/Android/i)) {
    return 'android';
  }
  return 'web';
};

const checkBrowserCompatibility = store => {
  if (navigator.userAgent.match(/MSIE [1-8]\./) !== null) {
    store.dispatch(
      errorChanged({
        from: 'browserCompatibility'
      })
    );
    log.info('browser not supported', {
      userAgent: navigator.userAgent
    });
    throw Error('browser not supported');
  }
};

//fetch utm data from URL
const getURLUtmParams = () => {
  let utmObj = {};
  const path = getPathFromQueryString();
  if (path) {
    const urlObj = new URL(path);
    const query = new URLSearchParams(urlObj.search);
    if (query) {
      utmObj['utm_source'] = query.get('utm_source');
      utmObj['utm_medium'] = query.get('utm_medium');
      utmObj['utm_campaign'] = query.get('utm_campaign');
      utmObj['utm_term'] = query.get('utm_term');
      utmObj['utm_content'] = query.get('utm_content');
      utmObj['utm_campaigntype'] = query.get('utm_campaigntype');
      utmObj['utm_adname'] = query.get('utm_adname');
    }
  }
  Object.keys(utmObj).forEach(function (key) {
    if (!utmObj[key]) {
      delete utmObj[key];
    }
  });
  return utmObj;
};

//fetch city and iframne from url
const getCountryAndLanguageFromIframe = (landingPageConfig) => {
  let keyUrl;
  const responseObj = {
    city: DEFAULT_CITY,
    lang: DEFAULT_LANGUAGE,
    country: DEFAULT_COUNTRY
  };
  //path parameter from iframe
  const path = getPathFromQueryString();
  const urlObj = new URL(path);
  const paths = urlObj.pathname.split('/');
  if (paths.length >= 2) {
    keyUrl = paths
      .slice(1, 4)
      .join('/')
      .toLowerCase();
    if (landingPageConfig[keyUrl]) {
      return landingPageConfig[keyUrl];
    }
  }
  return responseObj;
};

const getPathFromQueryString = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('path') || '';
};
export default async function startup(store, history, i18n) {
  try {
    if (window.location.pathname === '/') {
      window.location.replace('/register' + window.location.search);
    }
    if (window.location.pathname.indexOf('/register') !== -1) {
      // get everything we need to render the registration page
      let locations, registration, iframeCountriesUrl;
      try {
        if (isConfigBasedUrl()) {
          [locations, registration, iframeCountriesUrl] = await Promise.all([
            Api.getLocations({}),
            Api.getInitRegistrationData(),
            Api.getIframeLalamoveUrls()
          ]);
        } else {
          [locations, registration] = await Promise.all([
            Api.getLocations({}),
            Api.getInitRegistrationData(),
          ]);
        }
      } catch (err) {
        throw Error('server error');
      }
      let { locationStatusCode, locationBody } = locations;
      let { statusCode, body } = registration;
      if (locationStatusCode !== 200 || statusCode !== 200) {
        throw Error('server error');
      }
      let ConfigCountries = body.data;
      let locationsConfig = locationBody.data;
      let locationsConfigMap = {};
      let iframeCountriesUrlConfig = {};

      if (isConfigBasedUrl()) {
        let { statusCode: iframeUrlApiStatusCode, body: iframeUrlApibody } = iframeCountriesUrl;
        if (iframeUrlApiStatusCode !== 200) {
          throw Error('server error');
        }
        //save url specific countries url
        const {
          DRIVER_REGISTRATION_LANDING_PAGE: landingPageConfig,
          DRIVER_REGISTRATION_THANKYOU_PAGE: thankYouPageConfig
        } = iframeUrlApibody.data;
        iframeCountriesUrlConfig = { landingPageConfig, thankYouPageConfig };
        store.dispatch(setIframeCountriesUrl(iframeCountriesUrlConfig));
      }

      locationsConfig.map(location => {
        locationsConfigMap[location.id] = location;
        return null;
      });
      ConfigCountries = ConfigCountries.map(configCountry => {
        // put domain config if exist
        if (locationsConfigMap[configCountry.country]) {
          configCountry.domainApp =
            locationsConfigMap[configCountry.country].domainApp;
        }
        return configCountry;
      });
      await store.dispatch({
        type: CONFIG_COUNTRIES_SUCCESS,
        payload: { ConfigCountries }
      });
      // load all translations for country
      addCountryTranslationsToi18n(ConfigCountries, i18n);
      let country = DEFAULT_COUNTRY;
      let lang = DEFAULT_LANGUAGE;
      let city = DEFAULT_CITY;
      const paramsObj = new URLSearchParams(window.location.search);
      const source = paramsObj.get('src');

      // fetch utm data from url & update utm
      const propertyObject = getURLUtmParams();

      if (source === DEVICE_TYPE.IFRAME) {
        //update utmparams only for iframe
        store.dispatch(setUTMParams(propertyObject));
      }
      if (
        (window.location.pathname === '/register' ||
          window.location.pathname === '/register/') &&
        source !== DEVICE_TYPE.IFRAME
      ) {
        if (!isNewSignUpVersion()) {
          try {
            // ask user permission on geolocation and get user's located country
            const latLngFroCountries = extractLatLngForCountries(
              ConfigCountries
            );
            country = await getCountry(latLngFroCountries);
          } catch (e) {
            country = DEFAULT_COUNTRY;
          }
          // get browser language
          lang = navigator.language.replace('-', '_');
        }
      } else if (isNewSignUpVersion() && source === DEVICE_TYPE.IFRAME) {
        // iframe fucntionality
        if (isConfigBasedUrl()) {
          ({ country, city, lang } = await getCountryAndLanguageFromIframe(iframeCountriesUrlConfig.landingPageConfig));
        } else {
          ({ country, city, lang } = await getCountryAndLanguageFromIframe(COUNTRY_URL_MAP));
        }
        store.dispatch(setNearestCity({ nearestCity: city })); //update nearest city
      } else {
        ({ country, lang } = await extractUserInputtedCountryAndLanguage());
      }
      country = country.toUpperCase();
      lang = convertLang(lang);
      var countrySelected = ConfigCountries.filter(element => {
        return element.country === country;
      });
      countrySelected = countrySelected.length > 0 ? countrySelected[0] : [];
      // filter countries belongs to that region
      const configs = ConfigCountries.filter(element => {
        if ('regionCode' in element && 'regionCode' in countrySelected) {
          return countrySelected.regionCode === element.regionCode;
        }
        return true;
      });
      await store.dispatch({
        type: CONFIG_REGION_COUNTRIES,
        payload: { configs }
      });
      const allCountries = extractCountries(configs);
      if (allCountries.indexOf(country) === -1) {
        country = DEFAULT_COUNTRY;
      }
      var countryLanguages = extractLanguageForCountry(configs, country);
      if (countryLanguages.indexOf(lang) === -1) {
        lang = _.find(configs, { country }).defaultLanguage;
      }
      let os = detectUserAgent();
      store.dispatch(
        driverDevice({
          device: os
        })
      );

      store.dispatch(
        changeCountry({
          country,
          lang
        })
      );

      checkBrowserCompatibility(store);

      if (!isNewSignUpVersion) {
        //for old version works same as before
        //add os source and country in properties
        propertyObject['llm_source'] = os.toLowerCase();
        propertyObject['country'] = country;

        store.dispatch({
          type: TRACKING_REGISTRATION_PAGE_FIRST_VIEWED,
          meta: {
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'Registration First Page Viewed',
                properties: propertyObject
              }
            }
          }
        });
      }

      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const action = params.get('action');
      const revision = params.get('revision');

      //calling getSignupInfo for normal flow in new signup version
      if (isNewSignUpVersion() && !action && !token) {
        try {
          Api.changeLocale(lang);
          let { statusCode, body } = await Api.getSignupInfo(); //fetch form-details
          if (statusCode !== 200) {
            throw Error('server error');
          }
          store.dispatch({
            type: GET_BASIC_INFO_SUCCESS,
            payload: {
              fields: body.data
            }
          });
        } catch (err) {
          throw Error('server error');
        }
      }

      if (action) {
        if (token) {
          const payload = {
            token: token,
            country: country,
            os: os,
            revision: revision,
            app: 'driver'
          };

          let {
            responseStatusCode,
            responseBody
          } = await Api.getDriverAuthInfo(payload);

          if (responseStatusCode !== 200 || responseBody.success === false) {
            throw Error('Invalid Driver Token');
          }
          const identityPayload = {
            driverTrackingId: `${country}_${responseBody.data.driverId}`,
            tel: responseBody.data.driverTel,
            driverIdWithoutCountry: responseBody.data.driverId
          }
          //reset identity when driver auth info is called
          await store.dispatch(setIdentityTracker(identityPayload))
          await store.dispatch(setDriverId({
            driverIdWithoutCountry: identityPayload.driverIdWithoutCountry,
            driverTrackingId: identityPayload.driverTrackingId
          }))
          await store.dispatch(changeCity({ city: responseBody.data.city }));
          Api.changeLocation(responseBody.data.city);
          Api.changeToken(token);
          Api.changeLocale(lang);

          //This request will always be from driver app
          await store.dispatch({
            type: CONFIG_DRIVER_DEVICE,
            payload: {
              fromDriverApp: true
            }
          });

          await store.dispatch({
            type: SET_DRIVER_FIELDS,
            payload: {
              submittedFields: {
                FORM_CITY: responseBody.data.city,
                FORM_NAME: responseBody.data.driverName,
                FORM_PASSWORD: '',
                FORM_VEHICLE_TYPE: `VEHICLE_${responseBody.data.vehicleType}`
              }
            }
          });
        } else {
          throw Error('Missing Driver Token');
        }
        if (isNewSignUpVersion()) {
          let { statusCode, body } = await Api.getAdditionalInfoFlag(); //fetch flag to show additional-info
          if (statusCode !== 200) {
            throw Error('server error');
          }
          if (body.data.additionalPageShow) {
            await store.dispatch(
              setAdditionalDisplayFlag({
                additionalDisplay: true,
                flow: ADDITIONAL_INFO_VIA_DRIVER_APP
              })
            );
            history.push(`/register/additional-info${history.location.search}`);
          } else {
            history.push(
              `/driver/training-session-booking${history.location.search}`
            );
          }
        } else {
          history.push(
            `/driver/training-session-booking${history.location.search}`
          );
        }
      } else {
        history.push(`/register/${country}/${lang}${history.location.search}`);
      }
    }
  } catch (e) {
    history.push('/register/error');
  }
}
