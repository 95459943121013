import styled from 'styled-components';

import { createGlobalStyle, css } from 'styled-components';

export const orange = 'rgba(242,103,34,1)';
export const gold = 'rgba(250,162,27,1)';
export const yellow = 'rgba(245,215,110,1)';
export const red = 'rgba(216,83,79,1)';
export const green = 'rgba(25,188,155,1)';
export const white = 'rgba(255,255,255,1)';
export const offWhite = 'rgba(229,229,229,1)';
export const silver = 'rgba(128,130,133,1)';
export const gray = 'rgba(88,89,91,1)';
export const black = 'rgba(51,49,50,1)';
export const facebook = 'rgba(59, 89, 152, 1)';
export const blue = 'rgba(47,160,237, 1)';

const colors = {
	orange,
	gold,
	white,
	red,
	green,
	gray,
	silver,
	offWhite,
	black,
	facebook,
};

export const GlobalStyle = createGlobalStyle`
  :root {
    ${Object.keys(colors).map(key => css`--${key}-color: ${colors[key]};`)}
  }
`;

export default colors;


// used for places that need to make other components stay at the bottom
export const FlexGrowDiv = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;
