
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { resetLoading, hideLoading, showLoading } from 'react-redux-loading-bar';
import { EventTypes } from 'redux-segment';

import {
    GET_ADDITIONAL_INFO,
    SET_ADDITIONAL_INFO,
    getAdditionalInfoCompleted,
    setAdditionalInfoCompleted,
    GET_ADDITIONAL_INFO_POST,
    setAdditionalInfoError,
} from 'store/actions/additionalInfo';
import { getQueryParams, getCurrentCountry, getAdditionalInfo, getThankYouPageUrls } from 'store/selectors';
import Api from 'utils/api';
import {
    ERROR_TYPE_LENGTH_TOO_LONG,
    ERROR_TYPE_REQUIRED,
    getThankYouPageUrl,
    DEVICE_TYPE,
    isIframeView,
    isConfigBasedUrl
} from 'utils/helpers';
import {
    trackingAdditionalInfoPageFirstViewed,
    trackingAdditionalInfoPageDataSubmitted
} from 'store/actions/tracking'
import {
    ADDITIONAL_INFORMATION_PAGE_VIEWED,
    ADDITIONAL_INFORMATION_PAGE_DATA_SUBMITTED,
    FILLED,
    NOT_FILLED,
    NO_FIELDS,
    ADDITIONAL_INFO_SIGNUP_POPUP,
    ADDITIONAL_INFO_VIA_DRIVER_APP
} from 'containers/AdditionalInfo/constants'
import {
    GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
    initializeTrainingSessionFields
} from 'store/actions/trainingSession';
import { formFinish } from 'store/actions/config';
import { THANK_YOU_PAGE_MAPPING } from 'utils/urlMapping';

export function* handleAdditionalInfoSubmit({ payload }) {
    const queryParams = yield select(getQueryParams);
    const { city, lang, device } = yield select(getCurrentCountry)
    const { flow } = yield select(getAdditionalInfo)
    try {
        yield put(showLoading())
        const { submittedFields } = payload
        //tracking event trigger 
        yield put(trackingAdditionalInfoPageDataSubmitted({
            analytics: {
                eventType: EventTypes.track,
                eventPayload: {
                    event: ADDITIONAL_INFORMATION_PAGE_DATA_SUBMITTED,
                    properties: submittedFields,
                }
            }
        })());
        const postData = Object.keys(submittedFields).reduce((arr, key) => {
            return [...arr, { driverRegistrationInfoKey: key, value: submittedFields[key] }];
        }, []);

        const response = yield postData.map(d => call(Api.postAdditionalInfo, d));
        let error = null
        for (let index in response) {
            const { statusCode, body } = response[index];
            if (statusCode !== 201) {
                const { inputKey } = body.error
                switch (statusCode) {
                    case 422:
                        error = {
                            driverRegistrationInfoKey: inputKey,
                            type: ERROR_TYPE_LENGTH_TOO_LONG,
                        }
                        break;
                    case 404:
                        error = {
                            driverRegistrationInfoKey: inputKey,
                            type: ERROR_TYPE_REQUIRED,
                        }
                        break;
                    default:
                        throw Error('server error');
                }
                yield put(setAdditionalInfoError({ error }))
            }
        }
        //call api once again with additionalComplete flag
        if (!error) {
            const additionalCompleteFlag = {
                driverRegistrationInfoKey: 'additional_field_display', value: FILLED
            }
            const { statusCode } = yield call(Api.postAdditionalInfo, additionalCompleteFlag)
            if (statusCode === 201) {
                yield put(setAdditionalInfoCompleted({ status: true }))

                if (isIframeView() || device === DEVICE_TYPE.WEB ){
                    let thankYouPageMap = THANK_YOU_PAGE_MAPPING;
                    if (isConfigBasedUrl()){
                        thankYouPageMap = yield select(getThankYouPageUrls)
                    } 
                    window.parent.location.href = getThankYouPageUrl(city, lang, thankYouPageMap)
                } else if (flow === ADDITIONAL_INFO_VIA_DRIVER_APP){ 
                    //redirection when user drop off from additional or training page
                    yield put(formFinish({ isFormFinish: false }))
                    yield put(initializeTrainingSessionFields())
                } else { //redirection when continuous flow occurs
                    yield put({
                        type: GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
                        payload: {
                            locale: lang,
                        }
                    });
                }
            } else {
                yield put(replace(`/register/error${queryParams}`));
            }
        }
    }
    catch (e) {
        yield put(replace(`/register/error${queryParams}`));
    }
    finally {
        yield put(hideLoading());
        yield put(resetLoading());
    }
}

export function* handleGetAdditionalInfoPost() {
    let queryParams = '';
    try {
        queryParams = yield select(getQueryParams);
        queryParams = queryParams || '';
        const { city, device, lang } = yield select(getCurrentCountry)
        const response = yield call(Api.getAdditionalInfoPost);
        const { statusCode, body } = response;
        const { additionalDisplay, additionalFields } = body.data
        let additionalPageDisplay = false;
        switch (additionalDisplay) {
            case NO_FIELDS:
            case FILLED:
                break;
            case NOT_FILLED:
                additionalPageDisplay = true
                break;
            default: additionalPageDisplay = false
        }
        if (statusCode === 200) {
            yield put(getAdditionalInfoCompleted({
                additionalDisplay: additionalPageDisplay,
                fields: additionalFields,
                flow: ADDITIONAL_INFO_SIGNUP_POPUP
            }))
            if (additionalPageDisplay) { // display additional info page
                yield put(trackingAdditionalInfoPageFirstViewed({
                    analytics: {
                        eventType: EventTypes.track,
                        eventPayload: {
                            event: ADDITIONAL_INFORMATION_PAGE_VIEWED,
                        }
                    }
                })());
                yield put(replace(`/register/additional-info${queryParams}`))
            } else if (isIframeView() || device === DEVICE_TYPE.WEB ){
                let thankYouPageMap = THANK_YOU_PAGE_MAPPING;
                if (isConfigBasedUrl()){
                    thankYouPageMap = yield select(getThankYouPageUrls)
                } 
                window.parent.location.href = getThankYouPageUrl(city, lang, thankYouPageMap)
            } else { //display existing workflow for mobile 
                yield put({
                    type: GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
                    payload: {
                        locale: lang,
                    }
                });
            }
        } else {
            throw Error(response.error)
        }
    } catch (e) {
        yield put(replace(`/register/error${queryParams}`));
    }
    finally {
        yield put(hideLoading());
        yield put(resetLoading());
    }
};

export function* handleGetAdditionalInfo() {
    let queryParams = '';
    try {
        queryParams = yield select(getQueryParams);
        queryParams = queryParams || '';
        const response = yield call(Api.getAdditionalInfo);
        const { statusCode, body } = response;

        if (statusCode === 200) {
            yield put(getAdditionalInfoCompleted({
                fields: body.data,
                additionalDisplay: false,
                flow: ADDITIONAL_INFO_VIA_DRIVER_APP,
            }))
            yield put(trackingAdditionalInfoPageFirstViewed({
                analytics: {
                    eventType: EventTypes.track,
                    eventPayload: {
                        event: ADDITIONAL_INFORMATION_PAGE_VIEWED,
                    }
                }
            })());
        } else {
            throw Error(response.error)
        }
    } catch (e) {
        yield put(replace(`/register/error${queryParams}`));
    }
    finally {
        yield put(hideLoading());
        yield put(resetLoading());
    }
};

export default function* authSaga() {
    yield takeLatest(SET_ADDITIONAL_INFO, handleAdditionalInfoSubmit);
    yield takeLatest(GET_ADDITIONAL_INFO, handleGetAdditionalInfo);
    yield takeLatest(GET_ADDITIONAL_INFO_POST, handleGetAdditionalInfoPost);
}
