import config from './config';
import auth from './auth';
import trainingSession from './trainingSession';
import campaign from './campaign';
import additionalInfo from './additionalInfo'

export default {
  config,
  auth,
  additionalInfo,
  trainingSession,
  campaign
};
