import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { formFinish } from 'store/actions/config';
import {
  func,
  shape,
  string,
  oneOfType,
  object,
} from 'prop-types';
import { FlexGrowDiv } from 'style';
import {
  Image,
  Notice,
  Title,
  SubTitle,
  NextButton,
} from './style';

export class Error extends Component {
  static propTypes = {
    t: func.isRequired,
    history: oneOfType([func, object]).isRequired,
    error: shape({
      from: string,
    }).isRequired,
    formFinish: func,
  };

  state = {};

  componentDidMount() {
    this.props.formFinish({
        isFormFinish: true,
    })
  };
  
  handleRetryClick = () => this.props.history.go('/register' + window.location.search);

  render() {
    const {
      t,
      error: {
        from,
      },
      csDriverPhone,
    } = this.props;

    return (
      <div className="mainContainer">
        <FlexGrowDiv>
          <div className="paddingWrapper">
            <Notice>
              {from === 'trainingSession' && t('TrainingSession.title') }
            </Notice>
            <Image
              src="/assets/error_van.svg"
            />
            {from === 'general' && (
              <div>
                <Title>
                  {t('Error.generalError.title')}
                </Title>
                <SubTitle>
	                {`${t('Error.generalError.subtitle').replace('[CS_TEL]', csDriverPhone)}`}
                </SubTitle>
              </div>
            )}
            {from === 'trainingSession' && (
              <div>
                <Title>
                  {t('Error.scheduleError.title')}
                </Title>
                <SubTitle>
	                {`${t('Error.scheduleError.subtitle').replace('[CS_TEL]', csDriverPhone)}`}
                </SubTitle>
              </div>
            )}
            {from === 'browserCompatibility' && (
              <div>
                <Title>
                  {t('Error.browserCompatibilityError.title')}
                </Title>
                <SubTitle>
                  {t('Error.browserCompatibilityError.subtitle')}
                </SubTitle>
              </div>
            )}
          </div>
        </FlexGrowDiv>
        {from !== 'browserCompatibility' && from !== 'trainingSession' && (
          <NextButton
            variant="primary"
            size="xlarge"
            solid
            onClick={this.handleRetryClick}
          >
            {t('Error.retryButton')}
          </NextButton>
        )}
	      {from === 'trainingSession' && (
        <Link to={{
          pathname: "/register/welcome",
          search: this.props.location ? this.props.location.search : ''
        }}>
          <NextButton
            variant="primary"
            size="xlarge"
            solid
          >
			      {t('TrainingSession.nextButton')}
          </NextButton>
        </Link>
	      )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  csDriverPhone: state.config.country.csDriverPhone,
  error: state.config.error,
  completed: state.config.completed,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    formFinish,
  }),
)(Error);
