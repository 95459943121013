import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { RadioGroup } from '@lalamove/karang';
import { noop } from 'utils/helpers';
import { func, array } from 'prop-types';

const SessionWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
`;

class Timeslot extends Component {
  static propTypes = {
    onChange: func,
    availableTime: array,
  };

  static defaultProps = {
    onChange: noop,
    availableTime: [],
  };

  state = {
    selected: null,
  };

  handleOnChange = (value) => {
    this.setState({ selected: value });
    this.props.onChange(value);
  };

  render() {
    const { availableTime } = this.props;
    return (
      <SessionWrapper>
        <RadioGroup
          name="session"
          onChange={this.handleOnChange}
          value={this.state.selected}
        >
          {Radio => (
            <Fragment>
              {
                availableTime.map((slot) => {
                  return (
                    <Radio
                      key={`slot-${slot.key}`}
                      value={'' + slot.key}
                      style={{
                        border: '' + slot.key === this.state.selected ? '1px solid #F16622' : '1px solid #E8E8E8',
                        padding: '0.75em',
                        color: '#58595B',
                        fontSize: '16px',
                        marginTop: '0.5em',
                        display: 'flex',
                        lineHeight: '1.5em',
                        alignItems: 'center',
                      }}
                    >
                      {slot.value}
                    </Radio>
                  );
                })
              }
            </Fragment>
          )}
        </RadioGroup>
      </SessionWrapper>
    );
  }
}

export default Timeslot;
