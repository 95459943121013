import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Trans, translate } from 'react-i18next';
import LanguageSelector from 'components/LanguageSelector';
import { Input, Select, Button, Checkbox } from '@lalamove/karang';

import {
  clone,
  handleFieldErrorOnBasicInfo,
  ERROR_TYPE_LENGTH_TOO_LONG,
  ERROR_TYPE_PASSWORD_TOO_SHORT,
  ERROR_TYPE_REQUIRED,
  OTHER_FORM_FIELDS,
  ERROR_TYPE_INVALID_EMAIL_FORMAT,
  PHONE_FORMAT,
  validateEmail
} from 'utils/helpers';

import driverIcon from 'assets/icon_driver.svg';
import ModalLink from 'components/ModalLink';
import Api from 'utils/api';
import {
  defaultProps,
  propsValidation,
  normalize,
  FORM_CITY,
  handleKeyPress,
  mapDispatchToProps,
  handleCopyPaste,
  FORM_PASSWORD,
  FORM_EMAIL,
  FORM_TEL,
  mapStateToProps,
  stateObj
} from './constants';
import './style.css';

import { FlexGrowDiv } from 'style';

export class PersonalInfo extends Component {
  static propTypes = propsValidation;
  static defaultProps = defaultProps;

  state = stateObj;

  componentDidMount() {
    window.analytics.reset()
    this.props.triggerTracker()
    const { submittedFields } = this.props;
    let stateValue = {};
    let filledValue = {};
    if (submittedFields && Object.keys(submittedFields).length) {
      for (var key in submittedFields) {
        stateValue[key] = submittedFields[key];
        filledValue[key] = !!submittedFields[key];
      }
    }
    if (Object.keys(stateValue).length) {
      this.setState({
        value: stateValue,
        filled: filledValue
      });
    }
  }

  /**@description this method sets the value of selected dropdown when page reloads (eg. language change) */
  getSelectedItemFromKey = selectedField => {
    let selectedItem = null;
    const key = selectedField.driverRegistrationInfoKey;
    if (key === FORM_CITY) {
      const updatedCityLookup = this.updateFormCityWithAreaCode(
        selectedField.lookup
      );
      selectedItem =
        updatedCityLookup.find(item => item.id === this.state.value[key]) ||
        updatedCityLookup[0];
    } else {
      selectedItem = selectedField.lookup.find(
        item => item.id === this.state.value[key]
      );
    }
    return selectedItem;
  };

  onInputChange = e => {
    const { name, value } = e.target;
    var regexp = new RegExp('\\s');
    if (name === FORM_PASSWORD && regexp.test(value)) {
      return;
    }
    if (name === FORM_TEL) {
      this.handleTelChange(e);
    }
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [name]: value
      },
      error: {
        ...prevState.error,
        [name]: null
      },
      filled: {
        ...prevState.filled,
        [name]: false
      }
    }));
  };

  static getDerivedStateFromProps(props, state) {
    const { personalInfoField, t, city } = props;
    let selectCity = {};
    let errorString = state.error[FORM_TEL] || null;
    // when first come to this page, no city should be set
    // set the deafult city as selected
    if (!state.value[FORM_CITY]) {
      const cityField = Object.values(personalInfoField).filter(
        field => field.driverRegistrationInfoKey === FORM_CITY
      );
      if (cityField.length > 0) {
        const selectedCityInfo = cityField[0].lookup.filter(
          item => item.id === city
        )[0];

        if (selectedCityInfo !== undefined) {
          selectCity = {
            [FORM_CITY]: selectedCityInfo.id
          };
        }
      }
    }
    if (props.form.error) {
      //error in tel_form field which is in auth reducer
      errorString = props.form.error && t(`Home.error.${props.form.error}`);
    }
    return {
      ...state,
      value: {
        ...state.value,
        ...selectCity
      },
      filled: {
        ...state.filled,
        [FORM_CITY]: true
      },
      error: {
        ...state.error,
        [FORM_TEL]: errorString
      }
    };
  }
  handleTelChange = e => {
    this.props.telFormFieldChanged({
      telWithoutCountryCode: e.target.value
    });
    // clear submit error
    this.props.form.error && this.props.telChange();
  };

  handleOnBlur = e => {
    const required =
      e.target.getAttribute('data-required') !== null
        ? e.target.getAttribute('data-required')
        : e.target.required;
    const name =
      e.target.getAttribute('data-name') !== null
        ? e.target.getAttribute('data-name')
        : e.target.name;
    const value = this.state.value[name];
    let error = null;
    if (required && (!value || value.length <= 0)) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_REQUIRED
      );
    }

    if (
      e.target.maxLength !== -1 &&
      value &&
      value.toString().length > e.target.maxLength
    ) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_LENGTH_TOO_LONG,
        e.target.maxLength
      );
    }

    if (name === FORM_PASSWORD && value && value.toString().length < 6) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_PASSWORD_TOO_SHORT
      );
    }

    if (name === FORM_EMAIL && value && !validateEmail(value)) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_INVALID_EMAIL_FORMAT
      );
    }

    if (name === FORM_TEL && value && !this.checkTelephoneFormat(value)) {
      error = handleFieldErrorOnBasicInfo(this.props.t, name, PHONE_FORMAT);
    }

    if (error !== null) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          ...error
        }
      }));
      return;
    }
    this.setState(prevState => ({
      filled: {
        ...prevState.filled,
        [name]: true
      }
    }));
  };

  handleSelection = (selectedItem, dsState) => {
    if (dsState.id === FORM_CITY) {
      Api.changeLocation(selectedItem.id);
      this.props.changeCity({ city: selectedItem.id });
      this.props.getBasicInfo();
    }
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [dsState.id]: selectedItem.id
      },
      error: {
        ...prevState.error,
        [dsState.id]: null
      },
      filled: {
        ...prevState.filled,
        [dsState.id]: true
      }
    }));
  };

  handleOnSubmit = e => {
    e.preventDefault();
    this.props.formFinish({
      isFormFinish: false
    });
    var submitValues = {};
    for (var key in this.state.value) {
      if (this.state.value[key] && this.state.value[key].length > 0) {
        submitValues[key] = this.state.value[key];
      }
    }
    //save value in store for future use in pin verify
    this.props.signupSaveForm({ submittedFields: submitValues });
    const countryCode = Object.values(this.props.countriesConfig).filter(
      config => config.country === this.props.country.country
    )[0].areaCode;
    //call pin verify api
    this.props.telSubmit({
      tel: `+${countryCode}${this.state.value[FORM_TEL]}`,
      method: 'SMS'
    });
  };

  shouldNextEnable = () => {
    const requiredFields = clone(this.props.requiredFields);
    // no error on all fields
    const checkError = this.props.personalInfoField.reduce((arr, fields) => {
      const key = fields.driverRegistrationInfoKey;
      if (!(key in this.state.error) || this.state.error[key] === null) {
        return [...arr, key];
      }
      return arr;
    }, []);

    // all required fields are filled
    const checkFieldFilled = requiredFields.reduce((arr, key) => {
      if (key in this.state.filled && this.state.filled[key] === true) {
        return [...arr, key];
      }
      return arr;
    }, []);

    return (
      checkError.length !== this.props.personalInfoField.length ||
      checkFieldFilled.length !== requiredFields.length ||
      this.props.form.loading ||
      this.props.isGettingFields ||
      !this.props.form.agreeToS
    );
  };

  renderInputField = fields => {
    const compare = (a, b) => {
      if (a.sortingPriority < b.sortingPriority) return -1;
      if (a.sortingPriority > b.sortingPriority) return 1;
      return 0;
    };

    return Object.values(fields)
      .sort(compare)
      .map(field => {
        const key = field.driverRegistrationInfoKey;
        const label = this.props.t(`BasicInfo.fields.${key}`);
        let labelText = label;
        let errorString = this.state.error[key];
        //label text selection
        if (key === FORM_EMAIL && !field.required) {
          labelText = `${label} ${this.props.t(`BasicInfo.optional`)}`;
        } else if (key === FORM_TEL) {
          const countryMapper = normalize(this.props.countries);
          labelText = countryMapper[this.props.country.country]
            ? `${label} (e.g. ${
            countryMapper[this.props.country.country].samplePhone
            })`
            : label;
          // errorString = this.props.form.error && t(`Home.error.${this.props.form.error}`)
        }

        // normal text field
        if (field.lookup.length === 0) {
          let fieldProps = {};
          if (key === FORM_PASSWORD) {
            fieldProps = {
              type: 'password',
              autoCapitalize: 'none',
              masked: true
            };
          } else {
            fieldProps = {
              maxLength: field.length,
              type: key === FORM_TEL ? 'tel' : 'text'
            };
          }
          return (
            <div className="basicInfoInput" key={`${key}-div`}>
              <Input
                label={labelText}
                name={key}
                value={this.state.value[key] || ''}
                onChange={this.onInputChange}
                autoComplete="false"
                onCopy={handleCopyPaste}
                onPaste={handleCopyPaste}
                error={errorString}
                required={field.required}
                onBlur={this.handleOnBlur}
                onKeyPress={key === FORM_TEL ? handleKeyPress : () => { }}
                disabled={this.props.form.loading || this.props.isGettingFields}
                {...fieldProps}
              />
            </div>
          );
        }
        return (
          <div className="basicInfoInput" key={`${key}-div`}>
            <Select
              id={key}
              label={labelText}
              name={key}
              itemList={
                key === FORM_CITY
                  ? this.updateFormCityWithAreaCode(field.lookup)
                  : field.lookup
              }
              selectedItem={this.getSelectedItemFromKey(field)}
              onChange={this.handleSelection}
              error={this.state.error[key]}
              onBlur={this.handleOnBlur}
              required={field.required}
              disabled={this.props.form.loading || this.props.isGettingFields}
            />
          </div>
        );
      });
  };

  handleToSAgreement = e => {
    this.props.telFormFieldChanged({
      agreeToS: e.target.checked
    });
  };

  updateFormCityWithAreaCode = lookup => {
    const countryCode = Object.values(this.props.countriesConfig).filter(
      config => config.country === this.props.country.country
    )[0].areaCode;
    let listwithAreaCode = [];
    lookup.forEach(item => {
      listwithAreaCode.push({
        id: item.id,
        value: `${item.value} (+${countryCode})`
      });
    });
    return listwithAreaCode;
  };

  handleLinkClick = () => {
    this.props.telFormFieldChanged({
      agreeToS: !this.props.form.agreeToS
    });
  };

  handleLanguageSelection = item => {
    //clear store values when language changes
    this.props.signupSaveForm({ submittedFields: {} });
    if (item.id !== this.props.country.lang) {
      this.props.changeCountry({
        country: this.props.country.country,
        lang: item.id,
        history: this.props.history
      });
      // get new transation for new language
      Api.changeLocale(item.id);
      this.props.getBasicInfo();
    }
  };

  checkTelephoneFormat = telToCheck => {
    const telFormat = Object.values(this.props.countriesConfig).filter(
      config => config.country === this.props.country.country
    )[0].phoneFormat;
    if (this.props.country.isPhonePrecedingZero) {
      telToCheck = `0${telToCheck}`;
    }
    return RegExp(telFormat).test(telToCheck) ? true : false;
  };

  render() {
    const {
      t,
      personalInfoField,
      form: { agreeToS, loading },
      languages,
      country,
      isGettingFields
    } = this.props;
    const personalInfoData = personalInfoField.filter(
      item => OTHER_FORM_FIELDS.indexOf(item.driverRegistrationInfoKey) === -1
    );
    const personalFields = this.renderInputField(personalInfoData);
    const otherDetails = personalInfoField.filter(
      item => OTHER_FORM_FIELDS.indexOf(item.driverRegistrationInfoKey) !== -1
    );
    const otherFields = this.renderInputField(otherDetails);
    return (
      <React.Fragment>
        <FlexGrowDiv>
          <div className="languageBlock paddingWrapper">
            <img alt="" src={driverIcon} />
            <LanguageSelector
              name="language"
              languages={languages}
              selectedLanguage={normalize(languages)[country.lang]}
              onChange={this.handleLanguageSelection}
            />
          </div>
          <form>
            <div className="paddingWrapper">
              <span className="infoHints">
                {t('BasicInfo.personalInfoTitle')}
              </span>
              {personalFields}
            </div>
            <div className="paddingWrapper">
              <span className="infoHints">{t('BasicInfo.otherInfoTitle')}</span>
              {otherFields}
            </div>
            <div className="paddingWrapper">
              <Checkbox
                name="agreeToS"
                checked={agreeToS}
                onChange={this.handleToSAgreement}
                disabled={loading || isGettingFields}
                label={
                  <span className="infoHints">
                    <Trans i18nKey="Home.labelToS">
                      By signing up, I agree to
                      <ModalLink
                        closeText={t('Home.modal.closeBtn')}
                        title={t('Home.modal.toSTitle')}
                        href={t('Home.termsUrl')}
                        onClick={this.handleLinkClick}
                      >
                        Lalamove's Terms and Conditions
                      </ModalLink>
                      and
                      <ModalLink
                        closeText={t('Home.modal.closeBtn')}
                        title={t('Home.modal.privacyTitle')}
                        href={t('Home.privacyUrl')}
                        onClick={this.handleLinkClick}
                      >
                        Privacy Policy
                      </ModalLink>
                    </Trans>
                  </span>
                }
              />
            </div>
          </form>
        </FlexGrowDiv>
        <Button
          id="submitInfoBtn"
          variant="primary"
          solid
          disabled={this.shouldNextEnable()}
          onClick={this.handleOnSubmit}
          size="xlarge"
          block
        >
          {t('BasicInfo.nextButton')}
        </Button>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(PersonalInfo);
