import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';
import FaAngleDown from 'react-icons/lib/fa/angle-down';

const Item = styled.div`
  border-left: 2px solid #fff;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#f2f2f2' : '#FFF')};
  border-left-color: ${({ isActive }) => (isActive ? '#f16622' : '#FFF')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  &:hover,
  &:focus {
    background-color: #f2f2f2;
    border-left-color: #f16622;
  }
`;

const Dropdown = ({ countryList, selectedItem, onChange }) => (
  <Downshift
    selectedItem={selectedItem.id}
    onChange={onChange}
  >
    {({
      isOpen,
      getToggleButtonProps,
      getItemProps,
      highlightedIndex,
      selectedItem: dsSelectedItem,
    }) => (
        <div>
          <button
            {...getToggleButtonProps()}
            className="PhoneInput__select-button"
          >
            {selectedItem && (
              <span>
                <img
                  className="PhoneInput__select-flag"
                  src={selectedItem.flag}
                  alt={selectedItem.id}
                />
                <span className="PhoneInput__select-code">{`+${
                  selectedItem.areaCode ? selectedItem.areaCode : ''
                  }`}</span>
                <FaAngleDown fill="#b4b4b4" height="24px" width="24px" />
              </span>
            )}
          </button>
          <div className="PhoneInput__dropdown">
            {isOpen && (
              <div className="PhoneInput__select-item-list">
                {countryList.map((item, index) => (
                  <Item
                    {...getItemProps({
                      item,
                      isActive: highlightedIndex === index,
                      isSelected: dsSelectedItem.id === item.id,
                    })}
                    key={item.id}
                  >
                    <img
                      className="PhoneInput__item-flag"
                      src={item.flag}
                      alt={item.id}
                    />
                    {/* temp: */}
                    {item.translations.length ? item.translations[0].value : item.defaultLanguage}
                  </Item>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
  </Downshift>
);

Dropdown.propTypes = {
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItem: PropTypes.shape({
    areaCode: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  selectedItem: {},
};

export default Dropdown;
