import styled from 'styled-components';
import { Checkbox } from '@lalamove/karang';

export const CheckBox = styled(Checkbox)`
  > span:first-of-type {
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FormItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 1em;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  align-items: center;

  @media (max-width: 580px) {
    align-items: baseline;
  }
`;

export const FormWrapper = styled.div`
  margin: 33px 1.8em 0 1.8em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const Notice = styled.span`
  color: #B4B4B4;
  font-size: 0.94em; 
`;

export const ToSLable = styled.span`
  text-align: left;
  display: block;
`;
