import {
  GET_BASIC_INFO,
  GET_BASIC_INFO_SUCCESS,
  GET_BASIC_INFO_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_REQUEST_FAILURE,
} from 'store/actions/signup';
import {
  CONFIG_DRIVER_DEVICE,
  UTM_SAVE_SUCCESS,
} from 'store/actions/config';
import {
  GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING
} from 'store/actions/trainingSession';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import {
  getCurrentCountry,
  getTelWithoutCountryCode,
  getIsPhonePrecedingZero,
  getQueryParams,
  getUTMParams
} from 'store/selectors';
import { replace } from 'react-router-redux';
import Api from 'utils/api';
import {
  ERROR_TYPE_LENGTH_TOO_LONG,
  ERROR_TYPE_REQUIRED,
  isNewSignUpVersion
} from 'utils/helpers';
import { onSignedUp } from 'utils/callback';
import { GET_ADDITIONAL_INFO_POST } from 'store/actions/additionalInfo';
import { ADDITIONAL_INFO_SIGNUP_POPUP } from 'containers/AdditionalInfo/constants'
import { TRIGGER_TRACKER } from '../actions/signup';
import { EventTypes } from 'redux-segment';
import { trackingRegistrationPageFirstViewed } from 'store/actions/tracking';

export function* handleGetBasicInfo(actions) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
    queryParams = queryParams || '';
    const response = yield call(Api.getSignupInfo);
    const { statusCode, body } = response;

    if (statusCode === 200) {
      yield put({
        type: GET_BASIC_INFO_SUCCESS,
        payload: {
          fields: body.data,
        }
      });
      if (!isNewSignUpVersion()) {
        if (!actions.hasOwnProperty('payload')
          || !actions.payload.hasOwnProperty('skipSignUpForm')
          || !actions.payload.skipSignUpForm) {
          yield put(replace(`/register/basic-info${queryParams}`));
        }
        else {
          throw Error('server error');
        }
      }
    } else if (statusCode === 400) {
      throw Error(response.error)
    }
  } catch (e) {
    yield put({
      type: GET_BASIC_INFO_FAILURE,
    });
    yield put(replace(`/register/error${queryParams}`));
  }
};

export function* handleSignup(actions) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
    queryParams = queryParams || '';
    const { payload: { submittedFields } } = actions;
    const postData = Object.keys(submittedFields).reduce((arr, key) => {
      return key === 'FORM_PASSWORD'
        ? arr
        : [...arr, { driverRegistrationInfoKey: key, value: submittedFields[key] }];
    }, []);
    let hasError = false;

    const response = yield postData.map(d => call(Api.postSignupInfo, d));

    for (let index in response) {
      const { statusCode, body } = response[index];
      if (statusCode !== 201) {
        hasError = true;
        const { error: { message } } = body;
        switch (statusCode) {
          case 422:
            // The value may not be greater than 5 characters.
            const findNumberRegex = message.match(/\d+/g)
            if (findNumberRegex === null) {
              break;
            }
            yield put({
              type: SIGNUP_REQUEST_FAILURE,
              payload: {
                error: {
                  driverRegistrationInfoKey: postData[index].driverRegistrationInfoKey,
                  type: ERROR_TYPE_LENGTH_TOO_LONG,
                  length: findNumberRegex.map(Number)[0]
                }
              }
            });
            break;
          case 400:
            yield put({
              type: SIGNUP_REQUEST_FAILURE,
              payload: {
                error: {
                  driverRegistrationInfoKey: postData[index].driverRegistrationInfoKey,
                  type: ERROR_TYPE_REQUIRED
                }
              }
            });
            break;
          default:
            throw Error('server error');
        }
      }
    }

    if (!hasError) {
      if (isNewSignUpVersion()) {
        const utmObject = yield select(getUTMParams);
        if (Object.keys(utmObject).length) {
          const { statusCode } = yield call(Api.postUTMParams, { utmObject });
          if (statusCode === 201) {
            yield put({ type: UTM_SAVE_SUCCESS })
          }
        }
      }
      const { statusCode } = yield Api.postInitPassword({
        password: submittedFields.FORM_PASSWORD,
      });

      switch (statusCode) {
        case 200:
          const currentCountry = yield select(getCurrentCountry);
          const tel = yield select(getTelWithoutCountryCode);
          const isPhonePrecedingZero = yield select(getIsPhonePrecedingZero);
          if (onSignedUp(
            isPhonePrecedingZero ? '0' + tel : tel,
            submittedFields.FORM_PASSWORD,
            currentCountry.country,
            currentCountry.city,
            currentCountry.lang
          )) {
            yield put({
              type: CONFIG_DRIVER_DEVICE,
              payload: {
                fromDriverApp: true,
              }
            });
          }
          if (isNewSignUpVersion()) {
            yield put({
              type: GET_ADDITIONAL_INFO_POST,
              payload: {
                flow: ADDITIONAL_INFO_SIGNUP_POPUP,
              }
            });
          } else {
            yield put({
              type: GET_TRAINING_SESSION_VENUES_REQUEST_NO_LOADING,
              payload: {
                locale: currentCountry.lang,
              }
            });
          }
          break;
        default:
          throw Error('server error');
      }
    }

  } catch (e) {
    yield put({
      type: SIGNUP_REQUEST_FAILURE,
    });
    yield put(replace(`/register/error${queryParams}`));
  }
}

export function* handleTracker() {
  const utmObj = yield select(state => state.config.utm)

  Object.keys(utmObj).forEach(function (key) {
    if (!utmObj[key]) {
      delete utmObj[key];
    }
  });

  let propsObj = {
    llm_source: yield select(state => state.config.country.device),
    country: yield select(state => state.config.country.country),
  }

  if (Object.keys(utmObj).length) {
    propsObj = { ...propsObj, ...utmObj }
  }
  yield put(trackingRegistrationPageFirstViewed({
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Registration First Page Viewed',
        properties: propsObj
      }
    }
  })());
}

export default function* signupSaga() {
  yield takeLatest(GET_BASIC_INFO, handleGetBasicInfo);
  yield takeLatest(SIGNUP_REQUEST, handleSignup);
  yield takeLatest(TRIGGER_TRACKER, handleTracker)
}
