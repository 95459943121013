import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dropdown from './components/Dropdown';
import InputError from '../InputError';

import './style.css';

function normailize(array) {
  return array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
}

class PhoneInput extends PureComponent {
  static propTypes = {
    classNameContainer: PropTypes.string,
    classNameInput: PropTypes.string,
    classNamePlaceholder: PropTypes.string,
    className: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    country: PropTypes.string,
    countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onCountryChange: PropTypes.func,
    isPhoneNumber: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    classNameContainer: 'PhoneInputContainer',
    classNameInput: 'AnimatedInput',
    classNamePlaceholder: '',
    className: 'AnimatedInput__input',
    autoComplete: null,
    placeholder: '',
    name: '',
    value: '',
    error: '',
    onFocus: null,
    onKeyPress: null,
    onChange: null,
    onBlur: null,

    country: null,
    onCountryChange: () => {},
    isPhoneNumber: false,
  };

  state = {
    isInputFocused: false,
    value: this.props.value,
    isPhoneNumber: this.props.isPhoneNumber,
    countryMapper: {},
  };

  static getDerivedStateFromProps(props, state) {
    const { countryList } = props;

    return { ...state, countryMapper: normailize(countryList) };
  }

  handleFocus = e => {
    this.setState({ isInputFocused: true });
    if (this.props.onFocus) this.props.onFocus(e);
  };

  handleKeyPress = e => {
    if (this.props.isPhoneNumber) {
      const keyCode = e.keyCode || e.which;
      const keyValue = String.fromCharCode(keyCode);
      if (/\D/.test(keyValue)) {
        e.preventDefault();
      }
    }
  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
      isPhoneNumber:
        this.props.isPhoneNumber || /^\d{2,}$/.test(e.target.value),
    });
    if (this.props.onChange) this.props.onChange(e);
  };

  handleBlur = e => {
    this.setState({ isInputFocused: false });
    if (this.props.onBlur) this.props.onBlur(e);
  };

  handleSelect = item => {
    this.setState({
      selectedItem: item,
    });
    if (this.props.onCountryChange) this.props.onCountryChange(item);
  };

  render() {
    const { isPhoneNumber } = this.state;

    const { country, error, countryList } = this.props;

    const { countryMapper } = this.state;

    const focusClass = this.state.isInputFocused
      ? 'AnimatedInput--focused'
      : '';
    const openClass =
      this.state.isInputFocused || this.state.value
        ? 'AnimatedInput--opened'
        : '';
    const errorClass = error ? 'AnimatedInput--error' : '';
    const isPhoneNumberClass =
      this.state.isInputFocused || isPhoneNumber
        ? 'PhoneInput__phone-number-placeholder'
        : '';
    const placeholderText =
      countryMapper[country] && isPhoneNumber
        ? `${this.props.placeholder} (e.g. ${
            countryMapper[country].samplePhone
          })`
        : this.props.placeholder;

    return (
      <div className={this.props.classNameContainer}>
        <div
          className={`AnimatedInput ${focusClass} ${openClass} ${errorClass} ${
            this.props.classNameInput
          }`}
        >
          <div
            className={`AnimatedInput__placeholder ${isPhoneNumberClass} ${
              this.props.classNamePlaceholder
            }`}
          >
            {placeholderText}
          </div>

          {isPhoneNumber && (
            <Dropdown
              countryList={countryList}
              selectedItem={countryMapper[country]}
              onChange={this.handleSelect}
            />
          )}
          <input
            className="AnimatedInput__input PhoneInput__input"
            type="tel"
            placeholder={this.state.isInputFocused ? placeholderText : ''}
            name={this.props.name}
            value={this.state.value || ''}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
          />
        </div>

        <InputError error={error} />
      </div>
    );
  }
}

export default PhoneInput;
