import styled from 'styled-components';

export const VenuePickerContainer = styled.div`
  padding-top: 16px;
`;

export const BottomContainer = styled.div`
  align-self: flex-end;
  max-width: 600px;
  width: 100%;
`;

export const DecideLaterBlock = styled.div`
  margin: 16px 0;
`;

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChosenDate = styled.span`
  display: block;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 700;
`;
