import { createAction } from 'redux-actions';

export const GET_BASIC_INFO = 'GET_BASIC_INFO';
export const getBasicInfo = createAction(GET_BASIC_INFO);

export const GET_BASIC_INFO_SUCCESS = 'GET_BASIC_INFO_SUCCESS';
export const getBasicInfoSuccess = createAction(GET_BASIC_INFO_SUCCESS);

export const GET_BASIC_INFO_FAILURE = 'GET_BASIC_INFO_FAILURE';
export const getBasicInfoFailure = createAction(GET_BASIC_INFO_FAILURE);

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const signupRequest = createAction(SIGNUP_REQUEST);

export const SET_DRIVER_FIELDS = 'SET_DRIVER_FIELDS';
export const setDriverField = createAction(SET_DRIVER_FIELDS);

export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
export const signupRequestSuccess = createAction(SIGNUP_REQUEST_SUCCESS);

export const SIGNUP_REQUEST_FAILURE = 'SIGNUP_REQUEST_FAILURE';
export const signupRequestFailure = createAction(SIGNUP_REQUEST_FAILURE);

export const FIELD_CHANGES = 'FIELD_CHANGES';
export const fieldChanges = createAction(FIELD_CHANGES);

export const SIGNUP_SAVE_FORM = 'SIGNUP_SAVE_FORM';
export const signupSaveForm = createAction(SIGNUP_SAVE_FORM);

export const TRIGGER_TRACKER = 'TRIGGER_TRACKER';
export const triggerTracker = createAction(TRIGGER_TRACKER);

