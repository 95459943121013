import { func, string, shape, int, bool, arrayOf, oneOfType, number } from 'prop-types';

import { changeCity, formFinish, changeCountry } from 'store/actions/config';
import { fieldChanges, getBasicInfo, signupSaveForm, triggerTracker } from 'store/actions/signup';
import { telFormFieldChanged, telChange, telSubmit } from 'store/actions/auth';
import {
  getRequiredBasicInfoFields,
  convertCountriesConfigToCountryList,
  extractLanguagesFromCountriesConfig,
} from 'utils/helpers';

const FORM_CITY = 'FORM_CITY';
const FORM_PASSWORD = 'FORM_PASSWORD'
const FORM_EMAIL = 'FORM_EMAIL'
const FORM_TEL = 'FORM_TEL'

const defaultProps = {
  requiredFields: [],
  errorFromApi: [],
  device: null,
  signupRequest: null,
};

const propsValidation = {
  t: func.isRequired,
  personalInfoField: arrayOf(
    shape({
      driverRegistrationInfoKey: string,
      sortingPriority: int,
      name: string,
      type: string,
      length: int,
      required: oneOfType([number, bool]),
      lookup: arrayOf(
        shape({
          id: string,
          value: string,
        }),
      ),
    }),
  ).isRequired,
  requiredFields: arrayOf(string),
  changeCity: func.isRequired,
  signupRequest: func,
  errorFromApi: arrayOf(
    shape({
      driverRegistrationInfoKey: string,
      type: string,
      length: int,
    }),
  ),
  fieldChanges: func.isRequired,
  isGettingFields: bool.isRequired,
  getBasicInfo: func.isRequired,
  city: string.isRequired,
  device: string,
  form: shape({
    agreeToS: bool
  }),
  languages: arrayOf(shape({
    id: string,
    value: string,
  })).isRequired,
};

const normalize = (array) => {
  return array.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
}

const handleKeyPress = e => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\D/.test(keyValue)) {
    e.preventDefault();
  }
}

const handleCopyPaste = (e) => {
  e.preventDefault();
}

const mapDispatchToProps = {
  changeCity,
  changeCountry,
  signupSaveForm,
  fieldChanges,
  getBasicInfo,
  telFormFieldChanged,
  telChange,
  telSubmit,
  formFinish,
  triggerTracker,
}

const mapStateToProps = state => ({
  personalInfoField: state.auth.signup.fields,
  requiredFields: getRequiredBasicInfoFields(state.auth.signup.fields),
  errorFromApi: state.auth.signup.error,
  isGettingFields: state.auth.signup.isGettingFields,
  city: state.config.country.city,
  device: state.config.country.device,
  form: state.auth.telVerify,
  countries: convertCountriesConfigToCountryList(state.config.countriesConfig),
  country: state.config.country,
  submittedFields: state.auth.signup.submittedFields,
  countriesConfig: state.config.countriesConfig,
  languages: extractLanguagesFromCountriesConfig(
    state.config.countriesConfig,
    state.config.country.country,
  ),
});

const stateObj = {
  value: {},
  error: {},
  filled: {},
}

export {
  FORM_CITY,
  defaultProps,
  propsValidation,
  normalize,
  handleKeyPress,
  mapDispatchToProps,
  handleCopyPaste,
  FORM_PASSWORD,
  FORM_EMAIL,
  FORM_TEL,
  mapStateToProps,
  stateObj
}