import log from './log';
import { isNewSignUpVersion } from 'utils/helpers';

const HEADER_LOCATION = 'X-LLM-LOCATION';
const HEADER_LOCALE = 'Accept-Language';
const HEADER_AUTHORIZATION = 'Authorization';
const HEADER_APPTYPE = 'X-LLM-APPTYPE';

const {
  REACT_APP_MOBILE_API,
  REACT_APP_MOBILE_API_RAW,
  REACT_APP_MOBILE_API_PROTOCOL,
  REACT_APP_HOST_ENV,
} = process.env;

const defaultConfig = {
  location: 'HK_HKG',
  locale: 'en_HK',
  token: null,
};

class API {
  constructor(config = defaultConfig) {
    this._config = config;
    this._domain = REACT_APP_MOBILE_API;
  }

  changeDomain = (domain) => {
    this._domain = `${REACT_APP_MOBILE_API_PROTOCOL}${domain}/drivers`
  }

  changeLocation = (location = 'HK_HKG') => {
    this._config.location = location;
  };

  changeLocale = (locale = 'en_HK') => {
    this._config.locale = locale;
  }

  changeToken = (token = null) => {
    this._config.token = token;
  }

  // only work fine with function that accept queryString as the first params
  makeRequestWithPagination = async (httpCall, allArguments) => {
    let pageNumber;
    let data = [];

    let args = allArguments;
    if (
      typeof args !== 'object'
      || args.length === 0
    ) {
      args = [{}];
    }

    while (true) {
      if (pageNumber) {
        args[0]['page[number]'] = pageNumber;
      }
      const { statusCode, body } = await httpCall.apply(this, args);

      if (statusCode === 200) {
        const { links } = body;
        if (!('next' in links)) break;
        const page = links.next.match(/page%5Bnumber%5D=(\d)/);

        if (page.length === 2) {
          pageNumber = page[1];
        }
        data = [...data, ...body.data];
      } else {
        return null;
      }
    }

    return data;
  }

  makeRequest = async ({
    endpoint = '',
    extraHeaders = {},
    body = {},
    method = 'GET',
    queryString = {},
  }) => {
    let url = `${this._domain}/${endpoint}`;

    const queryStringStr = Object.keys(queryString)
      .map(q => `${q}=${queryString[q]}`)
      .join('&');

    if (queryStringStr !== '') {
      url = `${url}?${queryStringStr}`;
    }

    let config = {
      headers: {
        [HEADER_LOCATION]: this._config.location,
        [HEADER_LOCALE]: this._config.locale,
        [HEADER_APPTYPE]: 'DRIVER',
        ...extraHeaders,
      },
      method,
    };
    if (REACT_APP_HOST_ENV !== "ldev") {
      log.changeLocation(this._config.location);
      log.changeLocale(this._config.locale);
    }

    if (this._config.token !== null) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          [HEADER_AUTHORIZATION]: this._config.token,
        },
      };
    }

    config = method !== 'GET' ? {
      ...config,
      body: JSON.stringify(body),
      headers: {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    } : config;

    try {
      const response = await fetch(url, config);
      const resBody = await response.json();
      if (!response.ok || resBody.success === false) {
        log.error('API Response error', {
          category: 'backend:makeRequest',
          endpoint: url,
          response: `JSON: ${JSON.stringify(resBody)}`, // prevent log service parsing JSON string as object
          status_code: response.status,
          ...(method === 'GET' && {
            query_string: queryStringStr.replace(
              /access_token=([^&]*)/g,
              'access_token=****'
            ),
          }),
          ...(resBody.footprint && { footprint: resBody.footprint }),
          ...(config && { config: config }),
        });
      }
      return {
        statusCode: response.status,
        body: resBody,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // Configs for all countries
  getInitRegistrationData = async () => {
    const response = await this.makeRequest({
      endpoint: 'signup-phone-info',
    });
    return response;
  };

  // Check if phone exists in system
  postSignupPhoneVerification = async ({ tel = null, method = 'SMS' }) => {
    const response = await this.makeRequest({
      endpoint: 'signup-phone-verification',
      body: {
        tel,
        method,
      },
      method: 'POST',
    })
    return response;
  };

  postSignupPhoneVerify = async (body) => {
    const response = await this.makeRequest({
      endpoint: 'signup-phone-verify',
      body,
      method: 'POST',
    });
    return response;
  };

  getSignupInfo = async () => {
    const response = await this.makeRequest({
      endpoint: isNewSignUpVersion() ? 'get-signup-form-info' : 'signup-info',
      method: 'GET',
    });
    return response;
  }

  postSignupInfo = async ({ driverRegistrationInfoKey, value }) => {
    const response = await this.makeRequest({
      endpoint: 'signup-info',
      body: {
        driverRegistrationInfoKey,
        value,
      },
      method: 'POST',
    });
    return response;
  }

  postInitPassword = async (data) => {
    const response = await this.makeRequest({
      endpoint: 'init-password',
      body: {
        ...data,
      },
      method: 'POST',
    });
    return response;
  }

  getTrainingSessionVenues = async (queryString) => {
    const response = await this.makeRequest({
      endpoint: 'cities-training-venues',
      queryString,
    });
    return response;
  }

  getCampaignDetails = async (params) => {
    const { id } = params;
    const response = await this.makeRequest({
      endpoint: `campaign/${id}`,
    });
    return response;
  }

  getTrainingSessionsDays = async (queryString) => {
    const response = await this.makeRequest({
      endpoint: 'training-sessions-days',
      queryString,
    });
    return response;
  }

  getTrainingSessions = async (queryString) => {
    const response = await this.makeRequest({
      endpoint: 'training-sessions',
      queryString,
    });
    return response;
  }

  postTrainingSessionsBookings = async (body) => {
    const response = await this.makeRequest({
      endpoint: 'training-sessions-bookings',
      body,
      method: 'POST',
    });
    return response;
  }

  fetchLanguageJsonFromUrl = (url) => {
    return fetch(url).then(response => response.json())
  }

  getLocations = async ({
    extraHeaders = {},
    body = {},
    method = 'GET',
  }) => {
    let url = `${REACT_APP_MOBILE_API_RAW}/api/v5/locations`;

    let config = {
      headers: {
        [HEADER_LOCATION]: this._config.location,
        [HEADER_LOCALE]: this._config.locale,
        [HEADER_APPTYPE]: 'DRIVER',
        ...extraHeaders,
      },
      method,
    };
    if (REACT_APP_HOST_ENV !== "ldev") {
      log.changeLocation(this._config.location);
      log.changeLocale(this._config.locale);
    }

    config = method !== 'GET' ? {
      ...config,
      body: JSON.stringify(body),
      headers: {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    } : config;

    try {
      const response = await fetch(url, config);
      const resBody = await response.json();
      if (!response.ok || resBody.success === false) {
        log.error('API Response error', {
          category: 'backend:getLocations',
          endpoint: url,
          response: `JSON: ${JSON.stringify(resBody)}`, // prevent log service parsing JSON string as object
          status_code: response.status,
          ...(resBody.footprint && { footprint: resBody.footprint }),
          ...(config && { config: config }),
        });
      }
      return {
        locationStatusCode: response.status,
        locationBody: resBody,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }


  getDriverAuthInfo = async (payload) => {
    let queryParams = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    let url = `${REACT_APP_MOBILE_API_RAW}/api/v5/vanaccount?${queryParams}`;
    let method = "GET";
    let config = {
      headers: {
        [HEADER_LOCATION]: this._config.location,
        [HEADER_LOCALE]: this._config.locale,
        [HEADER_APPTYPE]: 'DRIVER',
      },
      method,
    };
    if (REACT_APP_HOST_ENV !== "ldev") {
      log.changeLocation(this._config.location);
      log.changeLocale(this._config.locale);
    }
    try {
      const response = await fetch(url, config);
      const resBody = await response.json();
      if (!response.ok || resBody.success === false) {
        log.error('API Response error', {
          category: 'backend:getDriverAuthInfo',
          endpoint: url,
          response: `JSON: ${JSON.stringify(resBody)}`, // prevent log service parsing JSON string as object
          status_code: response.status,
          ...(method === 'GET' && {
            query_string: queryParams.replace(
              /access_token=([^&]*)/g,
              'access_token=****'
            ),
          }),
          ...(resBody.footprint && { footprint: resBody.footprint }),
          ...(config && { config: config }),
        });
      }
      return {
        responseStatusCode: response.status,
        responseBody: resBody,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getAdditionalInfo = async () => {
    const response = await this.makeRequest({
      endpoint: 'get-additional-form-info',
      method: 'GET',
    });
    return response;
  }

  getAdditionalInfoPost = async () => {
    const response = await this.makeRequest({
      endpoint: 'additional-info-save',
      method: 'POST',
      body: {},
    });
    return response;
  }

  postAdditionalInfo = async ({ driverRegistrationInfoKey, value }) => {
    const response = await this.makeRequest({
      endpoint: 'signup-additional-info',
      body: {
        driverRegistrationInfoKey,
        value,
      },
      method: 'POST',
    });
    return response;
  }

  postUTMParams = async ({ utmObject }) => {
    const response = await this.makeRequest({
      endpoint: 'save-utm-information',
      body: {
        ...utmObject
      },
      method: 'POST',
    });
    return response;
  }

  getAdditionalInfoFlag = async () => {
    const response = await this.makeRequest({
      endpoint: 'display-additional-fields',
      method: 'GET',
    });
    return response;
  }

  getIframeLalamoveUrls = async () => {
    const response = await this.makeRequest({
      endpoint: 'registration-urls',
      method: 'GET',
    });
    return response;
  }
}

export default new API();
