import {
  GET_BASIC_INFO,
  GET_BASIC_INFO_SUCCESS,
  SIGNUP_REQUEST_FAILURE,
  FIELD_CHANGES,
  SIGNUP_REQUEST,
  SET_DRIVER_FIELDS,
  SIGNUP_SAVE_FORM,
} from 'store/actions/signup';
import { CHANGED_SUBMITTED_TEL } from 'store/actions/auth';
import { isNewSignUpVersion } from 'utils/helpers';

const initialState = {
  fields: [],
  error: [],
  isSubmitting: false,
  isGettingFields: false,
  submittedFields: {},
};


export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        ...payload,
        isSubmitting: true,
      };
    case SIGNUP_SAVE_FORM:
      return {
        ...state,
        ...payload,
        isSubmitting: false,
      }
    case SET_DRIVER_FIELDS:
      return {
        ...state,
        ...payload,
      };
    case GET_BASIC_INFO:
      return {
        ...state,
        isGettingFields: true,
      };
    case GET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        ...payload,
        isGettingFields: false,
      }
    case SIGNUP_REQUEST_FAILURE:
      if (typeof payload === 'undefined') {
        return state;
      }
      return {
        ...state,
        error: state.error.concat(payload.error),
        isSubmitting: false,
      };
    case FIELD_CHANGES:
      return {
        ...state,
        error: state.error
          .filter(err => err.driverRegistrationInfoKey !== payload.driverRegistrationInfoKey),
      };
    case CHANGED_SUBMITTED_TEL:
      if (!isNewSignUpVersion()) {
        return {
          ...state,
          ...initialState,
        };
      }
      return state
    default:
      return state;
  }
};
