import React from 'react';
import {
  func,
  string,
  bool,
  arrayOf,
  shape,
  int,
  oneOfType,
  number
} from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { Button, Alert } from '@lalamove/karang';

import {
  getAdditionalInfo,
  setAdditionalInfo,
  clearAdditionalInfoError
} from 'store/actions/additionalInfo';
import {
  handleFieldErrorOnBasicInfo,
  ERROR_TYPE_LENGTH_TOO_LONG,
  ERROR_TYPE_REQUIRED,
  DEVICE_TYPE,
  getRequiredBasicInfoFields,
  cancelBack,
  isIframeView
} from 'utils/helpers';
import FormFields from './components/FormFields';
import {
  OTHERS_OPTION,
  ADDITIONAL_INFO_SIGNUP_POPUP,
  ADDITIONAL_INFO_VIA_DRIVER_APP
} from './constants';

import { FlexGrowDiv } from 'style';
import './style.css';
export class AdditionalInfo extends React.Component {
  state = {
    selectedFields: {},
    error: {},
    filled: {},
    otherFields: {},
    showSignUp: true
  };

  componentDidMount() {
    const { postAdditionalInfoCall, getAdditionalInfo, flow } = this.props;
    if (postAdditionalInfoCall && flow === ADDITIONAL_INFO_VIA_DRIVER_APP) {
      getAdditionalInfo();
    }
    cancelBack();
    document.title = this.props.t('AdditionalInfo.title');
  }

  static getDerivedStateFromProps(props, state) {
    const { apiError } = props;
    if (apiError && apiError.length > 0) {
      let errorRes = {};
      let currentError = {};
      for (let i = 0; i < apiError.length; i++) {
        switch (apiError[i].type) {
          case ERROR_TYPE_REQUIRED:
            currentError = handleFieldErrorOnBasicInfo(
              props.t,
              apiError[i].driverRegistrationInfoKey,
              ERROR_TYPE_REQUIRED
            );
            break;
          case ERROR_TYPE_LENGTH_TOO_LONG:
            const reqLength = props.fields.find(
              item =>
                item.driverRegistrationInfoKey ===
                apiError[i].driverRegistrationInfoKey
            ).length;
            currentError = handleFieldErrorOnBasicInfo(
              props.t,
              apiError[i].driverRegistrationInfoKey,
              ERROR_TYPE_LENGTH_TOO_LONG,
              reqLength
            );
            break;
          default:
            currentError = handleFieldErrorOnBasicInfo(
              props.t,
              apiError[i].driverRegistrationInfoKey,
              ERROR_TYPE_REQUIRED
            );
        }
        errorRes = {
          ...errorRes,
          ...currentError
        };
      }
      return {
        ...state,
        error: {
          ...state.error,
          ...errorRes
        }
      };
    }
    return null;
  }
  handleInputChange = e => {
    const { name, value } = e.target;
    const { clearAdditionalInfoError } = this.props;
    this.setState(prevState => ({
      ...prevState,
      selectedFields: {
        ...prevState.selectedFields,
        [name]: value
      },
      error: {
        ...prevState.error,
        [name]: null
      },
      filled: {
        ...prevState.filled,
        [name]: false
      }
    }));
    clearAdditionalInfoError({ driverRegistrationInfoKey: name });
  };

  handleOnBlur = e => {
    const required =
      e.target.getAttribute('data-required') || e.target.required;
    const name = e.target.getAttribute('data-name') || e.target.name;
    //value if  not in selectedfields than otherFields
    const value =
      this.state.selectedFields[name] || this.state.otherFields[name];
    let error = null;
    if (required && (!value || value.length <= 0)) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_REQUIRED
      );
    } else if (
      e.target.maxLength !== -1 &&
      value &&
      value.toString().length > e.target.maxLength
    ) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_LENGTH_TOO_LONG,
        e.target.maxLength
      );
    }
    if (error) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          ...error
        }
      }));
      return;
    }
    this.setState(prevState => ({
      filled: {
        ...prevState.filled,
        [name]: true
      }
    }));
  };

  handleSelection = (selectedItem, dsState) => {
    const { clearAdditionalInfoError } = this.props;
    const { otherFields } = this.state;
    let updatedOthers = {};
    const othersKey = `${dsState.id}-${OTHERS_OPTION}`;
    if (Object.keys(otherFields).length > 0) {
      Object.keys(otherFields).forEach(key => {
        if (key !== othersKey) {
          updatedOthers[key] = otherFields[key];
        }
      });
    }
    this.setState(prevState => ({
      selectedFields: {
        ...prevState.selectedFields,
        [dsState.id]: selectedItem.id
      },
      error: {
        ...prevState.error,
        [dsState.id]: null
      },
      filled: {
        ...prevState.filled,
        [dsState.id]: true
      },
      otherFields: updatedOthers
    }));
    clearAdditionalInfoError({ driverRegistrationInfoKey: dsState.id });
  };

  handleOtherField = (key, value) => {
    this.setState(prevState => ({
      otherFields: {
        ...prevState.otherFields,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: null
      }
    }));
  };

  handleOnSubmit = () => {
    const { selectedFields, otherFields } = this.state;
    var submitValues = {};
    for (let key in selectedFields) {
      if (
        selectedFields[key] &&
        String(selectedFields[key]).toLowerCase() !== OTHERS_OPTION
      ) {
        submitValues[key] = selectedFields[key];
      }
    }
    if (Object.keys(otherFields).length > 0) {
      for (let key in otherFields) {
        if (otherFields[key]) {
          let submittedFieldkey = key.split('-')[0];
          submitValues[submittedFieldkey] = otherFields[key];
        }
      }
    }
    this.props.setAdditionalInfo({ submittedFields: submitValues });
  };

  shouldNextEnable = () => {
    if (this.props.fields && this.props.fields.length) {
      const requiredFields = getRequiredBasicInfoFields(this.props.fields);
      // no error on all fields
      const checkError = this.props.fields.reduce((arr, field) => {
        const key = field.driverRegistrationInfoKey;
        if (!(key in this.state.error) || this.state.error[key] === null) {
          return [...arr, key];
        }
        return arr;
      }, []);

      // all required fields are filled
      const checkFieldFilled = requiredFields.reduce((arr, key) => {
        if (key in this.state.filled && this.state.filled[key] === true) {
          return [...arr, key];
        }
        return arr;
      }, []);

      return (
        checkError.length !== this.props.fields.length ||
        checkFieldFilled.length !== requiredFields.length ||
        this.checkOtherFieldsError() ||
        this.props.loadingPost
      );
    }
    return true;
  };

  showPopUp = toMatchKey => {
    return this.props.flow === toMatchKey && this.state.showSignUp
      ? true
      : false;
  };

  checkOtherFieldsError = () => {
    const { selectedFields, otherFields } = this.state;
    let otherFieldsError = [];
    //TODO, extract only fields with lookup length  >0
    const keysWithOther = Object.keys(selectedFields).filter(
      item => selectedFields[item] === OTHERS_OPTION
    );
    if (keysWithOther.length > 0) {
      //if otherfields has values and there are dropdowns with others selected
      for (let key of keysWithOther) {
        if (!otherFields[`${key}-${OTHERS_OPTION}`]) {
          otherFieldsError.push(`${key} - ${OTHERS_OPTION}`);
        }
      }
    }
    return !!otherFieldsError.length;
  };

  setBtnText = () => {
    const { t } = this.props;
    if (isIframeView()) {
      return t('AdditionalInfo.finishButton');
    }
    return t('BasicInfo.nextButton');
  };

  render() {
    const { fields, loading, loadingPost, t, device } = this.props;
    const { selectedFields, otherFields, error } = this.state;
    return (
      !loading && (
        <div className="mainContainer">
          <FlexGrowDiv>
            {this.showPopUp(ADDITIONAL_INFO_SIGNUP_POPUP) && (
              <Alert
                message={t('AdditionalInfo.signupHeading')}
                description={t('AdditionalInfo.signupBody')}
                type="success"
                variant="toast"
                onDismiss={() => this.setState({ showSignUp: false })}
                className="alert"
              />
            )}
            {this.showPopUp(ADDITIONAL_INFO_VIA_DRIVER_APP) && (
              <Alert
                message={t('AdditionalInfo.signupDirectHeading')}
                description={t('AdditionalInfo.signupDirectBody')}
                type="info"
                variant="toast"
                onDismiss={() => this.setState({ showSignUp: false })}
                className="alert"
              />
            )}
            {device === DEVICE_TYPE.WEB && (
              <div className="heading">{t('AdditionalInfo.title')}</div>
            )}
            <div className="paddingWrapper">
              <FormFields
                fields={fields}
                loading={loadingPost}
                errorFields={error}
                selectedFields={selectedFields}
                otherFields={otherFields}
                handleInputChange={this.handleInputChange}
                handleOnBlur={this.handleOnBlur}
                handleSelection={this.handleSelection}
                handleOtherField={this.handleOtherField}
              />
            </div>
          </FlexGrowDiv>
          <Button
            className="submitBtn"
            id="submitInfoBtn"
            variant="primary"
            solid
            disabled={this.shouldNextEnable()}
            onClick={this.handleOnSubmit}
            size="xlarge"
            block
          >
            {this.setBtnText()}
          </Button>
        </div>
      )
    );
  }
}

AdditionalInfo.propTypes = {
  fields: arrayOf(
    shape({
      driverRegistrationInfoKey: string,
      sortingPriority: int,
      example: string,
      length: int,
      required: bool,
      label: string,
      type: string,
      lookup: arrayOf(
        shape({
          id: oneOfType([string, number]),
          value: oneOfType([string, number])
        })
      )
    })
  ),
  loading: bool,
  loadingPost: bool,
  status: bool,
  submittedFields: shape({}),
  getAdditionalInfo: func.isRequired,
  setAdditionalInfo: func.isRequired,
  apiError: arrayOf(
    shape({
      error: string,
      driverRegistrationInfoKey: string
    })
  ),
  t: func.isRequired,
  device: string,
  flow: string
};

AdditionalInfo.defaultProps = {
  fields: [],
  loading: false,
  loadingPost: false,
  status: false,
  submittedFields: {},
  postAdditionalInfoCall: false,
  device: DEVICE_TYPE.WEB,
  apiError: [],
  flow: ADDITIONAL_INFO_SIGNUP_POPUP
};

const mapStateToProps = ({ additionalInfo, config }) => ({
  fields: additionalInfo.fields,
  loading: additionalInfo.loading,
  loadingPost: additionalInfo.loadingPost,
  status: additionalInfo.status,
  submittedFields: additionalInfo.submittedFields,
  postAdditionalInfoCall: additionalInfo.showAdditionalInfo,
  device: config.country.device,
  apiError: additionalInfo.error,
  flow: additionalInfo.flow
});

const mapDispatchToProps = {
  getAdditionalInfo,
  setAdditionalInfo,
  clearAdditionalInfoError
};

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(AdditionalInfo);
