import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';

import {initializeTrainingSessionFields} from 'store/actions/trainingSession'
import { formFinish } from 'store/actions/config';

export class TrainingSessionBooking extends Component {
    componentDidMount() {
      this.props.formFinish({
        isFormFinish: false,
      })
      this.props.initializeTrainingSessionFields();
    };

  render() {
    return (
      <div></div>
    );
  }
}

const mapStateToProps = state => ({
  completed: state.config.completed,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    formFinish,
    initializeTrainingSessionFields
  }),
)(TrainingSessionBooking);