import React, { Component } from 'react';
import {
  WEB_CHAT_DATA_APP_URL,
  WEB_CHAT_BOT_QUEUES_AVAILABLE,
  WEB_CHAT_BOT_DATA_APP_URL,
  WEB_CHAT_REGION_DRIVER_QUEUE_ALIAS,
  WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS,
  WEB_CHAT_NEW_FEAT_ENABLED,
  WEB_CHAT_NEW_FEAT_QUEUES,
  WEB_CHAT_REGION_CONFIG_API,
  WEB_CHAT_VCONSOLE_ENABLED
} from '../../startupScript'
import VConsole from 'vconsole';
import './style.css';
import axios from 'axios';
import {genesysConfig} from '../../utils/genesys.config';
import i18n from 'i18next';
import * as questionFormFeatOld from './questionFormFeatOld';
import * as questionFormFeatNew from './questionFormFeatNew';
import * as Sentry from "@sentry/react";

export function getParams() {
  const urlPage = window.location.href;
  const url = new URL(urlPage);
  let queryString = url.search.substring(1);
  //avoid using '?' in the query string value
  if((queryString.match(/\?/g) || []).length>1){
    Sentry.captureMessage('queryString with more than one ?: '+queryString);
    Sentry.captureException('queryString with more than one ?: '+queryString);
  }
  queryString = queryString.replace(/\?/g, '&');
  queryString = '?' + queryString;
  console.error('queryString: ', queryString);
  const params = new URLSearchParams(queryString);
  try {
    let languageQueueName = trimForStr(params.get('queueName'));
    //some hard code queue process
    if(languageQueueName){
      languageQueueName = languageQueueName.replace('_IN_', '_ID_');
      languageQueueName = languageQueueName.replace('PH_MNL_ZH_USER_CHAT', 'PH_MNL_EN_USER_CHAT');
      languageQueueName = languageQueueName.replace('MY_KUL_ZH_USER_CHAT', 'MY_KUL_EN_USER_CHAT');
    }

    // the '+' has been changed by URLSearchParams, change it back
    const phoneNumber = params.get('phoneNumber') !== null ? params.get('phoneNumber').replace(/\s/, '+') : null;
    return {
      firstName: trimForStr(params.get('driverFirstName')),
      lastName: trimForStr(params.get('driverLastName')),
      question: trimForStr(params.get('question')),
      email: trimForStr(params.get('driverEmail')),
      driverInfoLink: trimForStr(params.get('driverInfoLink')),
      orderInfoLink: trimForStr(params.get('orderInfoLink')),
      queueName: languageQueueName,
      phoneNumber: phoneNumber,
      isLegacy: isLegacy(params)
    };
  }catch (e){
    console.error('getParams exception: ', e);
    Sentry.captureException('getParams: '+JSON.stringify(e));
    return {
      firstName: trimForStr(params.get('driverFirstName')),
      lastName: trimForStr(params.get('driverLastName')),
      question: trimForStr(params.get('question')),
      email: trimForStr(params.get('driverEmail')),
      driverInfoLink: trimForStr(params.get('driverInfoLink')),
      orderInfoLink: trimForStr(params.get('orderInfoLink')),
      queueName: trimForStr(params.get('queueName')),
      phoneNumber: trimForStr(params.get('phoneNumber')),
      isLegacy: isLegacy(params)
    };
    }
};

function trimForStr(input){
  return input !== null ? input.trim() : null;
}

function isLegacy(params) {
  return !(params.get(IS_GLOBAL) === "1");
}

// URL = http://localhost:3000/chat?driverFirstName=pawan&driverLastName=KK&driverEmail=demo@123.com&driverInfoLink=http://google.com&orderInfoLink=http://facebook.com&queueName=WebChat

const IS_GLOBAL = "isGlobal"
let feat = {};
export class ChatWidget extends Component {
  constructor(props) {
    super(props);
    this.CXBus = {};
    this.customPlugin = {};
  }

  getCookie = (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (name === cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  };

  setSessionExistingFlagCookie = (flag) => {
    localStorage.setItem('isExistingSession', flag);
  }

  loadSession = () => {
    console.error('cookie before load session: ', document.cookie.split(';'), document.cookie);
    ['ConversationID', 'JWtoken', 'MemberID', 'WS_URL'].forEach(i => {
        localStorage.getItem(i) && (document.cookie = localStorage.getItem(i) + '; domain=.lalamove.com');
    })
  }

  saveSession = () => {
    ['ConversationID', 'JWtoken', 'MemberID', 'WS_URL'].forEach(i => {
        localStorage.setItem(i, document.cookie.split(';').find(j => j.includes(i)).trim());
    })
  }

  clearSession = () => {
    localStorage.clear();
  }

  async getQueueRequest({ queueName, phoneNumber, email, isLegacy }) {
    let processedQueueName = queueName;
    try {
      let countryId;
      let uApiUrl;
      const region = queueName.split('_')[0] || '';
      const regionConfigResp = await axios.get(WEB_CHAT_REGION_CONFIG_API);
      console.error('regionConfigResp: ', regionConfigResp);
      if(regionConfigResp.status===200&&regionConfigResp.data.ret===0){
        regionConfigResp.data.data.forEach((regionInfo)=>{
          if(regionInfo.id===region){
            uApiUrl = regionInfo.uapi;
            countryId = regionInfo.countryId;
          }
        });
      }
      const url = `${uApiUrl}/index.php/?_m=cs_chat&hcountry=${countryId}&queue_info=${queueName}&phone_number=${encodeURIComponent(phoneNumber)}&email=${email}`
      const uApiResp = await axios.get(url);
      if(uApiResp.data&&uApiResp.data.ret===0){
        processedQueueName = uApiResp.data.data[0];
      }
      console.error('uApiResp, processedQueueName(before legacy): ', uApiResp, processedQueueName);
      processedQueueName = this.processLegacyQueue(processedQueueName, isLegacy);
      console.error('originalqueueName, processedQueueName, isLegacy: ', queueName, processedQueueName, isLegacy);
      throw new Error('attempt for throw exception: ', processedQueueName);
      // return processedQueueName;
    }catch (e){
      console.error('getQueueRequest exception: ', e);
      Sentry.captureException('getQueueRequest: '+JSON.stringify(e));
      return processedQueueName;
    }finally {
      this.setLangByQueueName(processedQueueName);
    }
  };
  setLangByQueueName(queueName) {
    if (queueName) {
      let country = queueName.split('_')[0];
      let languageQueue = queueName.split('_')[2];
      console.error('lang: ', languageQueue.toLowerCase() + '_' + country);
      i18n.changeLanguage(languageQueue.toLowerCase() + '_' + country);
    }
  }
  async componentDidMount() {
    const paramsData = getParams();
    paramsData.queueName = await this.getQueueRequest(paramsData);
    console.error('original queueName : ', new URLSearchParams(new URL(window.location.href).search).get('queueName'), '#----#processed queueName: ', paramsData.queueName);
    //if other feat added, add one more try block of this one, avoid use eval to parse parameter
    console.error('WEB_CHAT_NEW_FEAT_ENABLED : ', WEB_CHAT_NEW_FEAT_ENABLED['questionFormFeat'], WEB_CHAT_NEW_FEAT_QUEUES,
        paramsData.queueName);
    try {
      if (WEB_CHAT_NEW_FEAT_ENABLED['questionFormFeat'] && WEB_CHAT_NEW_FEAT_QUEUES['questionFormFeat'].indexOf(paramsData.queueName) !== -1) {
        console.error('questionFormFeatNew');
        feat['questionFormFeat'] = questionFormFeatNew;
      } else {
        feat['questionFormFeat'] = questionFormFeatOld;
        console.error('questionFormFeatOld');
      }
    }catch (e) {
      console.error('feat questionForm import exception, e: ', e);
      Sentry.captureException('feat questionForm import exception, e: '+JSON.stringify(e));
      feat = questionFormFeatOld;
    }

    if (WEB_CHAT_VCONSOLE_ENABLED) {
      // eslint-disable-next-line
      var vConsole = new VConsole();
    }
    document.title = "CS Chat";
    try {
      const { widgets: { main, webchat } } = genesysConfig;
      const webchatObj = feat['questionFormFeat'].createWebchatObj(webchat, paramsData, this.isBotAvailable(paramsData.queueName));
      console.error('webchatObj: ', webchatObj);
      window._genesys = {
        widgets: {
          main,
          webchat: webchatObj
        }
      };
      if (this.isBotAvailable(paramsData.queueName)) {
        this.loadVACxbus();
      } else {
        window._genesys.widgets.webchat.transport.interactionData.routing.targetAddress = paramsData.queueName || 'WebChat';
        this.loadCxbus();
      }
    }catch (e) {
      console.error('componentDidMount connect genesys/cv exception: ', e);
      Sentry.captureException('connect genesys/cv exception: '+JSON.stringify(e));
    }
  }

  // virtual asistent
  loadVACxbus = () => {
    const existingScript = document.getElementById('cxbus');
    if (!existingScript) {
      const widgetBaseUrl = `${WEB_CHAT_BOT_DATA_APP_URL}`;
      const script = document.createElement('script');
      script.src = `${widgetBaseUrl}va.js`;
      script.id = 'cxbus';
      const vaDiv = document.getElementById('virtual-assistant');
      // remove anchors
      const eventTrigerAnchor = document.getElementById('eventTriger');
      const startEventTrigerAnchor = document.getElementById('startEventTriger');
      vaDiv.removeChild(eventTrigerAnchor);
      vaDiv.removeChild(startEventTrigerAnchor);
      //append script
      vaDiv.appendChild(script);

    }
  };

  loadCxbus = () => {
    const existingScript = document.getElementById('cxbus');
    if (!existingScript) {
      // const widgetBaseUrl = `${WEB_CHAT_DATA_APP_URL}/9.0/`;
      const widgetBaseUrl = `${WEB_CHAT_DATA_APP_URL}/9.0.017.25/`;
      const script = document.createElement('script');
      script.src = `${widgetBaseUrl}cxbus.min.js`;
      script.id = 'cxbus';
      document.head.appendChild(script);

      if(localStorage.getItem('isExistingSession')==='true'){
        this.loadSession();
      }
      script.onload = () => {
        window.CXBus.configure({ debug: false, pluginsPath: widgetBaseUrl + 'plugins/' });
        const widgetLoad = window.CXBus.loadPlugin('widgets-core');
        widgetLoad.done((e) => {
          this.customPlugin = window.CXBus.registerPlugin('Custom');
          console.error('isexistingSession: ', localStorage.getItem('isExistingSession'), document.cookie.split(';'));
          // if(this.getCookie('_genesys.widgets.webchat.state.purecloud-v2-sockets.JWtoken')) {
            this.customPlugin.command('WebChat.open', feat['questionFormFeat'].getAdvancedConfig()).done(function(e){
              console.error('WebChat.open done', e);
            }).fail(function(e){
              console.error('error in open chat', e);
            });
          this.customPlugin.subscribe('WebChat.opened', function (e) {
            console.error('WebChat.opened: ', e);
          });
          this.customPlugin.subscribe('WebChat.messageAdded', function (e, a,b,c) {
            console.error('WebChat.messageAdded: ', e, a, b,c);
          });
          this.customPlugin.subscribe('WebChat.unminimized', function (e) {
            console.error('WebChat.unminimized: ', e);
          });
          console.error('subscribe WebChat.cancelled');
          feat['questionFormFeat'].listenCancelEvt(this.customPlugin);
          this.customPlugin.subscribe('WebChatService.ended', (e) => {
            console.error('WebChatService.ended', e);
            // End chat event send to device

            setTimeout(() => {
              try {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                this.setSessionExistingFlagCookie(false);
                this.clearSession();
              }catch (e){
                Sentry.captureException('WebChatService.ended exception, cookie: '+localStorage.getItem('isExistingSession'), [e, localStorage.getItem('isExistingSession'), document.cookie.split(';')]);
              }
              //Check mobile device is Android
              if (/android/i.test(userAgent)) {
                if (window.Android){
                  window.Android.endChat();
                }
              }

              //Check mobile device is IOS
              if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                //Add your Code here
                var chatEvent = document.getElementById('eventTriger').href;
                window.open(chatEvent, '_self');
                // HLL style event
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app && typeof window.webkit.messageHandlers.app.postMessage === 'function') {
                  window.webkit.messageHandlers.app.postMessage(JSON.stringify({action: "endChat"}));
                }
              }
            }, 2000);
          });
          this.customPlugin.subscribe('WebChatService.started',  (e) => {
            try{
              this.saveSession();
              this.setSessionExistingFlagCookie(true);
            }catch (e){
              Sentry.captureException('WebChatService.started, cookie: '+localStorage.getItem('isExistingSession'), [e, localStorage.getItem('isExistingSession'), document.cookie.split(';')]);
            }
            console.error('WebChatService.started', e);
            // End chat event send to device
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            //Check mobile device is Android
            if (/android/i.test(userAgent)) {
              if (window.Android){
                window.Android.startChat();
              }
            }

            //Check mobile device is IOS
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              //Add your Code here
              var chatEvent = document.getElementById('startEventTriger').href;
              window.open(chatEvent, '_self');
              // HLL style event
              if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app && typeof window.webkit.messageHandlers.app.postMessage === 'function') {
                window.webkit.messageHandlers.app.postMessage(JSON.stringify({action: "startChat"}));
              }
            }
          });
        });
      };
    }
  };

  render() {
    let appendText = '';
    if (process.env.REACT_APP_HOST_ENV !== 'production') {
      appendText = 'driver.' + process.env.REACT_APP_HOST_ENV + '.lalamove.com';
    }
    return (<div id="virtual-assistant" >{appendText}
      <a id="eventTriger" href="scheme://endChat" title="event">Event</a>
      <a id="startEventTriger" href="scheme://startChat" title="event">Event</a>
    </div>)
  }

  isBotAvailable(queueName) {
    let webChatBotQueuesAvailable = WEB_CHAT_BOT_QUEUES_AVAILABLE.split(',');
    console.error('isBotAvailable: ', queueName, webChatBotQueuesAvailable, webChatBotQueuesAvailable.includes(queueName));
    return webChatBotQueuesAvailable.includes(queueName)
  }

  processLegacyQueue(queueName, isLegacy){
    if (queueName) {
      let region = queueName.substring(0,queueName.split('_', 2).join('_').length);
      let typeOfUser = queueName.substring(queueName.split('_', 3).join('_').length + 1,queueName.split('_', 4).join('_').length);
      queueName = this.setQueueName(queueName, region, typeOfUser, isLegacy);
    }
    return queueName;
  }

  setQueueName(queueName, region, typeOfUser, isLegacy) {
    let regionList = {};
    if (typeOfUser === 'DRIVER') {
      // handling special cases for legacy app
      if (isLegacy) {
        // add the region list of the config inside new region list
        regionList = {
          ...WEB_CHAT_REGION_DRIVER_QUEUE_ALIAS
        }
        for (var key in WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS) {
          if (WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS[key] in regionList) {
            // if element of region in legacy list exist on the one for global, we remove it from global
            delete regionList[WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS[key]]
          }
        }
        // append the new region list with the legacy one
        regionList = {
          ...regionList,
          ...WEB_CHAT_REGION_LEGACY_DRIVER_QUEUE_ALIAS
        }
        //override the region if inside the new region list
        if (region in regionList) {
          queueName = queueName.replace(region, regionList[region]);
        }
      }
    }
    return queueName;
  }
}

export default ChatWidget;

// export default compose(
//     translate()
// )(ChatWidget);


