import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import { Button } from '@lalamove/karang';
import { func, bool, string } from 'prop-types';
import { onSignUpCompleted } from 'utils/callback';
import { formFinish } from 'store/actions/config';
import { trackRegistrationCompletePageLoad, trackRegistrationAppLoginClick, trackRegistrationAppDownloadClick } from 'store/actions/tracking';

import { FlexGrowDiv } from 'style';
import {
  SuccessImg,
  SuccessTitle,
  SuccessSubtitle,
} from './style';

export class Welcome extends Component {
  static propTypes = {
    t: func.isRequired,
    fromDriverApp: bool.isRequired,
    device: string,
    formFinish: func,
  };

  static defaultProps = {
    device: null,
  }

  componentDidMount() {
    this.props.formFinish({
        isFormFinish: true,
    });
    this.props.trackRegistrationCompletePageLoad({});
  };
  
  openStore = () => {
    this.props.trackRegistrationAppDownloadClick({});
    const storeLink = this.props.device === 'ios'
      ? 'https://itunes.apple.com/app/id848896555'
      : 'https://play.google.com/store/apps/details?id=hk.easyvan.app.driver2';

    window.open(storeLink, '_blank');
  }

  openApp = () => {
	  this.props.trackRegistrationAppLoginClick({});
    onSignUpCompleted()
  }

  render() {
    const {
      t,
      fromDriverApp,
      device,
    } = this.props;

    return (
      <div className="mainContainer">
        <FlexGrowDiv>
          <div className="paddingWrapper">
            <SuccessImg
              alt="successImage"
              src={t('Home.successBackground')}
            />
            <SuccessTitle>
              {t('Home.successTitle')}
            </SuccessTitle>
            <SuccessSubtitle>
              {t('Home.successSubtitle')}
            </SuccessSubtitle>
          </div>
        </FlexGrowDiv>
        { (device === 'android' || device === 'ios') && (
          <Button
            variant="primary"
            size="xlarge"
            onClick={fromDriverApp ? this.openApp : this.openStore}
            block
          >
            { fromDriverApp ? t('Welcome.doneButton') : t('Welcome.downloadAppButton')}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fromDriverApp: state.config.country.fromDriverApp,
  device: state.config.country.device,
  completed: state.config.completed,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    formFinish,
    trackRegistrationCompletePageLoad,
    trackRegistrationAppDownloadClick,
    trackRegistrationAppLoginClick
  }),
)(Welcome);
