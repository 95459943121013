import { delay } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getTel, getIsRequestResend, getVerificationQuota, getQueryParams, getOperatingSystem, getCurrentCountry, getPinTriggeredEventProperties } from 'store/selectors';
import { EventTypes } from 'redux-segment';

import {
  TEL_SUBMIT_REQUEST,
  TEL_RESUBMIT_FAILURE,
  TEL_SUBMIT_FAILURE,
  TEL_SUBMIT_SUCCESS,

  RESEND_LOCK,
  RESEND_RELEASE,
  SIGNUP_LOCK,
  SIGNUP_RELEASE,

  PIN_VERIFY,
  PIN_SUBMIT_REQUEST,
  PIN_SUBMIT_SUCCESS_NO_LOADING,
  PIN_SUBMIT_FAILURE,

  CHANGED_SUBMITTED_TEL,
  SET_IDENTITY_TRACKER,
} from 'store/actions/auth';
import {
  GET_BASIC_INFO,
  SIGNUP_REQUEST,
} from 'store/actions/signup';
import {
  TRACKING_IDENTIFY_DRIVER_ID,
  trackingRegistrationPinTriggered
} from 'store/actions/tracking';
import Api from 'utils/api';
import { isNewSignUpVersion } from 'utils/helpers';

export function* handleTelSubmit({ payload }) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
    queryParams = queryParams || '';
    const os = yield select(getOperatingSystem);
    const country = yield select(getCurrentCountry);
    const requestedResend = yield select(getIsRequestResend);
    const savedTel = yield select(getTel);
    const response = yield call(
      Api.postSignupPhoneVerification,
      payload,
    );

    if (savedTel !== payload.tel) {
      yield put({
        type: CHANGED_SUBMITTED_TEL,
      });
    }

    const { statusCode, body } = response;

    if (statusCode === 200) {
      yield put({
        type: TEL_SUBMIT_SUCCESS,
        payload: {
          tel: payload.tel,
        },
      });
      yield put({
        type: PIN_VERIFY,
        payload: {
          remainingSMSLimit: body.remainingSMSLimit,
          remainingCallLimit: body.remainingCallLimit,
          waitBeforeResending: body.waitBeforeResending,
          disabledPinInput: false,
        },
      });
      yield put({
        type: RESEND_LOCK,
        payload: {
          blockDuration: body.waitBeforeResending,
        },
      });

      let propObj = {
        llm_source: os || '',
        country: country ? country.country : '',
        language: country ? country.lang : '',
        phone: payload.tel.replace('+', ''),
        trigger_method: payload.method,
      }

      if (isNewSignUpVersion()) {
        const propObject = yield select(getPinTriggeredEventProperties);
        propObj = { ...propObj, ...propObject };
      }

      yield put(trackingRegistrationPinTriggered({
        analytics: {
          eventType: EventTypes.track,
          eventPayload: {
            event: 'Registration PIN Triggered',
            properties: propObj
          }
        }
      })());
      if (!requestedResend) {
        yield put(push(`/register/verification${queryParams}`))
      }

      return;
    }

    // handling not success case
    const { error: { message } } = body;
    switch (statusCode) {
      case 409:
        // this status code will happen only after driver entered their phone number
        yield put({
          type: TEL_SUBMIT_FAILURE,
          payload: {
            error: message,
          },
        });
        break;
      case 429:
        let payloadToStore = {
          tel: payload.tel,
          remainingSMSLimit: body.remainingSMSLimit,
          remainingCallLimit: body.remainingCallLimit,
        };
        if (payload.method === 'SMS' && parseInt(body.remainingCallLimit, 10) > 0 && parseInt(body.remainingSMSLimit, 10) === 0) {
          payloadToStore.error = 'tryVoiceInstead';
        } else {
          let signupLockPayload = {
            error: message,
            waitBeforeSignupAgain: body.waitBeforeRetry,
            disabledPinInput: true,
          }
          if (message === 'Maximum request rate exceeded') {
            signupLockPayload.error = null;
            signupLockPayload.disabledPinInput = false;
          }
          yield put({
            type: SIGNUP_LOCK,
            payload: signupLockPayload,
          });
        }
        yield put({
          type: TEL_RESUBMIT_FAILURE,
          payload: payloadToStore,
        });
        yield put(push(`/register/verification${queryParams}`));
        break;
      default:
        throw Error('server error');
    }
  } catch (e) {
    yield put({
      type: TEL_SUBMIT_FAILURE,
    });
    yield put(push(`/register/error${queryParams}`));
  }
}

export function* handleResendLock({ payload: { blockDuration } }) {
  yield call(delay, blockDuration);
  yield put({ type: RESEND_RELEASE });
}

export function* handleSignupLock({ payload: { waitBeforeSignupAgain } }) {
  yield call(delay, waitBeforeSignupAgain);
  yield put({ type: SIGNUP_RELEASE });
}

export function* handlePinSumbit({ payload: { pin } }) {
  let queryParams = '';
  try {
    queryParams = yield select(getQueryParams);
    queryParams = queryParams || '';
    const tel = yield select(getTel);
    const response = yield call(
      Api.postSignupPhoneVerify,
      {
        tel,
        pin,
      },
    );
    const { statusCode, body } = response;

    if (statusCode === 200) {
      Api.changeToken(body.token);
      yield put({
        type: PIN_SUBMIT_SUCCESS_NO_LOADING,
        payload: {
          token: body.token,
          driverTrackingId: body.driverTrackingId || null
        },
      });
      if (body.driverTrackingId) {
        let driverIdWithoutCountry = body.driverTrackingId.split('_')[1];
        yield put({
          type: TRACKING_IDENTIFY_DRIVER_ID,
          meta: {
            analytics: {
              eventType: EventTypes.identify,
              eventPayload: {
                userId: body.driverTrackingId,
                traits: {
                  phone: tel ? tel.replace('+', '') : '',
                  phone_verified: true,
                  id: driverIdWithoutCountry
                }
              }
            }
          }
        });
      }
      if (isNewSignUpVersion()) {
        const { submittedFields } = yield select(store => store.auth.signup)
        const { FORM_TEL, ...rest } = submittedFields
        yield put({ type: SIGNUP_REQUEST, payload: { submittedFields: rest } });
      }
      else {
        yield put({ type: GET_BASIC_INFO });
      }
      return;
    }

    // handling not success case
    const { error: { message } } = body;
    let payload = {};
    const verificationQuota = yield select(getVerificationQuota);
    switch (statusCode) {
      case 403:
        payload = {
          remainingSMSLimit: verificationQuota.remainingSMSLimit,
          remainingCallLimit: verificationQuota.remainingCallLimit,
          error: message,
        };

        yield put({
          type: PIN_SUBMIT_FAILURE,
          payload,
        });
        break;
      case 429:
        yield put({
          type: PIN_SUBMIT_FAILURE,
          payload: {},
        });
        yield put({
          type: SIGNUP_LOCK,
          payload: {
            error: `${message}`,
            waitBeforeSignupAgain: body.waitBeforeRetry,
          },
        });
        break;
      default:
        throw Error('server error');
    }
  } catch (e) {
    yield put({
      type: PIN_SUBMIT_FAILURE,
    });
    yield put(push(`/register/error${queryParams}`));
  }
}

export function* handleSetIdentity(action) {
  const { driverTrackingId, tel, driverIdWithoutCountry } = action.payload
  yield put({
    type: TRACKING_IDENTIFY_DRIVER_ID,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: driverTrackingId,
          traits: {
            phone: tel ? tel.replace('+', '') : '',
            phone_verified: true,
            id: driverIdWithoutCountry
          }
        }
      }
    }
  });
}

export default function* authSaga() {
  yield takeLatest(TEL_SUBMIT_REQUEST, handleTelSubmit);
  yield takeEvery(RESEND_LOCK, handleResendLock);
  yield takeEvery(SIGNUP_LOCK, handleSignupLock);
  yield takeLatest(PIN_SUBMIT_REQUEST, handlePinSumbit);
  yield takeLatest(SET_IDENTITY_TRACKER, handleSetIdentity)
}
