
/**
 *  (lalamove.com/country/city/lang) url specific mapping for default lang, city and country
 */
const COUNTRY_URL_MAP = {
  'taiwan/taipei/en': { city: "TW_TPE", country: "TW", lang: "en_TW" },
  'taiwan/taipei/zh': { city: "TW_TPE", country: "TW", lang: "zh_TW" },
  "hongkong/en/delivery-driver" : { city : "HK_HKG", country: "HK", lang :"en_HK" },
  "hongkong/zh/delivery-driver" : { city : "HK_HKG", country: "HK", lang :"zh_HK" },
  'singapore/en/driver': { city: "SG_SIN", country: "SG", lang: "en_SG" },
  'thailand/bangkok/en': { city: "TH_BKK", country: "TH", lang: "en_TH" },
  'thailand/bangkok/th': { city: "TH_BKK", country: "TH", lang: "th_TH" }, 
  'thailand/pattaya/th': { city: "TH_PYX", country: "TH", lang: "th_TH" },
  'thailand/pattaya/en': { city: "TH_PYX", country: "TH", lang: "en_TH" }, 
  'philippines/manila/fil': { city: "PH_MNL", country: "PH", lang: "en_PH" },
  'philippines/cebu/ceb': { city: "PH_CEB", country: "PH", lang: "en_PH" }, 
  "india/mumbai/en" : { city: "IN_BOM", country: "IN", lang : 'en_IN' },
  "india/bengaluru/en" : { city: "IN_BLR", country: "IN", lang : 'en_IN' },
  "india/delhi/en" : { city: "IN_DEL", country: "IN", lang : 'en_IN' },
  "india/hyderabad/en" : { city: "IN_HYD", country: "IN", lang : 'en_IN' }, 
  "india/pune/en" : { city: "IN_PNQ", country: "IN", lang : 'en_IN' },
  "india/chennai/en" : { city: "IN_MAA", country: "IN", lang : 'en_IN' },  
  'indonesia/jakarta/id': { city: "ID_JKT", country: "ID", lang: "id_ID" },
  'indonesia/jakarta/en': { city: "ID_JKT", country: "ID", lang: "en_ID" }, 
  'malaysia/kualalumpur/ms': { city: "MY_KUL", country: "MY", lang: "ms_MY" },
  'malaysia/kualalumpur/en': { city: "MY_KUL", country: "MY", lang: "en_MY" }, 
  'vietnam/hcmc/en': { city: "VN_SGN", country: "VN", lang: "en_VN" },
  'vietnam/hcmc/vi': { city: "VN_SGN", country: "VN", lang: "vi_VN" }, 
  'vietnam/hanoi/vi': { city: "VN_HAN", country: "VN", lang: "vi_VN" },
  'vietnam/hanoi/en': { city: "VN_HAN", country: "VN", lang: "en_VN" }, 
  'brasil/motorista': { city: "BR_SAO", country: "BR", lang: "pt_BR" }, 
  'mexico/conductor': { city: "MX_MEX", country: "MX", lang: "es_MX" }
}

const THANK_YOU_PAGE_MAPPING = {
  'TW_TPE_EN': 'https://www.lalamove.com/taiwan/taipei/en/driver-thank-you',
  'TW_TPE_ZH': 'https://www.lalamove.com/taiwan/taipei/zh/driver-thank-you',
  'HK_HKG_ZH': "https://www.lalamove.com/hongkong/zh/driver-thank-you",
  'HK_HKG_EN': "https://www.lalamove.com/hongkong/en/driver-thank-you",
  'SG_SIN_EN': "https://www.lalamove.com/singapore/en/driver-thank-you",
  'TH_BKK_EN': "https://www.lalamove.com/thailand/bangkok/en/driver-thank-you",
  'TH_BKK_TH': "https://www.lalamove.com/thailand/bangkok/th/driver-thank-you",
  'TH_PYX_EN': 'https://www.lalamove.com/thailand/pattaya/en/driver-thank-you',
  'TH_PYX_TH': "https://www.lalamove.com/thailand/pattaya/th/driver-thank-you",
  'PH_MNL_EN': 'https://www.lalamove.com/philippines/manila/fil/thank-you',
  'PH_CEB_EN': ' https://www.lalamove.com/philippines/cebu/en/driver-thank-you',
  'IN_BOM_EN':  "https://www.lalamove.com/mumbai/en/thank-you",
  "IN_BLR_EN" : 'https://www.lalamove.com/bengaluru/en/thank-you',
  "IN_DEL_EN" : 'https://www.lalamove.com/delhi/en/thank-you',
  "IN_HYD_EN" : 'https://www.lalamove.com/hyderabad/en/driver-thank-you',
  "IN_PNQ_EN" : 'https://www.lalamove.com/pune/en/driver-thank-you',
  "IN_MAA_EN" : 'https://www.lalamove.com/chennai/en/driver-thank-you',
  "ID_JKT_EN" : 'https://www.lalamove.com/indonesia/jakarta/en/thank-you',
  "ID_JKT_ID" : 'https://www.lalamove.com/indonesia/jakarta/id/thank-you',
  "MY_KUL_EN" : 'https://www.lalamove.com/malaysia/kualalumpur/en/driver-thank-you',
  "MY_KUL_MS" : 'https://www.lalamove.com/malaysia/kualalumpur/ms/driver-thank-you',
  "VN_SGN_EN" :'https://www.lalamove.com/vietnam/hcmc/en/thank-you',
  "VN_SGN_VI" :'https://www.lalamove.com/vietnam/hcmc/vi/thank-you',
  "VN_HAN_EN" :'https://www.lalamove.com/vietnam/hanoi/en/thank-you',
  "VN_HAN_VI" :'https://www.lalamove.com/vietnam/hanoi/vi/thank-you',
  'BR_SAO_PT': 'https://www.lalamove.com/brasil/driver-thank-you', 
  'BR_SAO_EN': 'https://www.lalamove.com/brasil/driver-thank-you',
  'MX_MEX_EN': 'https://www.lalamove.com/mexico/driver-thank-you', 
  'MX_MEX_ES': 'https://www.lalamove.com/mexico/driver-thank-you'
}

export { 
  COUNTRY_URL_MAP,
  THANK_YOU_PAGE_MAPPING
}