import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { string } = PropTypes;

class TopBanner extends Component {

  static defaultProps = {
    background: '',
    title: '',
    subTitle: '',
  };
  
  static propTypes = {
    background: string,
    title: string,
    subTitle: string,
  };

  render() {
    const {
      background,
      title,
      subTitle
    } = this.props;

    const Container = styled.div`
      display: flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 309px;
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      background-image: url(${background});
    `;
    Container.displayName = 'container';
    
    const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
      padding-bottom: 1.6em;
      text-overflow: ellipsis;
    `;
    
    const Header = styled.span`
      margin: 0 0 0.3em 0;
      color: white;
      font-size: 1.5em;
      font-display: bold;
    `;
    
    const SubHeader = styled.span`
      color: white;
      font-size: 1.03em;
      line-height: 1em;
    `;

    return (
      <Container>
        <Wrapper>
          <Header>
          {title}
          </Header>
          <SubHeader>
          {subTitle}
          </SubHeader>
        </Wrapper>
      </Container>
    );
  }
}

export default TopBanner;
