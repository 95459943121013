const OTHERS_OPTION = 'other';
const ADDITIONAL_INFORMATION_PAGE_VIEWED = 'Additional Information Page Viewed';
const ADDITIONAL_INFORMATION_PAGE_DATA_SUBMITTED =
  'Additional Information Page Data Submitted';
const ADDITIONAL_INFO_SIGNUP_POPUP = 'continuous';
const ADDITIONAL_INFO_VIA_DRIVER_APP = 'mobile';
const THANK_YOU_PAGE_DEFAULT_KEY = 'HK_HKG_EN';
const FILLED = 'FILLED';
const NOT_FILLED = 'NOT_FILLED';
const NO_FIELDS = 'NO_FIELDS';

export {
  OTHERS_OPTION,
  ADDITIONAL_INFORMATION_PAGE_VIEWED,
  ADDITIONAL_INFO_SIGNUP_POPUP,
  ADDITIONAL_INFO_VIA_DRIVER_APP,
  ADDITIONAL_INFORMATION_PAGE_DATA_SUBMITTED,
  THANK_YOU_PAGE_DEFAULT_KEY,
  FILLED,
  NOT_FILLED,
  NO_FIELDS
};
