import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import loadTranslations from 'utils/loadTranslations';

function locales() {
  const context = loadTranslations;
  const obj = {};
  context.keys().forEach((key) => {
    const newKey = key.substr(2, key.length)
      .split('.')[0];
    obj[newKey] = context(key);
  });
  return obj;
}

const resources = locales();

i18n
  .use(LngDetector)
  .use(reactI18nextModule)
  .init({
    resources,
    initImmediate: true,
      preload: ['en_HK', 'en_ID', 'en_MY', 'en_PH', 'en_SG', 'en_TH', 'en_TW', 'en_VN', 'es_MX', 'id_ID', 'ms_MY', 'pt_BR', 'th_TH', 'vi_VN', 'zh_HK', 'zh_TW'],
    fallbackLng: 'en_HK',
    ns: ['translation'],
    defaultNS: 'translation',
    react: {
      wait: true,
    },
  });

export default i18n;
