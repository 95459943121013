/* global Android, webkit */
export const onSignedUp = (
  username,
  password,
  country,
  city,
  language,
) => {
  const data = `{"username": "${username}", "password": "${password}", "country": "${country}", "city": "${city}", "language": "${language}"}`;
  if (typeof Android !== 'undefined' || (typeof webkit !== 'undefined' && webkit.messageHandlers)) {
    try {
      Android.onSignedUp(data);
    } catch (e) {
      webkit.messageHandlers.onSignedUp.postMessage(data);
    }
    return true;
  }
  return false;
}

export const onSignUpCompleted = () => {
  if (typeof Android !== 'undefined' || (typeof webkit !== 'undefined' && webkit.messageHandlers)) {
    try {
      Android.onSignUpCompleted();
    } catch (e) {
      webkit.messageHandlers.onSignUpCompleted.postMessage('');
    }
    return true;
  }
  return false;
}
