
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRouteComponent = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
          return rest.completed.isFormFinish 
          ? window.location.replace('/register' + window.location.search)
          : (<Component {...props} />)
        }
      }
    />
);

const mapStateToProps = state => ({
    completed: state.config.completed,
  });

const PrivateRoute = connect(mapStateToProps, null)(PrivateRouteComponent);
export default PrivateRoute


