import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';
import {
  func,
  string,
  shape,
  int,
  bool,
  arrayOf,
} from 'prop-types';
import { Input, Select, Button } from '@lalamove/karang';

import {
  getRequiredBasicInfoFields,
  clone,
  handleFieldErrorOnBasicInfo,
  ERROR_TYPE_LENGTH_TOO_LONG,
  ERROR_TYPE_PASSWORD_TOO_SHORT,
  ERROR_TYPE_REQUIRED,
  DEVICE_TYPE,
} from 'utils/helpers';
import { changeCity } from 'store/actions/config';
import {
  signupRequest,
  fieldChanges,
  getBasicInfo,
} from 'store/actions/signup';
import Api from 'utils/api';
import './style.css';

import { FlexGrowDiv } from 'style';

const FORM_CITY = 'FORM_CITY';

export class BasicInfo extends Component {
  static propTypes = {
    t: func.isRequired,
    basicInfoField: arrayOf(
      shape({
        driverRegistrationInfoKey: string,
        sortingPriority: int,
        name: string,
        type: string,
        length: int,
        required: bool,
        lookup: arrayOf(
          shape({
            id: string,
            value: string,
          }),
        ),
      }),
    ).isRequired,
    requiredFields: arrayOf(string),
    changeCity: func.isRequired,
    signupRequest: func.isRequired,
    errorFromApi: arrayOf(
      shape({
        driverRegistrationInfoKey: string,
        type: string,
        length: int,
      }),
    ),
    fieldChanges: func.isRequired,
    isSubmitting: bool.isRequired,
    isGettingFields: bool.isRequired,
    getBasicInfo: func.isRequired,
    city: string.isRequired,
    device: string,
  };

  static defaultProps = {
    requiredFields: [],
    errorFromApi: [],
    device: null,
  };

  state = {
    value: {},
    selectedItem: {},
    error: {},
    filled: {},
  };

  onInputChange = (e) => {
    const {
      name,
      value,
    } = e.target;
    if (
      this.props.errorFromApi
        .filter(err => err.driverRegistrationInfoKey === name).length > 0
    ) {
      this.props.fieldChanges({
        driverRegistrationInfoKey: name,
      });
    }
    var regexp = new RegExp('\\s');
    if (name === 'FORM_PASSWORD' && regexp.test(value)) {
      return;
    }
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [name]: value,
      },
      error: {
        ...prevState.error,
        [name]: null,
      },
      filled: {
        ...prevState.filled,
        [name]: false,
      },
    }));
  }

  static getDerivedStateFromProps(props, state) {
    const {
      errorFromApi,
      t,
      city,
      basicInfoField,
    } = props;

    const error = errorFromApi.reduce((obj, err) => ({
      ...obj,
      ...handleFieldErrorOnBasicInfo(
        t,
        err.driverRegistrationInfoKey,
        err.type,
        err.length,
      ),
    }), {});

    let selectCity = {};
    // when first come to this page, no city should be set
    // set the deafult city as selected
    if (!state.value[FORM_CITY]) {
      const cityField = Object.values(basicInfoField)
        .filter(field => field.driverRegistrationInfoKey === FORM_CITY);
      if (cityField.length > 0) {
        const selectedCityInfo = cityField[0]
          .lookup
          .filter(item => item.id === city)[0];

        if (selectedCityInfo !== undefined) {
          selectCity = {
            [FORM_CITY]: selectedCityInfo.id,
          };
        }
      }
    }

    return {
      ...state,
      error: {
        ...state.error,
        ...error,
      },
      value: {
        ...state.value,
        ...selectCity,
      },
      filled: {
        ...state.filled,
        [FORM_CITY]: true,
      },
    };
  }

  handleCopyPaste = (e) => {
    e.preventDefault();
  }

  handleOnBlur = (e) => {
    const required = e.target.getAttribute('data-required') !== null
      ? e.target.getAttribute('data-required')
      : e.target.required;
    const name = e.target.getAttribute('data-name') !== null
      ? e.target.getAttribute('data-name')
      : e.target.name;
    const value = this.state.value[name];

    let error = null;
    if (required && (value === undefined || value.length <= 0)) {
      error = handleFieldErrorOnBasicInfo(this.props.t, name, ERROR_TYPE_REQUIRED);
    }

    if (e.target.maxLength !== -1 && value && value.toString().length > e.target.maxLength) {
      error = handleFieldErrorOnBasicInfo(
        this.props.t,
        name,
        ERROR_TYPE_LENGTH_TOO_LONG,
        e.target.maxLength,
      );
    }
    if (name === 'FORM_PASSWORD' && value && value.toString().length < 6) {
      error = handleFieldErrorOnBasicInfo(this.props.t, name, ERROR_TYPE_PASSWORD_TOO_SHORT);
    }

    if (error !== null) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          ...error,
        },
      }));
      return;
    }
    this.setState(prevState => ({
      filled: {
        ...prevState.filled,
        [name]: true,
      },
    }));
  };

  handleSelection = (selectedItem, dsState) => {
    if (dsState.id === FORM_CITY) {
      Api.changeLocation(selectedItem.id);
      this.props.changeCity({ city: selectedItem.id });
      this.props.getBasicInfo();
    }

    if (this.props.errorFromApi
      .filter(err => err.driverRegistrationInfoKey === dsState.id).length > 0
    ) {
      this.props.fieldChanges({
        driverRegistrationInfoKey: dsState.id,
      });
    }

    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [dsState.id]: selectedItem.id,
      },
      selectedItem: {
        ...prevState.selectedItem,
        [dsState.id]: selectedItem,
      },
      error: {
        ...prevState.error,
        [dsState.id]: null,
      },
      filled: {
        ...prevState.filled,
        [dsState.id]: true,
      },
    }));
  }

  handleOnSubmit = (e) => {
    e.preventDefault();
    var submitValues = {};
    for (var key in this.state.value) {
      if (this.state.value[key].length > 0) {
        submitValues[key] = this.state.value[key];
      }
    }
    this.props.signupRequest({ submittedFields: submitValues });
  };

  shouldNextEnable = () => {
    const requiredFields = clone(this.props.requiredFields);

    // no error on all fields
    const checkError = this.props.basicInfoField.reduce((arr, fields) => {
      const key = fields.driverRegistrationInfoKey;
      if (!(key in this.state.error) || this.state.error[key] === null) {
        return [...arr, key];
      }
      return arr;
    }, []);

    // all required fields are filled
    const checkFieldFilled = requiredFields.reduce((arr, key) => {
      if ((key in this.state.filled) && this.state.filled[key] === true) {
        return [...arr, key];
      }
      return arr;
    }, []);

    return checkError.length !== this.props.basicInfoField.length
      || checkFieldFilled.length !== requiredFields.length
      || this.props.isSubmitting
      || this.props.isGettingFields;
  }

  renderInputField = (fields) => {
    const compare = (a, b) => {
      if (a.sortingPriority < b.sortingPriority) return -1;
      if (a.sortingPriority > b.sortingPriority) return 1;
      return 0;
    };

    return Object.values(fields).sort(compare).map((field) => {
      const key = field.driverRegistrationInfoKey;
      // normal text field
      if (field.lookup.length === 0) {
        let fieldProps = {};

        if (key === 'FORM_PASSWORD') {
          fieldProps = {
            type: 'password',
            autoCapitalize:'none',
            masked: (this.props.device !== DEVICE_TYPE.IOS && this.props.device !== DEVICE_TYPE.ANDROID),
          };
        } else {
          fieldProps = {
            maxLength: field.length,
            type: 'text',
          };
        }
        return (
          <div className="BasicInfoInput" key={`${key}-div`}>
            <Input
              label={this.props.t(`BasicInfo.fields.${key}`)}
              name={key}
              value={this.state.value[key] !== undefined
                ? this.state.value[key]
                : ''
              }
              onChange={this.onInputChange}
              autoComplete="false"
              onCopy={this.handleCopyPaste}
              onPaste={this.handleCopyPaste}
              error={this.state.error[key]}
              required={field.required}
              onBlur={this.handleOnBlur}
              style={{ display: 'block' }}
              disabled={this.props.isSubmitting || this.props.isGettingFields}
              {...fieldProps}
            />
          </div>
        );
      }
      // select input field
      let selectedItem = this.state.selectedItem[key];
      // preset the city to default city when the driver first come to the page
      if (key === FORM_CITY) {
        if (!selectedItem) {
          selectedItem = field.lookup
            .filter(item => item.id === this.props.city)['0'];
        }
      }
      return (
        <div className="BasicInfoInput" key={`${key}-div`}>
          <Select
            id={key}
            label={this.props.t(`BasicInfo.fields.${key}`)}
            name={key}
            items={field.lookup}
            selectedItem={selectedItem}
            onChange={this.handleSelection}
            error={this.state.error[key]}
            onBlur={this.handleOnBlur}
            required={field.required}
            style={{ display: 'block' }}
            disabled={this.props.isSubmitting || this.props.isGettingFields}
            value={selectedItem && selectedItem.value}
          />
        </div>
      );
    });
  };

  render() {
    const {
      t,
      basicInfoField,
    } = this.props;

    return (
      <div className="mainContainer">
        <FlexGrowDiv>
          <form>
            <div className="paddingWrapper">
              <span className="BasicInfoHints">
                {t('BasicInfo.title')}
              </span>
              {this.renderInputField(basicInfoField)}
            </div>
          </form>
        </FlexGrowDiv>
        <Button
          id="submitInfoBtn"
          variant="primary"
          disabled={this.shouldNextEnable()}
          onClick={this.handleOnSubmit}
          size="xlarge"
          block
          solid
        >
          {t('BasicInfo.nextButton')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  basicInfoField: state.auth.signup.fields,
  requiredFields: getRequiredBasicInfoFields(state.auth.signup.fields),
  errorFromApi: state.auth.signup.error,
  isSubmitting: state.auth.signup.isSubmitting,
  isGettingFields: state.auth.signup.isGettingFields,
  city: state.config.country.city,
  device: state.config.country.device,
});

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, {
    changeCity,
    signupRequest,
    fieldChanges,
    getBasicInfo,
  }),
)(BasicInfo);
